import styled, {css, keyframes} from "styled-components";

const error = "#851168";
const top = "20px";
const correct = "#1A8282";
const edit = "#7F28C4";
const regular = "#7B8283";
const labelUp = keyframes`
  0% {
    top: ${top};
  }
  100% {
    top: 0;
  }
`;

const labelDown = keyframes`
  0% {
    top: 0
  }
  100% {
    top: ${top}
  }
`;

const paddingLeft = "5px";

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const FormEl = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 70px 0 50px;

  @media screen and (max-width: 1280px) {
    padding: 0 20px 0 30px;
  }
`;

export const InputBox = styled.label`
  padding: 15px 0;
  position: relative;
  font-size: 10px;
  transition: all 2s ease 0s;
  margin-right: 20px;

  ${(props) => {
    switch (props.width) {
      case "wide":
        return css`
          width: 100%;
          @media screen and (min-width: 1920px) {
            width: 90%;
          }

          @media screen and (max-width: 420px) {
            margin-right: 0;
          }
        `;
      case "short":
        return css`
          width: 120px;
          @media screen and (min-width: 1920px) {
            width: 190px;
          }
          @media screen and (max-width: 1366px) {
            max-width: unset;
            width: 140px;
          }

          @media screen and (max-width: 420px) {
            width: 40%;
          }
        `;

      default:
        return css`
          width: 160px;
          @media screen and (min-width: 1920px) {
            width: 250px;
          }

          @media screen and (max-width: 1366px) {
            width: 160px;
          }

          @media screen and (max-width: 1280px) {
            width: 145px;
          }
          @media screen and (max-width: 420px) {
            width: 100%;
            margin-right: 0;
          }
        `;
    }
  }}
  ::before {
    top: ${top};
    content: "${(props) => props.label || ""}";
    position: absolute;
    left: ${paddingLeft};
    color: ${(props) =>
            props.val
                    ? props.err
                    ? error
                    : props.focus
                            ? edit
                            : correct
                    : regular};
    font-size: 14px;
    @media screen and (max-width: 1280px) {
      font-size: 13px;
    }

    animation-duration: 0.2s;
    ${(props) =>
            props.val
                    ? css`
                      text-transform: uppercase;
                      font-size: 10px!important;
                      animation-name: ${labelUp};
                      top: 0;
                    `
                    : css`
                      animation-name: ${labelDown};
                    `};
  }

  ::after {
    content: "${({err, focus, errMsg}) => (err && !focus ? errMsg : "")}";
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    color: ${error};
    font-size: 10px;
  }
`;

export const InputEl = styled.input`
  width: 100%;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid ${(props) =>
          props.value
                  ? props.err
                  ? error
                  : props.focus
                          ? edit
                          : correct
                  : regular};
  padding: 3px 20px 3px ${paddingLeft};
    //padding-left: ${paddingLeft};
  //padding-right: 20px;
  background-color: ${({err, focus}) =>
          err && !focus ? "rgba(133, 17, 104, 0.05)" : "transparent"};
`;

export const IconBox = styled.div`
  position: absolute;
  left: -20px;
  top: 18px;
  font-size: 16px;
`;

export const ValidationIcon = styled.div`
  position: absolute;
  font-size: 14px;
  top: 19px;
  right: 5px;
`;

export const CorrectIcon = styled(ValidationIcon)`
  color: ${correct};
`;

export const ErrorIcon = styled(ValidationIcon)`
  color: ${error};
`;

export const Header = styled.div`
  padding: 30px;
  text-align: center;

  & h1 {
    font: normal normal medium 24px/20px Montserrat;
    font-size: 24px;
    font-weight: normal;
    color: #000000;
  }
`;

export const Footer = styled.div`
  width: 100%;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    position: unset;
  }
`;
