import React, { useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import styles from './GoogleLogin.module.css';

const GoogleLoginButton = ({ onLoginSuccess, onLoginFailure }) => {
    // Check for an existing token in localStorage when the component mounts
    useEffect(() => {
        const storedToken = localStorage.getItem('google_access_token');
        if (storedToken) {
            fetchUserData(storedToken);
        }
    }, []);

    const fetchUserData = async (token) => {
        try {
            // Fetch additional user information if needed (Example: from Google People API)
            const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const peopleInfo = await axios.get('https://people.googleapis.com/v1/people/me?personFields=phoneNumbers,addresses,names,locations', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // Pass the user information to the parent component via callback
            onLoginSuccess({ ...userInfo.data, ...peopleInfo.data });
        } catch (error) {
            console.error('Failed to fetch user information:', error);
            onLoginFailure(error);
        }
    };

    const login = useGoogleLogin({
        onSuccess: (response) => {
            console.log('Login Success:', response);

            // Store the access token in localStorage
            localStorage.setItem('google_access_token', response.access_token);

            // Fetch user data using the access token
            fetchUserData(response.access_token);
        },
        onError: (error) => {
            console.error('Login Failed:', error);
            onLoginFailure(error);
        },
        scope: 'openid email profile'
    });

    return (
        <span onClick={() => login()} className={styles.loginButton}>
           <strong>Google</strong> 
        </span>
    );
};


export default GoogleLoginButton;




