import React from "react";
import BigButton from "../BigButton/BigButton";
import ArrowButton from "../ArrowButton/ArrowButton";
import disCountSvg from "../../../assets/images/aprilForm/rabaty.svg";
import { handleScrollToFormClick } from "../utils";
import { scrollToElement } from "../../../utils";

import styles from "./AvonDiscountSection.module.scss";

const AvonDiscountSection = () => {
    const handleArrowDownClick = (e) => {
        // scrollToElement(e, "scrollVideoSection");
        scrollToElement(e, "dzialaj_zdalnie");
    };

    return (
        <div className={styles.section}>
            <div className={styles.contentContainer}>
                <div>
                    <div className={styles.mainHeader}>
                        <div className={styles.mainHeader__topSegment}>
                            AVON{" "}
                        </div>
                        <div>NAGRADZA</div>
                    </div>
                    <div className={styles.contentContainer__text}>
                        Zasada jest prosta! Im więcej produktów zamówisz, tym
                        większe otrzymasz zniżki, a co za tym idzie - większy
                        odnotujesz zysk. 
                        <br />
                        <br />
                        Dodatkowo na każdym poziomie czekają na
                        Ciebie wspaniałe nagrody za awans lub utrzymanie
                        poziomu.
                    </div>

                    <div className={styles.contentContainer__buttons}>
                        <BigButton customClickEvent={handleScrollToFormClick} fullWidth>
                        DOŁĄCZ DO KLUBU AVON
                        </BigButton>
                        <ArrowButton customClickEvent={handleArrowDownClick} />

                    </div>
                </div>
                <div>
                    <img className={styles.discountImage} src={disCountSvg} alt="rabaty" />
              
              
            
                </div>
            </div>
        </div>
    );
};

export default AvonDiscountSection;
