import React, { Component } from "react";
import "./css/bootstrap-customized/bootstrap-grid.css";
import "./css/main.css";
import WMN from "../Watchmenow/WMN";
import { scrollToElement } from "../../utils";
import grid_2__02 from "./img/grid_2__02.jpg";
import grid_1__blank from "./img/grid_1__blank.jpg";
import grid_1__08 from "./img/grid_1__08.jpg";
import grid_1__03 from "./img/grid_1__03.jpg";
import grid_1__01 from "./img/grid_1__01.jpg";
import grid_1__09 from "./img/grid_1__09.jpg";
import grid_1__10 from "./img/grid_1__10.jpg";
import grid_1__11 from "./img/grid_1__11.jpg";
import grid_1__12 from "./img/grid_1__12.jpg";
import work_01 from "./img/work_01.jpg";
import work_02 from "./img/work_02.png";
import work_04 from "./img/work_04.jpg";
import work_03 from "./img/work_03.jpg";
import img_s1 from "./img/img_s1.jpg";
import kit_01 from "./img/kit_01.jpg";
import kit_02 from "./img/kit_02.jpg";
import kit_04 from "./img/kit_04.jpg";
import kit_05 from "./img/kit_05.jpg";
import kit_08 from "./img/kit_08.jpg";
import kit_09 from "./img/kit_09.png";
import kit_10 from "./img/kit_10.png";
import kit_11 from "./img/kit_11.jpg";
import kit_12 from "./img/kit_12.jpg";
import kit_13 from "./img/kit_13.jpg";
import kit_14 from "./img/kit_14.jpg";
import kit_04a from "./img/kit_04a.jpg";
import help_01 from "./img/help_01.jpg";
import help_02 from "./img/help_02.jpg";
import help_03 from "./img/help_03.jpg";
import img_s2 from "./img/img_s2.jpg";
import VideoCarousel from "./VideoCarousel/VideoCarousel";

class BeRepComponent extends Component {
    render() {
        const clickHandler = (e) => {
            scrollToElement(e, "apply");
        };
        return (
            <>
                <div id="pcontent" className="content">
                    <header className="header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 fromBottom">
                                    <h2 className="h2 text-center mt-3">
                                        <span>Co zyskujesz działając w Avon?</span>
                                        <br />
                                        <span className="outline outer">
                                            {" "}
                                            Poznaj historie naszych dziewczyn!
                                        </span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </header>

                    <section>
                        <div className="container">
                            <div className="container__inner py-3 pt-md-6 pb-md-4 mb-3">
                                <VideoCarousel />
                                <div className="row no-gutters mb-6 text-center">
                                    <p>
                                        <strong>PIĘKNIE JEST DZIAŁAĆ NA WŁASNYCH ZASADACH.</strong>
                                        <br />
                                        <br /> 
                                        W Avon łączy nas miłość do produktów i pasja do piękna, a przedsiębiorczość i rozwój osobisty sprawiają, że dzięki temu możemy więcej.
                                        <br />
                                        Nasza siła? Nasi ludzie!
                                        <br />
                                        <br /> 
                                        To znajomi, sąsiedzi, koleżanki z pracy, którym pomagamy wybrać te właściwe, spośród setek różnych produktów, choć rozmowy nigdy na tym się nie kończą.
                                        <br />
                                        <br /> 
                                        Działamy kiedy chcemy i skąd chcemy, by mieć czas na to co w życiu ważne.
                                        <br />
                                        <br />
                                        <strong>Bądź z nami!</strong>
                                    </p>
                                </div>
                                <div className="boxes row no-gutters mb-6">
                                    <div className="col-12 col-md-6">
                                        <div className="row no-gutters">
                                            <div className="col-6">
                                                <img
                                                    src={grid_1__10}
                                                    className="d-block img-fluid"
                                                    alt="Avon products"
                                                />
                                            </div>
                                            <div className="col-6">
                                                <img
                                                    src={grid_1__blank}
                                                    className="d-block img-fluid"
                                                    alt="big team with a big heart"
                                                />
                                                <div className="centered">
                                                    <h4 className="h4B text-center colorRedish">
                                                        DODATKOWE <br />
                                                        ZYSKI
                                                    </h4>
                                                    <p className="text-center tile">
                                                        Na coś ekstra dla Ciebie czy dla Twoje rodziny, na wydatki bez wyrzutów sumienia!<br />
                                                        Twoje zyski zależą od Ciebie!
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <img
                                                    src={grid_1__blank}
                                                    className="d-block img-fluid"
                                                    alt="Over 500 prodcts"
                                                />
                                                <div className="centered">
                                                    <h4 className="h4B text-center colorRedish">
                                                        ELASTYCZNOŚĆ
                                                    </h4>
                                                    <p className="text-center tile">
                                                    Koniec z pracą od 9:00 do 17:00 
                                                    - działasz, jak chcesz i kiedy chcesz.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <img
                                                    src={grid_1__11}
                                                    className="d-block img-fluid"
                                                    alt="Avon products"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="row no-gutters">
                                            <div className="col-6">
                                                <img
                                                    src={grid_1__09}
                                                    className="d-block img-fluid"
                                                    alt="Avon products"
                                                />
                                            </div>
                                            <div className="col-6">
                                                <img
                                                    src={grid_1__blank}
                                                    className="d-block img-fluid"
                                                    alt="Flexibility"
                                                />
                                                <div className="centered">
                                                    <h4 className="h4B text-center colorRedish">
                                                        FANTASTYCZNI
                                                        <br />
                                                        LUDZIE
                                                    </h4>
                                                    <p className="text-center tile">
                                                        Spotykasz się ze znajomymi, nie klientami, ale poznajesz również nowych ludzi!
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <img
                                                    src={grid_1__blank}
                                                    className="d-block img-fluid"
                                                    alt="Unlimited earnings"
                                                />
                                                <div className="centered">
                                                    <h4 className="h4B text-center colorRedish">
                                                        MARKOWE
                                                        <br />
                                                        PRODUKTY
                                                    </h4>
                                                    <p className="text-center tile">
                                                        Kupuj markowe produktu i
                                                        dziel się pasją z
                                                        przyjaciółmi – nic
                                                        prostrzego!
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <img
                                                    src={grid_1__12}
                                                    className="d-block img-fluid"
                                                    alt="Avon Rep"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 text-center">
                                        <a
                                            target="_self"
                                            className="cta"
                                            onClick={clickHandler}
                                        >
                                            Dołącz teraz
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="container__inner py-3 pt-md-6 pb-md-4">
                                <div className="pl-md-6 pr-md-6">
                                    <div className="row justify-content-center">
                                        <h2 className="h2 text-center mb-3">
                                            To nigdy nie było prostsze
                                        </h2>
                                    </div>
                                </div>
                                <div className="row justify-content-center mb-md-6">
                                    <div className="col-12 col-md-5">
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                <img
                                                    src={work_01}
                                                    className="d-block img-fluid"
                                                    alt="Brochure"
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 text-center">
                                                <p>
                                                    Korzystaj nie tylko z
                                                    tradycyjnego katalogu dla
                                                    konsultantek, ale też z jego
                                                    wersji cyfrowej – iKatalogu!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                <img
                                                    src={work_02}
                                                    className="d-block img-fluid"
                                                    alt="Social"
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 text-center">
                                                <p>
                                                    Zwiększaj swoją sprzedaż,
                                                    wykorzystując do tego
                                                    aplikację AVON ON.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center mb-md-6 mb-3">
                                    <div className="col-12 col-md-5">
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                <img
                                                    src={work_04}
                                                    className="d-block img-fluid"
                                                    alt="Website"
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 text-center">
                                                <p>
                                                    Wygeneruj swój własny link,
                                                    gdzie klientki będą mogły
                                                    wygodnie zamawiać ulubione
                                                    produkty.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                <img
                                                    src={work_03}
                                                    className="d-block img-fluid"
                                                    alt="Delivery"
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 text-center">
                                                <p>
                                                    Zajmij się sprzedażą, a
                                                    dostawę zostaw nam.
                                                    Wszystkie zamówione produkty
                                                    dostarczymy do Twoich
                                                    klientek.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="displayNone">
                        <div className="container">
                            <div className="container__inner py-3 pt-md-6 fullVideo">
                                <div className="row align-items-center mt-3 mb-3">
                                    <div className="col-12 col-md-6 mb-3">
                                        <div className="video-wrap">
                                            <div
                                                id="video"
                                                className="video video-link"
                                                data-video="l409SdrSuC0"
                                            >
                                                <div className="video__image">
                                                    <img
                                                        className="video__arrow"
                                                        src={require("./img/video_play.png")}
                                                        alt="Real Rep stories"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 fromRight">
                                        <h2 className="h2 mb-2">
                                            <div>
                                                REAL <br />
                                                <span className="d-md-block outlineBlack">
                                                    REP STORIES
                                                </span>
                                            </div>
                                        </h2>
                                        <p>
                                            From answering to a boss to being
                                            the boss. Listen to some of our real
                                            Rep stories on how they’ve
                                            transformed their lives, and how
                                            they’ve worked to create a
                                            successful business doing what they
                                            love.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="displayNone">
                        <div className="container">
                            <div className="container__inner py-3 pt-md-6">
                                <div className="row align-items-center mb-3">
                                    <div className="col-12 col-md-6 d-md-block mb-3 mt-3 fromLeft">
                                        <img
                                            src={img_s1}
                                            alt="Work wherever and when ever"
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 fromRight">
                                        <h2 className="h2 mb-2">
                                            <div>
                                                work whenever,
                                                <span className="d-md-block outlineBlack">
                                                    wherever
                                                </span>
                                            </div>
                                        </h2>
                                        <p>
                                            ‘I love Avon because it’s just so
                                            flexible, you’re able to do it
                                            anytime, anywhere. I mostly sell
                                            using social media, you just share a
                                            link online, Avon deal with the
                                            shipping and you just get paid. It’s
                                            an amazing way to make a living.’{" "}
                                            <br />
                                            <strong>Vikki, Avon Rep</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="container__inner py-3 pt-md-6 pb-md-4 mt-3">
                                <div className="pl-md-6 pr-md-6">
                                    <div className="row justify-content-center">
                                        <h2 className="h2 text-center mb-3">
                                            MYŚLISZ, ŻE AVON TO TYLKO SPRZEDAŻ
                                            KOSMETYKÓW?
                                            <br />
                                            TYLKO POPATRZ!
                                        </h2>
                                        <div className="col-12 pt-md-4 pb-3 pb-md-6 order-md-3 fromBottom text-center">
                                            <p>
                                                Tworzymy i reprezentujemy nowy
                                                sposób życia i nowy sposób na
                                                odniesienie sukcesu.
                                                <br /> Na Twoich zasadach!
                                            </p>
                                        </div>
                                        <div className="col-6 pt-md-4 pb-3 pb-md-6 order-md-3 fromBottom text-center">
                                            <img
                                                src={kit_08}
                                                className="d-block img-fluid "
                                                alt="Welcome kit"
                                            />
                                        </div>
                                        <div className="col-12 pt-md-4 pb-3 pb-md-6 order-md-3 fromBottom text-center">
                                            <p>
                                                Naszym celem jest nagradzanie
                                                Twojego zaangażowania! Im
                                                większe zamówienia składasz tym
                                                większe czekaja na Ciebie
                                                rabaty, nagrody i korzyści!{" "}
                                                <br />
                                                Wszystko zależy od Ciebie!
                                            </p>
                                        </div>
                                        <div className="col-12 pt-md-4 pb-3 pb-md-6 order-md-3 fromBottom text-center">
                                            <p>
                                                <strong>
                                                    IM WIĘCEJ, TYM TANIEJ
                                                </strong>
                                            </p>
                                        </div>

                                        <div className="col-12 pt-md-4 pb-3 pb-md-6 order-md-3 fromBottom text-center">
                                            <p>
                                                Wysokość Twoich zniżek rośnie
                                                wraz z zamówieniem – im więcej
                                                zamawiasz, tym więcej zyskujesz,
                                                wszystko na Twoich zasadach!
                                            </p>
                                        </div>

                                        <div className="col-8 pt-md-4 pb-3 pb-md-6 order-md-3 fromBottom text-center">
                                            <img
                                                src={kit_10}
                                                className="d-block img-fluid"
                                                alt="Welcome kit"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 text-center">
                                        <a
                                            target="_self"
                                            className="cta"
                                            onClick={clickHandler}
                                        >
                                            Dołącz teraz
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="displayNone">
                        <div className="container">
                            <div className="container__inner py-3 pt-md-6 pb-md-4 mt-3">
                                <div className="pl-md-6 pr-md-6">
                                    <div className="row justify-content-center">
                                        <h2 className="h2 text-center mb-3">
                                            Jesteśmy tutaj, by wspierać Cię na
                                            każdym etapie
                                        </h2>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-12">
                                                <img
                                                    src={help_01}
                                                    className="d-block img-fluid"
                                                    alt="Training"
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 mt-2 mb-2 text-center">
                                                <h4 className="h4B text-center mb-1">
                                                    Dedykowany trening dla
                                                    konsultantek
                                                </h4>
                                                <p>
                                                    Przygotowaliśmy dla Ciebie
                                                    specjalne szkolenia z
                                                    praktycznymi poradnikami i
                                                    tutorialami, które pomogą Ci
                                                    rozkręcić swój biznes w
                                                    Internecie.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-12">
                                                <img
                                                    src={help_02}
                                                    className="d-block img-fluid"
                                                    alt="Helpline"
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 mt-2 mb-2 text-center">
                                                <h4 className="h4B text-center mb-1">
                                                    Praktyczne wsparcie
                                                </h4>
                                                <p>
                                                    Masz pytania lub
                                                    wątpliwości? Nie bój się
                                                    poprosić o wsparcie.
                                                    Jesteśmy dostępni dla Ciebie
                                                    7 dni w tygodniu.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-12">
                                                <img
                                                    src={help_03}
                                                    className="d-block img-fluid"
                                                    alt="Free First Look"
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 mt-2 mb-2 text-center">
                                                <h4 className="h4B text-center mb-1">
                                                    Darmowy katalog AVON dla
                                                    konsultantek
                                                </h4>
                                                <p>
                                                    Z nadejściem każdej kampanii
                                                    otrzymasz od nas darmowy
                                                    katalog online.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-12">
                                                <img
                                                    src={help_03}
                                                    className="d-block img-fluid"
                                                    alt="Free First Look"
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 mt-2 mb-2 text-center">
                                                <h4 className="h4B text-center mb-1">
                                                    Darmowa, dedykowana
                                                    aplikacja
                                                </h4>
                                                <p>
                                                    Pozwoli Ci w prosty sposób
                                                    przygotowywać zamówienia dla
                                                    swoich klientów
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="container__inner py-3 pt-md-6 pb-md-4 mt-3">
                                <div className="pl-md-6 pr-md-6">
                                    <div className="row justify-content-center mb-3">
                                        <h2 className="h2 text-center">
                                            Jesteśmy tutaj, by wspierać Cię na
                                            każdym etapie
                                        </h2>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                <img
                                                    src={help_01}
                                                    className="d-block img-fluid"
                                                    alt="Training"
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 mt-2 text-center mb-3">
                                                <h4 className="h4B text-center mb-1">
                                                    Dedykowany trening dla
                                                    konsultantek
                                                </h4>
                                                <p>
                                                    Przygotowaliśmy dla Ciebie
                                                    specjalne szkolenia z
                                                    praktycznymi poradnikami i
                                                    tutorialami, które pomogą Ci
                                                    rozkręcić swój biznes w
                                                    Internecie.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                <img
                                                    src={help_02}
                                                    className="d-block img-fluid"
                                                    alt="Helpline"
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 mt-2 text-center mb-3">
                                                <h4 className="h4B text-center mb-1">
                                                    Praktyczne wsparcie
                                                </h4>
                                                <p>
                                                    Masz pytania lub
                                                    wątpliwości? Nie bój się
                                                    poprosić o wsparcie.
                                                    Jesteśmy dostępni dla Ciebie
                                                    7 dni w tygodniu.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                <img
                                                    src={help_03}
                                                    className="d-block img-fluid"
                                                    alt="Free First Look"
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 mt-2 text-center mb-3">
                                                <h4 className="h4B text-center mb-1">
                                                    Darmowy katalog AVON dla
                                                    konsultantek
                                                </h4>
                                                <p>
                                                    Z nadejściem każdej kampanii
                                                    otrzymasz od nas darmowy
                                                    katalog online.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="displayNone">
                        <div className="container">
                            <div className="container__inner py-3 pt-md-6 pb-md-4 mt-3">
                                <div className="pl-md-6 pr-md-6">
                                    <div className="row justify-content-center">
                                        <h2 className="h2 text-center mb-3">
                                            Kobiety mają głos
                                        </h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="col-12 pl-0 pr-0 ml-0 mr-0">
                                            <img
                                                src={img_s2}
                                                className="d-block img-fluid"
                                                alt="Committed to improving the lives of women"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pl-md-6 pr-md-6">
                                    <div className="row justify-content-center">
                                        <h4 className="h4 text-center pl-3 pr-3 mt-3 mb-1">
                                            Głos kobiet od lat jest tak cichy,
                                            że niemal niesłyszalny. Pora to
                                            zmienić!
                                        </h4>
                                        <div className="col-12 pt-md-4 pb-3 pb-md-6 order-md-3 fromBottom text-center">
                                            <p>
                                                Kobiety od zawsze słyszą, co
                                                powinny robić, a czego nie, co
                                                im wypada, jak powinny się
                                                ubrać, gdzie pracować, jak się
                                                zachowywać, jak wychowywać swoje
                                                dzieci. Pora to zmienić. Dołącz
                                                do naszej galerii kobiecych
                                                historii i daj się usłyszeć!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="container__inner py-3 pt-md-6 pb-md-4">
                                <div className="pl-md-6 pr-md-6">
                                    <div className="row justify-content-center">
                                        <h2 className="h2 text-center mb-3">
                                            WSPÓLNIE TWORZYMY LEPSZE JUTRO
                                        </h2>
                                    </div>
                                </div>
                                <div className="row justify-content-center mb-md-6">
                                    <div className="col-12 col-md-5">
                                        <div className="row">
                                            <div className="col-10 col-md-10">
                                                <a
                                                    href="https://avonkontrarakpiersi.pl"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={kit_11}
                                                        className="d-block img-fluid"
                                                        alt="Brochure"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <div className="row">
                                            <div className="col-10 col-md-10">
                                                <a
                                                    href="https://avonkontraprzemoc.pl"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={kit_12}
                                                        className="d-block img-fluid"
                                                        alt="Social"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center mb-md-6 mb-3">
                                    <div className="col-12 col-md-5">
                                        <div className="row">
                                            <div className="col-10 col-md-10">
                                                <a
                                                    href="https://avonalert.pl"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={kit_13}
                                                        className="d-block img-fluid"
                                                        alt="Website"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <div className="row">
                                            <div className="col-10 col-md-10">
                                                <img
                                                    src={kit_14}
                                                    className="d-block img-fluid"
                                                    alt="Delivery"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="color_bar mt-6">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2
                                        onClick={(e) => clickHandler(e)}
                                        className="h3 text-center mb-md-0 pl-md-8"
                                    >
                                        PIĘKNO NA TWOICH ZASADACH
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <script src="js/gsap.min.js"></script>
                <script src="js/app.min.js"></script>
            </>
        );
    }
}

export default BeRepComponent;
