import React from 'react';
import styles from './Logo.module.scss';
import logoImg from '../../../assets/images/aprilForm/Avon_RGB_Logo-min.png';
import logoImgWebp from '../../../assets/images/aprilForm/Avon_RGB_Logo-min.webp';

const Logo = () => {
  return (
    <div className={styles.logoContainer}>
      <picture className={styles.logoImg}>
        <source srcSet={logoImgWebp} type="image/webp" />
        <source srcSet={logoImg} type="image/png" />
        <img src={logoImg} alt="Avon logo" />
      </picture>
      {/* <div className={styles.logoTitle}>
        DOŁĄCZ
        <br />
        DO KLUBU
      </div> */}
    </div>
  );
};

export default Logo;
