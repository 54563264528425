export const scrollToElement = (e, targetId) => {
  try {
    e.preventDefault();
    setTimeout(() => {
      const { offsetTop } = document.getElementById(targetId);
      window.scroll({
        top: offsetTop,
        behavior: 'smooth',
      });
    }, 300);
  } catch (e) {}
};

export const iframePostMessage = () => {
  setTimeout(() => {
    const pathsToSendPostMessage = ['/form2', '/form3'];

    if (pathsToSendPostMessage.includes(window.location.pathname)) {
      const { scrollHeight } = document.getElementById('root');

      // eslint-disable-next-line no-restricted-globals
      parent.postMessage(scrollHeight, 'https://www.avon.pl');
    }
  }, 200);
};

export const getSearchInfo = (search) => { return new URLSearchParams(window.location.search).get(search) || ''; };

export const getTypeForm = () => {
  const queryParams = new URLSearchParams(window.location.search);

  if (queryParams.get('utm_term')) return 'join.avon.pl/dolacz-do-klubu';
  if (queryParams.get('Campaign') || queryParams.get('src') === 'avonpl') { return 'join.avon.pl/dolacz-do-klubu/avon.pl'; }
  if (window.location.pathname.replace(/\//g, '') === 'dolacz-do-klubu') { return 'avon.pl/dolacz-do-klubu'; }

  return 'join.avon.pl';
};

export const b64_to_utf8 = (str) => {
  try {
    return decodeURIComponent(escape(window.atob(str)));
  } catch (err) {
    return null;
  }
};
