const formTrackingCodes = {};

const FormTrackingCodeModule = (function () {
    let dataLayerCode = function (data) {
        window.dataLayer.push({
            event: "typ",
            leadNumber: data.data.id,
            typeForm: data.typeForm,
            repId: data.data.apptId
        });
    };

    return {
        dataLayerCode: dataLayerCode
    };
})();

formTrackingCodes.trackLeadForm = function (data) {
    FormTrackingCodeModule.dataLayerCode(data);
};

export default formTrackingCodes;
