import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styles from "./HorizontalScroll2.module.scss";
import iconMakeup from "../../../assets/images/aprilForm/icon-makeup.svg";
gsap.registerPlugin(ScrollTrigger);

export default function HorizontalScroll2({
    ref2,
    blocks = [],
    enableScroll = false,
    xPercentConfig = window.innerWidth > 1200
        ? -31
        : window.innerWidth > 768
        ? -90
        : -110,
}) {
    const component = useRef(null);

    useLayoutEffect(() => {
        if (!enableScroll) return;
        let ctx = gsap.context(() => {
            if (ref2.current === undefined) return;
            let panels = gsap.utils.toArray(".panelZ");
            gsap.to(panels, {
                xPercent: xPercentConfig * (panels.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: ref2.current,
                    pin: true,
                    scrub: 1,
                    //   snap: 1 / (panels.length - 1),
                    end: () => "+=" + ref2.current.offsetWidth,
                    //   markers: true
                },
            });
        }, component);
        return () => ctx.revert();
    }, [ref2.current, enableScroll]);
    return (
        <div ref={component}>
            <div className={styles.slideWrapper}>
                {blocks?.map((item, index) => (
                    <div
                        key={index}
                        className={`${styles.slideElement} panelZ`}
                    >
                        <div className={styles.slideElement__content}>
                            <div
                                className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}
                            >
                                <div className={styles.mainHeader__topSegment}>
                                    {item.topSegment}
                                </div>
                                <div>{item.bottomSegment}</div>
                            </div>
                            <div>
                                {item.textSection.map((el) => {
                                    return (
                                        <div
                                            key={el.text}
                                            className={
                                                styles.slideElement__textSection
                                            }
                                        >
                                            <img
                                                src={iconMakeup}
                                                alt="Icon Makeup"
                                            />
                                            <div>{el.text}</div>
                                        </div>
                                    );
                                })}
                            </div>
                                {item.html}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
