import React from 'react';
import BigButton from '../BigButton/BigButton';
import ArrowButton from '../ArrowButton/ArrowButton';
import { handleScrollToFormClick } from '../utils';
import { scrollToElement } from '../../../utils';

import styles from './AvonConsultantSection.module.scss';

const AvonConsultantSection = () => {
  const handleArrowDownClick = (e) => {
    // scrollToElement(e, 'scrollVideoSection');
    scrollToElement(e, 'dlaczego_warto');
  };

  return (
    <div className={styles.section} id="avonConsultantSection">
      <div className={styles.contentContainer}>
        <div className={styles.mainHeader}>
          <span className={styles.mainHeader__topSegment}>KONSULTANTKA AVON </span>
          <span>CO TO ZNACZY?</span>
        </div>
        <div className={styles.contentContainer__text}>
          Bycie Konsultantką Avon to po prostu piękno na Twoich zasadach,
          Twoja radość i satysfakcja bez żadnych zobowiązań!
        </div>
        <div className={styles.contentContainer__buttons}>
          <BigButton customClickEvent={handleScrollToFormClick} fullWidth>
            CHCĘ ZOSTAĆ KONSULTANTKĄ
          </BigButton>
          <ArrowButton customClickEvent={handleArrowDownClick} />
        </div>
      </div>
    </div>
  );
};

export default AvonConsultantSection;
