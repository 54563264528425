import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-modal";

import paulaImage from "../../../assets/images/aprilForm/paula_video.png";
// import paulaImageWebp from "../../../assets/images/aprilForm/paula_video.webp";
import kenanImage from "../../../assets/images/aprilForm/kenan_video.png";
// import kenanImageWebp from "../../../assets/images/aprilForm/kenan_video.webp";

import playIcon from "../../../assets/images/aprilForm/play-alone.svg";
import BigButton from "../BigButton/BigButton";
import {
    SampleNextArrow,
    SamplePrevArrow,
    handleScrollToFormClick,
} from "../utils";
import ArrowButton from "../ArrowButton/ArrowButton";
import { scrollToElement } from "../../../utils";

import styles from "./VideoSection.module.scss";

Modal.setAppElement("#root");

const customStyles = {
    content: {
        width: "80vw",
        height: "50vw",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 4000,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 750,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

const VideoSection = () => {
    const [modalIsOpen, setIsOpen] = useState({
        isOpen: false,
        video: "",
    });

    const handleArrowDownClick = (e) => {
        // scrollToElement(e, "grid_Section_2");
        const misjaAvonElement = document.querySelector("#misja_avon");
        if (misjaAvonElement) {
            setTimeout(()=>misjaAvonElement.scrollIntoView({ behavior: 'smooth' }),300)
        }
    };

    const openModal = (video) => {
        setIsOpen((prevState) => ({ ...prevState, isOpen: true, video }));
    };

    const closeModal = () => {
        setIsOpen((prevState) => ({ ...prevState, isOpen: false, video: "" }));
    };

    return (
        <div className={styles.section}>
            <div
                className={`${styles.mainHeader} ${styles.mainHeader__videoSection}`}
            >
                <div>ZOSTAŃ KONSULTANTKĄ AVON</div>
                <div className={styles.mainHeader__topSegment}>ZERO ZOBOWIĄZAŃ, SAME KORZYŚCI</div>


            </div>
            <div className={styles.textContainer}>
                Poznaj niesamowite historie naszych konsultantek
            </div>
            <Slider className={styles.sliderWrapper} {...settings}>
                <div className={styles.slideElement}>
                    <picture>
                        {/* <source srcSet={paulaImageWebp} type="image/webp" /> */}
                        <source srcSet={paulaImage} type="image/png" />
                        <img src={paulaImage} alt="Paula Wideo" />
                    </picture>
                    <div className={styles.slideElement__content}>
                        <div>PAULA</div>
                        <div
                            className={styles.slideElement__playButtonWrapper}
                            onClick={() => openModal("paula")}
                        >
                            <ReactSVG
                                className={
                                    styles[
                                    "slideElement__playButtonWrapper--icon"
                                    ]
                                }
                                src={playIcon}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.slideElement}>
                    <picture>
                        {/* <source srcSet={kenanImageWebp} type="image/webp" /> */}
                        <source srcSet={kenanImage} type="image/png" />
                        <img src={kenanImage} alt="Kenan Wideo" />
                    </picture>
                    <div className={styles.slideElement__content}>
                        <div>KENAN</div>
                        <div
                            className={styles.slideElement__playButtonWrapper}
                            onClick={() => openModal("kenan")}
                        >
                            <ReactSVG
                                className={
                                    styles[
                                    "slideElement__playButtonWrapper--icon"
                                    ]
                                }
                                src={playIcon}
                            />
                        </div>
                    </div>
                </div>
            </Slider>
            <div className={styles.buttonWrapper}>
                <BigButton customClickEvent={handleScrollToFormClick} fullWidth>
                    DOŁĄCZ DO KLUBU AVON
                </BigButton>
                <ArrowButton customClickEvent={handleArrowDownClick} />
            </div>
            <Modal
                isOpen={modalIsOpen.isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {modalIsOpen.video === "paula" && (
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/SGodbddNZZg"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    />
                )}
                {modalIsOpen.video === "kenan" && (
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/9pU9tqzKRK4"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    />
                )}
            </Modal>
        </div>
    );
};

export default VideoSection;
