import React, {  useRef } from "react";
import SliderSection from "./SliderSection/SliderSection";
import FormComponent from "./FormComponent/FormComponent";
import styles from "./AprilForm.module.scss";
import TopSection from "./TopSection/TopSection";
import HowToBeConsultantSection from "./HowToBeConsultantSection/HowToBeConsultantSection";
import WorkOnlineSection from "./WorkOnlineSection/WorkOnlineSection";
import AvonConsultantSection from "./AvonConsultantSection/AvonConsultantSection";
import AvonDiscountSection from "./AvonDiscountSection/AvonDiscountSection";
import VideoSection from "./VideoSection/VideoSection";
import JoySection from "./joySection/joySection";
import ProfitSection from "./ProfitSection/ProfitSection";
import GridSection from "./GridSection/GridSection";
import GridSection2 from "./GridSection2/GridSection";
import ConsequencesSection from "./ConsequencesSection/ConsequencesSection";
import WhySection from "./WhySection/WhySection";
import Sidebar from "./Sidebar/Sidebar";

const AprilFormLong = () => {
    const containerRef= useRef();
    const containerRef2= useRef();
    return (
  
            <div className={styles.lpContainer} id="scrollToTop">
              <Sidebar />
                <TopSection />
                <div id="scrollToSlider" />
                {/* <SliderSection /> */}
                <div className={styles.joinSection}>
                    <HowToBeConsultantSection />
                    <div id="scrollToFormTop" className={styles.formContainer}>
                        <FormComponent />
                    </div>
                </div>
                <AvonConsultantSection />
                <br />

                <div id="dlaczego_warto">
                <WhySection />
                </div>

                <div id="avon_nagradza" >
                <AvonDiscountSection />
                </div>
                <div id="dzialaj_zdalnie">
                <WorkOnlineSection />
                </div>
              
              
                <JoySection />
                <div ref={containerRef}>
                <GridSection ref2={containerRef} />
                </div>
                <ProfitSection />

                <div id="scrollVideoSection" />
                <VideoSection />
                <div ref={containerRef2} id="misja_avon" >
                <div id="grid_Section_2" /> 
                <GridSection2 ref2={containerRef2} />
                </div>
                <ConsequencesSection />
            
            </div>
   
    );
};

export default AprilFormLong;
