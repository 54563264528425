import React from 'react';
import BigButton from '../../BigButton/BigButton';
import styles from './DuplicytyError.module.scss'

const DuplicityError = () => {
  const handleGoToLoginPage = () => {
    window.location.href = 'https://www2.pl.avon.com/pl-home';
  };

  return (
    <div className={styles.thankYou}>
    <div >
      <div className={styles.thankYou__header}>TWOJE KONTO<br></br>JUŻ ISTNIEJE</div>
      <div className={styles.thankYou__middleSection}>
      Jeśli nie pamiętasz hasła, przejdź do strony logowania
      {/* add br if desktop */}
      <br
        className={styles.thankYou__br}
      ></br>


      i kliknij "Nie pamiętam hasła".
            </div>
      <div className={styles.thankYou__button}>
      <BigButton customClickEvent={handleGoToLoginPage}>
        PRZEJDŹ DO STRONY LOGOWANIA
      </BigButton>
      </div>
    </div>
    </div>
  );
};

export default DuplicityError;
