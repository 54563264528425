import React from "react";
import BigButton from "../BigButton/BigButton";
import ArrowButton from "../ArrowButton/ArrowButton";
import { handleScrollToFormClick } from "../utils";
import svgDesign from "../../../assets/images/aprilForm/profit.svg";
import LipsSvg from "../../../assets/images/aprilForm/lips.svg";
import { scrollToElement } from "../../../utils";

import styles from "./ProfitSection.module.scss";

const ProfitSection = () => {
    const handleArrowDownClick = (e) => {
        scrollToElement(e, "scrollVideoSection");
        // scrollToElement(e, 'scrollVideoSection');
    };

    return (
        <div className={styles.section} id="profitSection">
            {/* <div className={styles.contentContainer}>
        <img className={styles.svgDesign} src={svgDesign} alt="joy" />
      </div> */}
            <div className={styles.desktop}>
            <div className={styles.mainHeader}>
                <div className={styles.mainHeader__extraSegment}>
                <div>NIC NIE TRACISZ </div>
                <img src={LipsSvg} alt="lips" />
                </div>
                <div className={`${styles.mainHeader__topSegment}`}>
                    <div>TYLKO ZYSKUJESZ</div>
                </div>
            </div>
            </div>
            <div className={styles.mobile}>
            <div className={styles.mainHeader}>
                <div className={styles.mainHeader__extraSegment}>
                <img src={LipsSvg} alt="lips" />
                <div>NIC NIE TRACISZ </div>
                </div>
                <div className={`${styles.mainHeader__topSegment}`}>
                    <div>TYLKO ZYSKUJESZ</div>
                </div>
            </div>
            </div>

            <div className={styles.contentContainer__buttons}>
                <BigButton customClickEvent={handleScrollToFormClick} fullWidth>
                    DOŁĄCZ DO KLUBU AVON
                </BigButton>
                <ArrowButton customClickEvent={handleArrowDownClick} />
            </div>
        </div>
    );
};

export default ProfitSection;
