export const pageLoaded = () => {
    try {
        switch (window.location.pathname) {
            case "/form3":
                // eslint-disable-next-line no-undef
                ga("join.send", {
                    hitType: "event",
                    eventCategory: "Dołącz do Avon - form loaded",
                    eventAction: "Test A/B - zgoda marketingowa",
                    eventLabel: "Wersja A",
                });
                break;
            case "/form2":
                // eslint-disable-next-line no-undef
                ga("join.send", {
                    hitType: "event",
                    eventCategory: "Dołącz do Avon - form loaded",
                    eventAction: "Test A/B - zgoda marketingowa",
                    eventLabel: "Wersja B",
                });
                break;
            default:
                // eslint-disable-next-line no-undef
                ga("join.send", {
                    hitType: "event",
                    eventCategory: "dolacz-do-klubu",
                    eventAction: window.location.pathname,
                    eventLabel: "form loaded",
                });
        }
    } catch (e) {}
};
