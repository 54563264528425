import styled from "styled-components";

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const ContentBlockDiv = styled(FlexColumn)`
    width: 100%;
    height: 100%;
    padding: 30px 30px 20px 100px;

    & h1 {
        font-weight: 500;
        font-size: 42px;
        color: white;
        padding: 30px 0;
        line-height: 1.2;

        & span {
            font-weight: bold;
        }

        @media screen and (max-width: 1366px) {
            font-size: 34px;
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 30px 20px;
        text-align: center;
    }
`;

export const ContentHeader = styled.div`
    height: 70px;
    width: 100%;

    & img {
        width: auto;
        max-width: 50%;
        height: 25px;
    }

    @media screen and (max-width: 1100px) {
        display: none;
    }
`;

export const IconsBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 30px;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const IconBox = styled(FlexColumn)`
    position: relative;
    width: 32%;
    color: #fff;
    font-weight: 500;

    & img {
        max-height: 70px;
        min-height: 4.6vw;
        margin-bottom: 20px;
    }
    & p {
        font-size: 14px;
        text-align: center;
        & span {
            font-weight: 700;
            font-size: 18px;

            .big {
                font-size: 32px;
                font-weight: 900;
            }
        }
    }

    @media screen and (max-width: 600px) {
        width: 75%;
        margin: 30px 0;
    }
`;

export const Circle = styled.div`
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

export const ContentFooter = styled(FlexColumn)`
    width: 100%;
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    p {
        color: white;
        font-size: 18px;
        margin: 30px auto;
        span {
            font-weight: 700;
        }
    }

    @media screen and (max-width: 1100px) {
        position: unset;
    }
`;
