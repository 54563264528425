import React from 'react';
import BigButton from '../BigButton/BigButton';
import ArrowButton from '../ArrowButton/ArrowButton';
import { handleScrollToFormClick } from '../utils';
import { scrollToElement } from '../../../utils';

import styles from './AvonConsultantSection.module.scss';

const AvonConsultantSection = () => {
  const handleArrowDownClick = (e) => {
    scrollToElement(e, 'scrollVideoSection');
  };

  return (
    <div className={styles.section}>
      <div className={styles.contentContainer}>
        <div className={styles.mainHeader}>
          <span className={styles.mainHeader__topSegment}>HEJ, A CIEBIE CO <br></br></span>
          <span>NAKRĘCA DO DZIAŁANIA?</span>
        </div>
        <div className={styles.contentContainer__text}>
        Zgrana ekipa? Ciekawe projekty?<br></br> A może pasja do kosmetyków?<br></br> <strong>Zostań Ejwoniarą!</strong>
        </div>
        <div className={styles.contentContainer__buttons}>
          <BigButton customClickEvent={handleScrollToFormClick} fullWidth>
            DOŁĄCZ DO KLUBU AVON
          </BigButton>
          <ArrowButton customClickEvent={handleArrowDownClick} />
        </div>
      </div>
    </div>
  );
};

export default AvonConsultantSection;
