import React from 'react';
import { Link } from 'react-scroll';
import styles from './Sidebar.module.scss';

const Sidebar = () => {
    return (
        <div className={styles.sidebar}>
            <Link className={styles.link} activeClass={styles.active} to="misja_avon" spy={true} smooth={true} offset={0} duration={500}>
                <div className={styles.sidebarLink}>Misja Avon</div>
            </Link>
            <Link className={styles.link} activeClass={styles.active} to="dzialaj_zdalnie" spy={true} smooth={true} offset={0} duration={500}>
                <div className={styles.sidebarLink}>Działaj Zdalnie</div>
            </Link>
            <Link className={styles.link} activeClass={styles.active} to="avon_nagradza" spy={true} smooth={true} offset={0} duration={500}>
                <div className={styles.sidebarLink}>Avon Nagradza</div>
            </Link>
            <Link className={styles.link} activeClass={styles.active} to="dlaczego_warto" spy={true} smooth={true} offset={0} duration={500}>
                <div className={styles.sidebarLink}>Dlaczego Warto</div>
            </Link>
            <Link className={styles.link} activeClass={styles.active} to="scrollToFormTop" spy={true} smooth={true} offset={0} duration={500}>
                <div className={styles.sidebarLink}>Dołącz Do Nas</div>
            </Link>
        </div>
    );
}

export default Sidebar;