import React from 'react';
import heartSuccessIcon from '../../../../assets/images/aprilForm/heart-success.svg';
import BigButton from '../../BigButton/BigButton';
import styles from './ThankYou.module.scss';

const ThankYou = () => {
  const handleGoToLoginPage = () => {
    window.location.href = 'https://www2.pl.avon.com/pl-home';
  };

  return (
    <div className={styles.thankYou}>
      <img className={styles.thankYou__image} src={heartSuccessIcon} alt="Sukces" />
      <div className={styles.thankYou__header}>Dziękujemy za rejestrację</div>
      <div className={styles.thankYou__middleSection}>
      Wkrótce z numeru 732 221 720/21/25 skontaktuje się z Tobą Opiekun, który wprowadzi Cię do wszystkich korzyści jakie na Ciebie czekają.
            </div>
            <div className={styles.thankYou__bottomSection}>
            Sprawdź swoją skrzynkę email oraz SMSa i zaloguj się do strony zamawiania Klubu Avon.
            </div>
      <BigButton customClickEvent={handleGoToLoginPage}>
        PRZEJDŹ DO STRONY LOGOWANIA
      </BigButton>
    </div>
  );
};

export default ThankYou;
