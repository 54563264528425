import React from "react";
import SmallButtonAndHeader from "../SmallButtonAndHeader/SmallButtonAndHeader";
import arrowUpright from "../../../assets/images/aprilForm/arrow_upright.svg";
import googlePlayLogo from "../../../assets/images/aprilForm/googlePlay.svg";
import appStoreLogo from "../../../assets/images/aprilForm/AppStore.svg";
import appGalleryLogo from "../../../assets/images/aprilForm/AppGallery.svg";
import { handleScrollToFormClick } from "../utils";
import styles from "./HowToBeConsultantSection.module.scss";

const HowToBeConsultantSection = () => {
    const highlightForm = () => {
        const element = document.getElementById("scrollToFormTop");
        element.style.animation = "highlightForm 0.5s forwards";
        setTimeout(() => {
            element.style.animation = "initial";
        }, 500);
    };

    return (
        <div className={styles.section}>
            <div className={styles.mainHeader}>
                <div className={styles.mainHeader__topSegment}>DOŁĄCZ</div>
                <div>DO KLUBU AVON</div>
            </div>
            <SmallButtonAndHeader
                withArrow
                isButton
                customClickEvent={(e) => {
                    handleScrollToFormClick(e);
                    highlightForm();
                }}
            >
                wypełnij formularz
            </SmallButtonAndHeader>
            <div className={styles.textWrapper}>
                W ciągu 5 minut otrzymasz e-mail i SMS z jednorazowym hasłem do
                logowania.
            </div>
            <SmallButtonAndHeader>zaloguj się</SmallButtonAndHeader>
            <div className={styles.textWrapper}>
                Możesz składać zamówienia od momentu, kiedy otrzymasz dane do
                logowania.
                <br />
                <br />
                Zaloguj się przez{" "}
                <a
                    href="https://www2.pl.avon.com/pl-home"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.bold}
                >
                    stronę WWW
                    <img
                        className={styles.linkIcon}
                        src={arrowUpright}
                        alt="Arrow upright"
                    />
                </a>
                <br />
                lub pobierz z:
                <div className={styles.storeLogos}>
                    <a
                        className={styles.storeLogos__links}
                        href="https://play.google.com/store/apps/details?id=com.avon.avonon&hl=pl&gl=US"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={googlePlayLogo} alt="Google Play Logo" />
                    </a>
                    <a
                        className={styles.storeLogos__links}
                        href="https://apps.apple.com/pl/app/avon-on/id1439105374?l=pl"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={appStoreLogo} alt="App Store Logo" />
                    </a>
                    <a
                        className={styles.storeLogos__links}
                        href="https://appgallery.huawei.com/app/C104382659"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={appGalleryLogo} alt="App Gallery Logo" />
                    </a>
                </div>
            </div>

            <SmallButtonAndHeader>zacznij zamawiać</SmallButtonAndHeader>
            <div className={styles.textWrapper}>
                Zapoznaj się z naszym katalogiem Avon i przygotuj swoje pierwsze
                zamówienie. Udostępnij katalog online i zbierz jeszcze więcej
                zamówień!
            </div>
        </div>
    );
};

export default HowToBeConsultantSection;
