import React, { useRef } from 'react';
import BigButton from '../BigButton/BigButton';
import ArrowButton from '../ArrowButton/ArrowButton';
import { handleScrollToFormClick } from '../utils';
import { scrollToElement } from '../../../utils';

import styles from './TopSection.module.scss';
// import HorizontalScroll from '../HorizontalScroll/HorizontalScroll';

const TopSection = () => {
  // const containerRef= useRef();
  const handleFirstSectionArrowClick = (e) => {
    scrollToElement(e, 'scrollToWorkOnlineSlider');
  };

  const avonBlocks = [
    {
      img: "/static/media/icon-rabat.b2bbcbdd.svg",
      desc: `<span class="bold">Zniżka <span class="avonColor emphasize2">15%</span> na start i każde<br/>kolejne zamówienie</span> - możliwość<br/>zwiększenia rabatu nawet<br/><span class="bold avonColor">do <span class="emphasize2">40%</span></span>`,
    },
    {
      img: "/static/media/icon-gift.a86f6e53.svg",
      desc: `Prezenty, bonusy, <span class="bold">wcześniejszy<br/>dostęp do nowości i możliwość<br/>testowania produktów</span>`,
    },
    {
      img: "/static/media/icon-computer.d5bfe922.svg",
      desc: `<span class="bold">Praca na Twoich zasadach</span><br/>- jak długo, kiedy, skąd chcesz.<br/>Możez pracować online,<br/>nie wychodząc z domu.`,
    },
    {
      img: "/static/media/icon-shopping.77a819ea.svg",
      desc: `<span class="bold">Biznes bez wymagań i ograniczeń</span><br/>Liczba i wartość realizowanych<br/>zamówień zależy tylko od Ciebie i<br/>potrzeb Twoich klientów`,
    },
    {
      img: "/static/media/icon-hands.36dd5a03.svg",
      desc: `<span class="bold">Powitalna paczka</span> materiałów na<br/>własny użytek oraz<br/><span class="bold">możliwość skorzystania<br/>z profesjonalnych szkoleń.</span>`,
    },
  ];

  return (
    <div className={styles.section}>
      <div className={styles.section__content} >
        <div className={styles.mainHeader}>
          <div className={styles.mainHeader__topSegment}>KORZYSTAJ</div>
          <div>NA PASJI</div>
          <div>DO PIĘKNA</div>
        </div>
        <div className={styles.section__buttons}>
          <BigButton customClickEvent={handleScrollToFormClick}>
            DOŁĄCZ DO KLUBU AVON
          </BigButton>
          <ArrowButton customClickEvent={handleFirstSectionArrowClick} />
        </div>
      </div>
      {
        avonBlocks.map((item, index) => (
          <div key={index} className={`${styles.subElement}`}>
            <img className={styles.subElement__centerImg} src={item.img} />
            <h3
              dangerouslySetInnerHTML={{ __html: item.desc }}
            ></h3>
          </div>
        ))
      }
      {/* <HorizontalScroll ref2={containerRef}/> */}
    </div>
  );
};

export default TopSection;
