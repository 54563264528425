import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import iconMakeup from "../../../assets/images/aprilForm/icon-makeup.svg";
import BigButton from "../BigButton/BigButton";
import ArrowButton from "../ArrowButton/ArrowButton";
import { scrollToElement } from "../../../utils";
import {
    SampleNextArrow,
    SamplePrevArrow,
    handleScrollToFormClick,
} from "../utils";

import styles from "./WhySection.module.scss";
import HorizontalScroll from "../HorizontalScroll/HorizontalScroll";
import HorizontalScroll2 from "../HorizontalScroll/HorizontalScroll2";

const WhySection = () => {
    const containerRef4 = useRef();
    const [isScrollEnabled, setIsScrollEnabled] = useState(false);
    const [switchRef, setSwitchRef] = useState(false);

    const handleShowCatalogClick = () => {
        window.location.href = "https://pl.avon-brochure.com/c04_pl_2023/";
    };
    const handleArrowDownClick = (e) => {
        // scrollToElement(e, "scrollVideoSection");
        scrollToElement(e, "avon_nagradza");
    };

    const contentBlocks = [
        {
            topSegment: "KOSMETYKI DLA SIEBIE",
            bottomSegment: "I OSZCZĘDZONE PIENIĄDZE",
            textSection: [
                {
                    text: `Ogromny wybór - wysokiej jakości
                produktów, od perfum i kosmetyków do
                makijażu, po pielęgnację ciała, twarzy i
                włosów.`,
                },
                {
                    text: `Duże rabaty i bardzo atrakcyjne ceny
                kosmetyków dostępne wyłącznie dla
                Konsultantek!`,
                },
            ],
        },
        {
            topSegment: "BEAUTY TRENDY",
            bottomSegment: "I NOWOŚCI W AVON",
            textSection: [
                {
                    text: `Sprawdzaj, co nowego w świecie
            kosmetyków i Avonie, wypróbuj produkty
            przed wszystkimi!`,
                },
                {
                    text: `Bądź na bieżąco z beauty trendami -
            poznaj zaskakujące rozwiązania w strefie
            makijażu i nie tylko.`,
                },
                {
                    text: `Zaskocz swoich znajomych lub klientów
                    specjalistyczną wiedzą - zostań expertem
                    beauty.`,
                },
            ],
        },
        {
            topSegment: "ZAMAWIAJ DOWOLNIE",
            bottomSegment: "KIEDY I ILE CHCESZ",
            textSection: [
                {
                    text: `Bez żadnych limitów na zamówienia.
            Możesz złożyć dowolną ilość zamówień lub
            jeśli chcesz mieć przerwę to bez
            konsekwencji nic nie zamawiasz.`,
                },
                {
                    text: `Złożone zamówienia mają jedynie wpływ na
            jeszcze większy możliwy rabat na dany
            miesiąc lub zwiększenie stałego rabatu
            na kolejny kwartał.`,
                },
            ],
        },
        {
            topSegment: "WYGODNA DOSTAWA -",
            bottomSegment: "ODLEGŁOŚĆ BEZ ZNACZENIA",
            textSection: [
                {
                    text: `W Avon możesz wybrać najlepszą dla
            Ciebie formę dostawy: przez Kuriera, do
            Punktu Odbioru, oraz Dostawę Ekspresową,
            dzięki czemu Twoja paczka dotrze do
            Ciebie lub klienta w mega szybkim
            tempie!`,
                },
                {
                    text: `Jeżeli w pierwszych trzech miesiącach
            zamówisz z naszej aplikacji Avon ON za
            min. 150 zł zyskasz dostawę za darmo!`,
                },
            ],
        },
    ];


    const handleLoad = () => {
        if (window.innerWidth < 768) {
            setSwitchRef(true);
        }
        if (window.innerWidth > 300) {
            setIsScrollEnabled(true);
        }
    };

    useEffect(() => {
        window.addEventListener("DOMContentLoaded", handleLoad);

        return () => {
            window.removeEventListener("DOMContentLoaded", handleLoad);
        };
    }, []);

    return (
        <>
            <div ref={switchRef ? null : containerRef4} className={styles.section}>
                <div className={styles.online_section}>
                    <div className={styles.online_section__content}>
                        <div className={styles.mainHeader}>
                            <div>DLACZEGO WARTO</div>
                            <div className={styles.mainHeader__topSegment}>
                                ZOSTAĆ KONSULTANTKĄ AVON?
                            </div>
                        </div>
                        <div className={styles.textWrapper}>
                            Avon to Lider branży kosmetycznej - dołącz do nas i
                            korzystaj z wysokiej jakości kosmetyków w
                            atrakcyjnych cenach i gwarantowanych rabatów.
                        </div>
                        <div className={styles.contentContainer__buttons}>
                       
                        
                            <BigButton
                                customClickEvent={handleScrollToFormClick}
                                fullWidth
                            >
                                CHCĘ ZOSTAĆ KONSULTANTKĄ
                            </BigButton>
                            <ArrowButton
                                customClickEvent={handleArrowDownClick}
                            />
                        </div>
                    </div>
                </div>
                <div ref={switchRef ? containerRef4 : null} className={styles.scrollWrapper}>
                <HorizontalScroll2
                    ref2={containerRef4}
                    blocks={contentBlocks}
                    enableScroll={isScrollEnabled}
                    xPercentConfig={
                        window.innerWidth > 1200
                            ? -38
                            : window.innerWidth >= 768
                            ? -75
                            : -108
                    }
                />
                </div>
            </div>
        </>
    );
};

export default WhySection;
