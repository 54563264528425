import React from "react";
import BigButton from "../BigButton/BigButton";
import ArrowButton from "../ArrowButton/ArrowButton";
import { handleScrollToFormClick } from "../utils";
import svgDesign from "../../../assets/images/aprilForm/profit.svg";
import { scrollToElement } from "../../../utils";

import styles from "./ConsequencesSection.module.scss";

const ConsequencesSection = () => {
    const handleArrowDownClick = (e) => {
        scrollToElement(e, "scrollToTop");
    };

    return (
        <div className={styles.section} id="consequencesSection">
            <div className={styles.contentContainer}>
                <div className={styles.contentContainer__text}>
                    <h1 className={styles.contentContainer__text__title}>
                        Czy rezygnacja z bycia konsultantką wiąże się z
                        konsekwencjami?
                    </h1>
                    <div className={styles.contentContainer__text__description}>
                        Chcesz spróbować swoich sił jako konsultantka Avon, ale
                        nie wiesz, czy jest to praca dla Ciebie? Obawiasz się o
                        to, że poniesiesz konsekwencje w przypadku rezygnacji?

                        <br />
                        <br />
                        Nic z tych rzeczy! Podobnie jak rejestracja, tak i
                        rezygnacja jest całkowicie darmowa, nie wiąże się z
                        żadną skomplikowaną procedurą. Jedyne co tracisz to
                        możliwość pracy we wspaniałym, wspierającym się zespole
                        i dostęp do ulubionych kosmetyków w bardzo atrakcyjnej
                        cenie, bo każda konsultantka ma gwarantowany rabat!
                    </div>
                </div>

                <div className={styles.contentContainer__buttons}>
                    <BigButton
                        customClickEvent={handleScrollToFormClick}
                        fullWidth
                    >
                        DOŁĄCZ DO KLUBU AVON
                    </BigButton>
                    <ArrowButton customClickEvent={handleArrowDownClick} isUp/>
                </div>
            </div>
        </div>
    );
};

export default ConsequencesSection;
