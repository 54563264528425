import React from 'react';
import styles from './ArrowButton.module.scss';

const ArrowButton = (props) => {
  const {
    customClickEvent, isUp, noTopMargin, children,
  } = props;

  return (
    <button
      onClick={customClickEvent}
      className={`
        ${styles.buttonContainer}
        ${isUp ? styles.buttonContainer__rotateArrow : ''}
        ${noTopMargin ? styles.buttonContainer__noTopMargin : ''}
      `}
      type="button"
    >
      {children}
    </button>
  );
};

export default ArrowButton;
