import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styles from "./HorizontalScroll.module.scss";
gsap.registerPlugin(ScrollTrigger);

export default function HorizontalScroll({ ref2 }) {
    const avonBlocks = [
        {
            img: "/static/media/icon-rabat.b2bbcbdd.svg",
            desc: `<span class="bold">Zniżka <span class="avonColor emphasize">15%</span> na start i każde kolejne zamówienie</span> - możliwość zwiększenia rabatu nawet <span class="bold avonColor">do <span class="emphasize">40%</span></span>`,
        },
        {
            img: "/static/media/icon-gift.a86f6e53.svg",
            desc: `Prezenty, bonusy, <span class="bold">wcześniejszy dostęp do nowości i możliwość testowania produktów</span>`,
        },
        {
            img: "/static/media/icon-computer.d5bfe922.svg",
            desc: `<span class="bold">Praca na Twoich zasadach</span> - jak długo, kiedy, skąd chcesz. Możez pracować online, nie wychodząc z domu.`,
        },
        {
            img: "/static/media/icon-shopping.77a819ea.svg",
            desc: `<span class="bold">Biznes bez wymagań i ograniczeń</span> Liczba i wartość realizowanych zamówień zależy tylko od Ciebie i potrzeb Twoich klientów`,
        },
        {
            img: "/static/media/icon-hands.36dd5a03.svg",
            desc: `<span class="bold">Możliwość skorzystania z profesjonalnych szkoleń.</span>`,
        },
    ];

    const component = useRef(null);
    const slider = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            if (ref2.current === undefined) return;
            let panels = gsap.utils.toArray(".panel");
            gsap.to(panels, {
                xPercent:
                    (window.innerWidth > 1200
                        ? -31
                        : window.innerWidth > 768
                        ? -90
                        : -105) *
                    (panels.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: ref2.current,
                    pin: true,
                    scrub: 1,
                    //   snap: 1 / (panels.length - 1),
                    end: () => "+=" + ref2.current.offsetWidth,
                    //   markers: true
                },
            });
        }, component);
        return () => ctx.revert();
    }, [ref2.current, window.innerWidth]);
    return (
        <div ref={component}>
            <div ref={slider} className={styles.slideWrapper}>
                {avonBlocks?.map((item, index) => (
                    <div key={index} className={`${styles.slideElement} panel`}>
                        <img className={styles.centerImg} src={item.img} />
                        <h3
                            dangerouslySetInnerHTML={{ __html: item.desc }}
                        ></h3>
                    </div>
                ))}
            </div>
        </div>
    );
}
