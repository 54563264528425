/* eslint-disable no-nested-ternary */
import styled, { css, keyframes } from "styled-components";

const error = "#EB4802";
const top = "20px";
const correct = "#616161";
const edit = "#616161";
const regular = "#C7C7C7";
const labelUp = keyframes`
  0% {
    top: ${top};
  }
  100% {
    top: 0;
  }
`;

const labelDown = keyframes`
  0% {
    top: 0
  }
  100% {
    top: ${top}
  }
`;

const paddingLeft = "10px";

export const FormContainer = styled.div`
    width: 100%;
    height: 100%;
    /* width:36.459vw; */
    position: relative;
    padding: 20px 17px;

    @media screen and (min-width: 1200px) {
        form[name="consultantForm"] .container {
            font-size: 0.57297vw;
        }
        padding: 2.08333vw 3.125vw;
    }
`;

export const FormEl = styled.form`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    // @media screen and (max-width: 1280px) {
    //   padding: 0 20px 0 30px;
    // }
`;

export const InputBox = styled.label`
    padding: 0.625vw 0;
    position: relative;
    font-size: 10px;
    /* transition: all 2s ease 0s; */

    ${(props) => {
        switch (props.width) {
            case "short1":
                return css`
                    width: 40%;

                    @media screen and (max-width: 1200px) {
                        width: 73.62%;
                    }
                `;
            case "wide1":
                return css`
                    width: 54%;
                    @media screen and (max-width: 1200px) {
                        width: 100%;
                    }
                `;
            case "wide-solo":
                return css`
                    width: 67%;
                    @media screen and (max-width: 1200px) {
                        width: 100%;
                    }
                `;
            case "short2":
                return css`
                    width: 45%;
                    @media screen and (max-width: 1200px) {
                        width: 48%;
                    }
                `;
            case "wide":
                return css`
                    width: 100%;
                    /* max-width: 425px; */
                    /* @media screen and (min-width: 1200px) {
            max-width: 220px;
           } */
                    @media screen and (min-width: 1741px) {
                        /* max-width: 18.75vw; */
                    }
                    @media screen and (min-width: 1200px) {
                        max-width: 18.75vw;
                        width: 54%;
                    }
                    // @media screen and (max-width: 420px) {
                    //   margin-right: 0;
                    // }
                `;
            case "short":
                return css`
                    width: 100%;
                    @media screen and (min-width: 1741px) {
                        width: calc(50% - 10px);
                        max-width: 325px;
                    }
                    @media screen and (min-width: 1200px) {
                        width: 45%;
                    }

                    // @media screen and (max-width: 420px) {
                    //   width: 40%;
                    // }
                `;

            default:
                return css`
                    width: 100%;

                    @media screen and (min-width: 1740px) {
                        /* min-width: 150px;
            max-width: 200px; */
                    }
                    @media screen and (min-width: 1200px) {
                        width: 40%;
                    }

                    // @media screen and (max-width: 1366px) {
                    //   width: 160px;
                    // }

                    // @media screen and (max-width: 1280px) {
                    //   width: 145px;
                    // }
                    // @media screen and (max-width: 420px) {
                    //   width: 100%;
                    //   margin-right: 0;
                    // }
                `;
        }
    }}
    ::before {
        top: 50%;
        transform: translateY(-50%);
        content: "${(props) => {
            return props.label || "";
        }}";
        position: absolute;
        left: ${paddingLeft};
        color: ${(props) => {
            return props.val
                ? props.err
                    ? error
                    : props.focus
                    ? edit
                    : correct
                : "#212121";
        }};
        font-size: 14px;
        @media screen and (min-width: 1200px) {
            font-size: 0.729166vw;
        }

        animation-duration: 0.2s;
        ${(props) => {
            return props.val
                ? css`
                      font-size: 12px;
                      @media screen and (min-width: 1200px) {
                          font-size: 0.625vw !important;
                          top: 0px;
                      }
                      animation-name: ${labelUp};
                      left: 0;
                      top: -5px;
                  `
                : css`
                      animation-name: ${labelDown};
                  `;
        }};
    }

    ::after {
        content: "${({ err, focus, errMsg }) => {
            return err && !focus ? errMsg : "";
        }}";
        position: absolute;
        left: 0;
        /* top: 60px; */
        bottom: 0.8em;
        left: 1em;
        white-space: nowrap;
        width: 100%;
        color: ${error};
        font-size: 10px;
        @media screen and (min-width: 1200px) {
            font-size:0.5208vw;
        bottom: 1.4em;
        left: 1em;
        }
    }
`;

export const InputEl = styled.input`
    width: 100%;
    font-size: 14px;

    border: 1px solid
        ${(props) => {
            return props.value
                ? props.err
                    ? error
                    : props.focus
                    ? edit
                    : correct
                : props.err
                ? error
                : regular;
        }};
    padding: 15px 20px 15px ${paddingLeft};
    /* padding: 12.5px 12px 12.5px 25px; */

    @media screen and (min-width: 1200px) {
        padding: 0.65105vw 25px 0.65105vw 12px;
    }
    //padding-left: ${paddingLeft};
    //padding-right: 20px;
    background-color: ${({ err, focus }) => {
        return err && !focus ? "rgba(133, 17, 104, 0.05)" : "transparent";
    }};
`;

export const IconBox = styled.div`
    position: absolute;
    left: -20px;
    top: 18px;
    font-size: 16px;
`;

export const ValidationIcon = styled.div`
    position: absolute;
    font-size: 14px;
    top: 19px;
    right: 5px;
`;

export const CorrectIcon = styled(ValidationIcon)`
    color: ${correct};
`;

export const ErrorIcon = styled(ValidationIcon)`
    color: ${error};
`;

export const Header = styled.div`
    /* margin-bottom: 15px; */
    font-size: 16px;
    @media screen and (min-width: 1200px) {
        font-size: 0.833333vw;
    }
`;
