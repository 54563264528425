import React from "react";
import SmallButtonAndHeader from "../SmallButtonAndHeader/SmallButtonAndHeader";
import arrowUpright from "../../../assets/images/aprilForm/arrow_upright.svg";
import googlePlayLogo from "../../../assets/images/aprilForm/googlePlay.svg";
import appStoreLogo from "../../../assets/images/aprilForm/AppStore.svg";
import appGalleryLogo from "../../../assets/images/aprilForm/AppGallery.svg";
import { handleScrollToFormClick } from "../utils";
import styles from "./HowToBeConsultantSection.module.scss";

const HowToBeConsultantSection = () => {
    const highlightForm = () => {
        const element = document.getElementById("scrollToFormTop");
        element.style.animation = "highlightForm 0.5s forwards";
        setTimeout(() => {
            element.style.animation = "initial";
        }, 500);
    };

    return (
        <div className={styles.section}>
            <div className={styles.mainHeader}>
                <div className={styles.mainHeader__topSegment}>DOŁĄCZ</div>
                <div>DO KLUBU </div>
                <div>AVON</div>
            </div>
        </div>
    );
};

export default HowToBeConsultantSection;
