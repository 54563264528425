import styled from "styled-components";


export const Carousel = styled.div`
    width: 100%;
    overflow: hidden;

    .carouselInner {
        width:100%;
        height: fit-content;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;

        transition: transform 1s;


        .iframeContainer {  
            position: relative;  
            width: 56.67vw;
            padding-bottom: 31.93vw;
            height: 0;
            margin: auto;
        }

        & iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .controls {
        
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: clamp(0.9rem, 0.83vw, 1.6rem);
        width: fit-content;
        margin-right: 13.18vw;
        margin-top: 1em;
        margin-left: auto;
        z-index: 100;
    }

    .dotsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        .carouselDots {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 2em;

          .carouselDot {
            width: 0.5em;
            height: 0.5em;
            border-radius: 100%;
            background: #9d9d9d;
            margin-right: 0.5em;
            transition: background-color 0.5s;
          }

          .carouselDot:last-child {
            margin-right: 0;
          }

          .carouselDotActive {
            width: 0.75em;
            height: 0.75em;
            background: #343434;
          }
        }

        .slideNumber {
          font-size: clamp(0.7rem, 0.63vw, 1.2rem);
          color: #9d9d9d;
          margin-top: 0.4em;
          width: fit-content;
          user-select: none;

          .currentSlide {
            color: #343434;
            font-weight: 600;
          }
        }
    }

    @media screen and (max-width: 1100px) {

        .carouselInner { 
          transition: transform 0.7s;   

          .iframeContainer {   
              width: 100%;
              padding-bottom: 56.25%;
          }
        }
        .controls {
            width: 100%;
            justify-content: space-between;
            margin-right: auto;
            font-size: clamp(0.8rem, 3.88vw, 1.4rem);
        }

        
        .dotsContainer {
          .slideNumber {
            font-size: clamp(0.8rem, 3.88vw, 1.4rem);
          }
        }
    }
`;

export const CarouselInner = styled.div`
`;