import React from 'react';

const Agreement2 = (data) => {
  return (
    <div className="agreement">
      <label className="container">
        <input
          type="checkbox"
          id={data.input.id}
          name={data.input.name}
        />
        <span
          className="checkmark"
          id={`${data.input.id}checkmark`}
          name={data.input.name}
          onClick={data.changed}
        />
        <span className="" onClick={data.changed}>
          {data.shortAgr ? (
            <>
              Wyrażam zgodę na kontakt mailowy, sms, telefoniczny
              ze strony Avon Cosmetics Polska Sp. z o.o, Lidera
              Sprzedaży w celu przedstawienia mi oferty dla
              Konsultantek. Informacja o przetwarzaniu danych
              osobowych
            </>
          ) : (
            <>
            Chcę skorzystać z <strong>SUPER PROMOCJI</strong>! Zgadzam się na otrzymywanie informacji o promocjach oraz dopasowanych do mnie reklam i spersonalizowanych ofert.
            </>
          )}
        </span>
        {data.input.error ? (
          <div className="error">{data.input.errorMessage}</div>
        ) : null}
      </label>
    </div>
  );
};

export default Agreement2;
