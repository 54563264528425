/* eslint-disable array-callback-return */
import React, { Component } from "react";
import axios from "axios";
import Agreement1 from "./Agreements/Agreement1";
import Agreement2 from "./Agreements/Agreement2";
import ThankYou from "../ThankYou/ThankYou";
import { ReCaptcha } from "react-recaptcha-v3";
import path from "../../config/api.config";
import * as validators from "../../utils/validators";
import DuplicityError from "../DuplicityError/DuplicityError";
import { blockInvalidChar } from "./blockInvalidChar";
import {
    CorrectIcon,
    ErrorIcon,
    Footer,
    FormContainer,
    FormEl,
    Header,
    IconBox,
    InputBox,
    InputEl,
} from "./Form.component.styles";
import { getSearchInfo, b64_to_utf8 } from "../../utils";
import { pageLoaded } from "../../utils/analytics.js";
import { Button } from "../../styles/styles";
import { state as initialState } from "../../states/formState";

class FormComponent extends Component {
    componentDidMount() {
        this.setState({ appSrcCode: getSearchInfo("appSrcCd") || window.location.pathname == "/dolacz-do-avon" ? "53" : window.location.pathname == "/testlp" ? "55" : "51" }); 
        this.setState({ src_r: getSearchInfo("src_r") }); // redirect from social prospecting default page
        this.setState({ utm_term: getSearchInfo("utm_term") });
        this.setState({ refRepId: b64_to_utf8(getSearchInfo("refRepId")) });
        this.setState({ rcrtrId: b64_to_utf8(getSearchInfo("rcrtrId")) });

        pageLoaded();
    }

    maxDate = () => {
        let year = new Date();
        year.setFullYear(year.getFullYear() - 16); // min age set to 16
        return year;
    };

    state = { ...initialState };

    // validators

    validateForm = () => {
        let inputs = [...this.state.inputs];
        let isValid = true;

        // eslint-disable-next-line array-callback-return
        inputs.map((input, index) => {
            const inputId = input.id;
            if (input.required) {
                if (inputId === "Phone") {
                }

                switch (inputId) {
                    case "firstName":
                    case "lastName":
                        if (!validators.validateNameValue(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "Email":
                        if (!validators.validateEmail(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "Phone":
                        if (!validators.validatePhone(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "Street":
                        if (!validators.validateStreetName(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "StreetNr":
                        if (!validators.validateStreetNo(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "PostCode":
                        if (!validators.validatePostCode(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "City":
                        if (!validators.validateCityName(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "BirthDate":
                        if (
                            !input.value ||
                            new Date(input.value) > this.maxDate() ||
                            input.value === "Invalid date"
                        ) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    default:
                        break;
                }
            } else {
                if (input.value) {
                    if (!validators.validateStreetNo(input.value)) {
                        isValid = false;
                        inputs[index].error = true;
                    } else {
                        inputs[index].error = false;
                    }
                } else {
                    inputs[index].error = false;
                }
            }
        });

        this.setState({ inputs: inputs });

        return isValid;
    };

    validateCheckboxes = () => {
        let checkboxes = [...this.state.checkboxes];
        let isValid = true;

        // eslint-disable-next-line array-callback-return
        checkboxes.map((checkbox, index) => {
            if (checkbox.required) {
                checkboxes[index].error = !document.getElementById(checkbox.id)
                    .checked;
                isValid = document.getElementById(checkbox.id).checked;
            } else {
                checkboxes[index].error = false;
            }
        });

        this.setState({ checkboxes: checkboxes });
        return isValid;
    };

    prepareDataToSend = () => {
        const inputs = this.state.inputs;
        const checkboxes = this.state.checkboxes;
        let requestJson = {
            src_r: this.state.src_r,
            recaptchaToken: this.state.recaptchaToken || null,
            frstNm: "frstNm", // First name
            lastNm: "lastNm", // Last name
            emailAddrTxt: "emailAddrTxt", // Email
            mobilePhoneNr: "mobilePhoneNr", // Mobile phone number
            rcrtrId: this.state.rcrtrId, // uplineEmail
            apptSrceCd: this.state.appSrcCode, // Application source code
            utmTerm: this.state.utm_term,
            addresses: [
                {
                    addrLocTyp: "HOME",
                    postCd: "postCd", // Post Code
                    city: "city", // City
                    strAddr1Txt: "strAddr1Txt", // Street
                    strAddr2Txt: "strAddr2Txt", // Apartment Number
                    strAddr3Txt: "", // Apartment Number
                },
            ],
            agrmnts: [
                {
                    agrmntTyp: "agrmntInd",
                    agrmntVerNr: "1",
                    agrmntAcptdInd: false,
                },
                {
                    agrmntTyp: "prsnlDataStorgInd",
                    agrmntVerNr: "1",
                    agrmntAcptdInd: false,
                },
                {
                    agrmntTyp: "prvcyAgrmntInd",
                    agrmntVerNr: "1",
                    agrmntAcptdInd: false,
                },
            ],
            cmnctn: [
                {
                    comnctnTyp: "ALWD",
                    email: false,
                    phone: false,
                    sms: false,
                },
            ],
        };

        if (this.state.refRepId && this.state.refRepId !== this.state.rcrtrId) {
            requestJson.rfrlId = this.state.refRepId;
        }

        // eslint-disable-next-line array-callback-return
        inputs.map((input) => {
            if (input.id === "firstName") {
                requestJson.frstNm = input.value;
            }

            if (input.id === "BirthDate") {
                requestJson.birthDate = input.value;
            }

            if (input.id === "lastName") {
                requestJson.lastNm = input.value;
            }

            if (input.id === "Email") {
                requestJson.emailAddrTxt = input.value;
            }

            if (input.id === "Phone") {
                requestJson.mobilePhoneNr =
                    "48" + input.value.replace(/ /g, "");
            }

            if (input.id === "Street") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].strAddr1Txt = input.value;
                });
            }

            if (input.id === "StreetNr") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].strAddr2Txt = input.value;
                });
            }

            if (input.id === "ApartmentNr") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].strAddr3Txt = input.value;
                });
            }

            if (input.id === "PostCode") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].postCd = input.value;
                });
            }

            if (input.id === "City") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].city = input.value;
                });
            }
        });

        // eslint-disable-next-line array-callback-return
        checkboxes.map((checkbox) => {
            if (checkbox.id === "Agreement1") {
                requestJson.agrmnts.map((agreement, i) => {
                    requestJson.agrmnts[i].agrmntAcptdInd = checkbox.isChecked;
                });
            }

            if (checkbox.id === "Agreement2") {
                requestJson.cmnctn.map((agreement, i) => {
                    requestJson.cmnctn[i].email = checkbox.isChecked;
                    requestJson.cmnctn[i].phone = checkbox.isChecked;
                    requestJson.cmnctn[i].sms = checkbox.isChecked;
                });
            }
        });

        return requestJson;
    };

    dateToSendParser = (date) => {
        const y = date.getFullYear();
        const m = date.getMonth() + 1;
        const d = date.getDate();
        const mm = m < 10 ? "0" + m : m;
        const dd = d < 10 ? "0" + d : d;

        return `${y}-${mm}-${dd}`;
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (this.validateForm() && this.validateCheckboxes()) {
            this.setState({ isFormSent: true });
            const errorFunc = this.prepareErrorResponse;
            const successFunc = this.prepareSuccessResponse;

            try {
                switch (window.location.pathname) {
                    case "/form3":
                        // eslint-disable-next-line no-undef
                        ga("join.send", {
                            hitType: "event",
                            eventCategory: "Dołącz do Avon - button click",
                            eventAction: "Test A/B - zgoda marketingowa",
                            eventLabel: "Wersja A",
                        });
                        break;
                    case "/form2":
                        // eslint-disable-next-line no-undef
                        ga("join.send", {
                            hitType: "event",
                            eventCategory: "Dołącz do Avon - button click",
                            eventAction: "Test A/B - zgoda marketingowa",
                            eventLabel: "Wersja B",
                        });
                        break;
                    default:
                        // eslint-disable-next-line no-undef
                        ga("join.send", {
                            hitType: "event",
                            eventCategory: "dolacz-do-klubu",
                            eventAction: window.location.pathname,
                            eventLabel: "form sent",
                        });
                }
            } catch (e) {}


            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(process.env.REACT_APP_API_RE_CAPTCHA_KEY, { action: 'form_action' }).then(token => {
                    this.setState({ recaptchaToken: token });
                    axios
                    .post(path, this.prepareDataToSend(), {
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(function (response) {
                        return successFunc(response);
                    })
                    .catch(function (error) {
                        if (!error.response) {
                            return errorFunc();
                        } else {
                            return errorFunc(error.response.data.errCd);
                        }
                    });
                });
              });
  
        } else {
            console.log("form is not valid");
        }
    };

    prepareErrorResponse = (code) => {
        const inputs = this.state.inputs;

        try {
            // eslint-disable-next-line no-undef
            ga("join.send", {
                hitType: "event",
                eventCategory: "dolacz-do-klubu",
                eventAction: window.location.pathname,
                eventLabel: `error code: ${code || "unknown error"}`,
            });
        } catch (e) {}

        switch (code) {
            case "DUPLICITY_REP_EMAIL":
                inputs.map((input, i) => {
                    if (input.id === "Email") {
                        inputs[i].error = true;
                        inputs[i].errorMessage =
                            "Podany adres jest już wykorzystywany";
                    }
                });
                break;
            case "DUPLICITY_REP_MOBILE":
                inputs.map((input, i) => {
                    if (input.id === "Phone") {
                        inputs[i].error = true;
                        inputs[i].errorMessage =
                            "Podany nr telefonu jest już wykorzystywany";
                    }
                });
                break;
            case "MULTIPLE_ACCOUNTS_NOT_REAPPOINTABLE":
            case "DUPLICITY_APPOINTMENT_NOT_POSSIBLE":
            case "DUPLICITY_REINSTATABLE_REPRESENTATIVE":
                this.setState({ duplicityError: true });
                this.setState({ error: true });
                break;
            default:
                this.setState({ error: true });
                break;
        }

        this.setState({ inputs: inputs });
        this.setState({ isFormSent: false });
    };

    prepareSuccessResponse = (response) => {
        try {
            // eslint-disable-next-line no-undef
            ga("join.send", {
                hitType: "event",
                eventCategory: "dolacz-do-klubu",
                eventAction: window.location.pathname,
                eventLabel: `registered apptId: ${
                    response.data.apptId || "no apptId"
                }`,
            });
            window.dataLayer.push({
                event: "typ",
                leadNumber: response.data.id || "no lead number",
                typeForm: window.location.pathname,
                repId: response.data.apptId || "no apptId",
            });
        } catch (e) {}

        this.setState({ responseSuccess: true });
    };

    onFocusHandler = (id) => {
        const inputs = [...this.state.inputs];
        inputs.forEach((elem) => {
            if (elem.id === id) elem.focus = true;
        });

        this.setState({ inputs: inputs });
    };

    onBlurHandler = (id) => {
        const inputs = [...this.state.inputs];
        inputs.forEach((elem) => {
            if (elem.id === id) elem.focus = false;
        });

        this.setState({ inputs: inputs });
    };

    changeInputHandler = (event, id) => {
        const inputIndex = this.state.inputs.findIndex((i) => {
            return i.id === id;
        });

        const input = {
            ...this.state.inputs[inputIndex],
        };

        switch (input.id) {
            case "firstName":
            case "lastName":
                if (!validators.validateNameValue(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;

            case "Email":
                if (!validators.validateEmail(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "Street":
                if (!validators.validateStreetName(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "StreetNr":
                if (!validators.validateStreetNo(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "ApartmentNr":
                const apartmentValid = validators.validateApartmentNo(
                    event.target.value
                );

                if (!apartmentValid) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else if (apartmentValid === -1) {
                    input.error = false;
                    input.class = "";
                    input.correct = null;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "City":
                if (!validators.validateCityName(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "PostCode":
                const notValidPostCode = !validators.validatePostCode(
                    event.target.value
                );
                let eValue = event.target.value;
                if (eValue.length > 2 && eValue.indexOf("-") === -1) {
                    String.prototype.splice = function (idx, rem, str) {
                        return (
                            this.slice(0, idx) +
                            str +
                            this.slice(idx + Math.abs(rem))
                        );
                    };
                    input.value = eValue.splice(2, 0, "-");
                } else if (eValue.length <= 6 && notValidPostCode) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                    input.value = event.target.value;
                } else if (eValue.length > 6) {
                    return;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                    if (!notValidPostCode) {
                        input.value = event.target.value;
                    }
                }
                break;

            case "Phone":
                const notValid = !validators.validatePhone(event.target.value);
                if (notValid && event.target.value.length <= 9) {
                    input.value = event.target.value;
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                    if (!notValid) {
                        input.value = event.target.value;
                    }
                }
                break;
            case "Referral":
                const isRefValid = validators.validateAcc(event.target.value);
                if (!isRefValid) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else if (isRefValid === -1) {
                    input.error = false;
                    input.class = "";
                    input.correct = null;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            // const notValidAcc = !validators.validateAcc(event.target.value);
            // if (notValidAcc && event.target.value.length <= 9) {
            //     input.value = event.target.value;
            //     input.error = true;
            //     input.class = "errorBg";
            //     input.correct = false;
            // } else {
            //     input.error = false;
            //     input.class = "correct";
            //     input.correct = true;
            //     if (!notValidAcc) {
            //         input.value = event.target.value;
            //     }
            // }
            // break;
            default:
                input.value = event.target.value;
                break;
        }

        const inputs = [...this.state.inputs];
        inputs[inputIndex] = input;

        this.setState({ inputs: inputs });
    };

    changeCheckboxHandler = (event, id) => {
        const checkboxIndex = this.state.checkboxes.findIndex((i) => {
            return i.id === id;
        });

        const checkbox = {
            ...this.state.checkboxes[checkboxIndex],
        };

        if (checkbox.isChecked) {
            checkbox.isChecked = false;
            if (checkbox.id === "Agreement1") {
                checkbox.error = true;
            }
        } else {
            checkbox.isChecked = true;
            checkbox.error = false;
        }
        const checkboxes = [...this.state.checkboxes];
        checkboxes[checkboxIndex] = checkbox;

        this.setState({ checkboxes: checkboxes });
    };

    dateHandler = (date, id) => {
        const inputIndex = this.state.inputs.findIndex((i) => {
            return i.id === id;
        });

        const input = {
            ...this.state.inputs[inputIndex],
        };

        input.startDate = date;
        input.value = date;

        if (
            (input.value && new Date(input.value) > this.maxDate()) ||
            input.value === "Invalid date"
        ) {
            input.error = true;
            input.class = "errorBg";
            input.correct = false;
        } else if (input.value != null) {
            input.error = false;
            input.class = "correct";
            input.correct = true;
        }

        input.startDate = date;
        input.value = date;

        const inputs = [...this.state.inputs];
        inputs[inputIndex] = input;

        this.setState({ inputs: inputs });
    };

    verifyCallback = (recaptchaToken) => {
        this.setState({ recaptchaToken: recaptchaToken });
    };

    render() {
        let inputs = this.state.inputs.map(
            ({
                id,
                label,
                type,
                name,
                value,
                error,
                correct,
                errorMessage,
                icon,
                focus,
                width,
            }) => {
                const handler =
                    id !== "BirthDate"
                        ? (event) => this.changeInputHandler(event, id)
                        : (date) => this.dateHandler(date, id);
                if (
                    id !== "Referral" ||
                    (id === "Referral" && this.state.appSrcCode === "45")
                ) {
                    if (id === "Referral" && this.state.refRepId) {
                        value = this.state.refRepId || "";
                        correct = true;
                    }

                    return (
                        <InputBox
                            key={id}
                            label={label}
                            val={value}
                            err={error}
                            errMsg={errorMessage}
                            correct={correct}
                            focus={focus}
                            width={width}
                        >
                            {icon && (
                                <IconBox>
                                    <i className={icon} />
                                </IconBox>
                            )}

                            <InputEl
                                type={type}
                                name={name}
                                id={id}
                                value={value}
                                onKeyDown={type=="number" ? blockInvalidChar : null}
                                onChange={handler}
                                err={error}
                                focus={focus}
                                onFocus={() => this.onFocusHandler(id)}
                                onBlur={() => this.onBlurHandler(id)}
                                disabled={
                                    id === "Referral" && this.state.refRepId
                                        ? true
                                        : false
                                }
                            />

                            {!focus && error && (
                                <ErrorIcon>
                                    <i className={"fa fa-exclamation-circle"} />
                                </ErrorIcon>
                            )}
                            {!focus && correct && (
                                <CorrectIcon>
                                    <i className={"fa fa-check"} />
                                </CorrectIcon>
                            )}
                        </InputBox>
                    );
                } else {
                    return null;
                }
            }
        );

        return (
            <FormContainer>
                <ReCaptcha
                    sitekey={process.env.REACT_APP_API_RE_CAPTCHA_KEY}
                    action="form_action"
                    verifyCallback={this.verifyCallback}
                />
                {this.state.responseSuccess ? (
                    <ThankYou />
                ) : (
                    <>
                        {this.state.error ? (
                            this.state.duplicityError ? null : (
                                <div className="error-resp">
                                    Wystąpił błąd podczas przesyłania danych.
                                    <br />
                                    Odczekaj chwilę i spróbuj ponownie.
                                </div>
                            )
                        ) : (
                            <Header>
                                <h1>Dołącz do Klubu Avon</h1>
                            </Header>
                        )}
                        {this.state.duplicityError ? (
                            <DuplicityError />
                        ) : (
                            <FormEl
                                name="consultantForm"
                                onSubmit={this.submitHandler}
                            >
                                {inputs}

                                <Agreement1
                                    input={this.state.checkboxes[0]}
                                    changed={(event) =>
                                        this.changeCheckboxHandler(
                                            event,
                                            this.state.checkboxes[0].id
                                        )
                                    }
                                />
                                <Agreement2
                                    shortAgr={this.props.shortAgr}
                                    input={this.state.checkboxes[1]}
                                    changed={(event) =>
                                        this.changeCheckboxHandler(
                                            event,
                                            this.state.checkboxes[1].id
                                        )
                                    }
                                />
                                <Footer id={"toScroll"}>
                                    {this.state.isFormSent ? (
                                        "Zaczekaj, przetwarzamy Twoje zgłoszenie"
                                    ) : (
                                        <Button type="submit" small={true}>
                                            DOŁĄCZ DO AVON
                                        </Button>
                                    )}
                                </Footer>
                            </FormEl>
                        )}
                    </>
                )}
            </FormContainer>
        );
    }
}

export default FormComponent;
