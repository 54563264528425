/* eslint-disable array-callback-return */
import React, { Component, useEffect  } from "react";
import axios from "axios";
import Agreement1 from "./Agreements/Agreement1";
import Agreement2 from "./Agreements/Agreement2";
import ThankYou from "./ThankYou/ThankYou";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import path from "../../../config/api.config";
import * as validators from "../../../utils/validators";
import DuplicityError from "./DuplicytyError/DuplicytyError";
import { blockInvalidChar } from "./blockInvalidChar";
import {
    CorrectIcon,
    ErrorIcon,
    Footer,
    FormContainer,
    FormEl,
    Header,
    IconBox,
    InputBox,
    InputEl,
} from "./FormComponent.styles";
import { getSearchInfo, b64_to_utf8 } from "../../../utils";
import { pageLoaded } from "../../../utils/analytics.js";
import { Button } from "../../../styles/styles";
import { state as initialState } from "../../../states/AprilFormState";
import BigButton from '../BigButton/BigButton';
import ArrowButton from '../ArrowButton/ArrowButton';
import Tooltip from 'rc-tooltip';
import { scrollToElement } from '../../../utils';
import 'rc-tooltip/assets/bootstrap_white.css';
import './overwrite-rc-tooltip.scss';
import styles from './FormComponent.module.scss';
import GoogleLoginButton from '../../GoogleLogin/GoogleLogin';
import {jwtDecode} from 'jwt-decode';
import { googleLogout } from '@react-oauth/google';
import { el } from "date-fns/locale";


class FormComponent extends Component {

    componentDidMount() {
        loadReCaptcha(process.env.REACT_APP_API_RE_CAPTCHA_KEY);

        this.setState({ appSrcCode: getSearchInfo("appSrcCd") || "53" });
        this.setState({ src_r: getSearchInfo("src_r") }); // redirect from social prospecting default page
        this.setState({ utm_term: getSearchInfo("utm_term") });
        this.setState({ refRepId: b64_to_utf8(getSearchInfo("refRepId")) });
        this.setState({ rcrtrId: b64_to_utf8(getSearchInfo("rcrtrId")) });

        if (localStorage.getItem('google_access_token')) {
            this.setState ({ isGoogleLoggedIn: true });
        }   
        else {
            this.setState ({ isGoogleLoggedIn: false });
        }



        pageLoaded();
    }

    

    maxDate = () => {
        let year = new Date();
        year.setFullYear(year.getFullYear() - 16); // min age set to 16
        return year;
    };


    handleLoginSuccess = (userInfo) => {
        console.log('Login Success:', userInfo);
        this.setState({ isGoogleLoggedIn: true });
        const address = userInfo.addresses ? this.extractPolishAddressComponents(userInfo.addresses?.[0].formattedValue) : {};
        let updatedInputs = this.state.inputs.map(input => {
            if (input.id === 'Email') {
                return { ...input, value: userInfo.email };
            }
            if (input.id === 'firstName') {
                return { ...input, value: userInfo.given_name };
            }
            if (input.id === 'lastName') {
                return { ...input, value: userInfo.family_name };
            }
            if (input.id === 'Phone') {
                return { ...input, value: userInfo.phoneNumbers ? userInfo.phoneNumbers[0].value.replace(/\s+/g, '') : '' };
            }
            if (input.id === 'Street') {
                return { ...input, value: address.street || '' };
            }
            if (input.id === 'StreetNr') {
                return { ...input, value: address.houseNumber || '' };
            }
            if (input.id === 'ApartmentNr') {
                return { ...input, value: address.apartmentNumber || '' };
            }
            if (input.id === 'PostCode') {
                return { ...input, value: address.postalCode || '' };
            }
            if (input.id === 'City') {
                return { ...input, value: address.city || '' };
            }
            return input;
        });

        this.setState({ inputs: updatedInputs });
    };

    handleLoginFailure = (error) => {
        console.error('Login Failed:', error);
    };

    handleLogout = () => {
        googleLogout();
        // remove the access token from localStorage
        localStorage.removeItem('google_access_token');
        this.setState({ inputs: initialState.inputs });
        this.setState({ isGoogleLoggedIn: false });
    };


     extractPolishAddressComponents = (addressString) => {
        const address = {};
    
        // Regex patterns for different parts of a Polish address
        const streetPattern = /(ul\.\s)?[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+\s\d+/i;  // Matches "ul. Mainowa 23"
        const houseNumberPattern = /\d+/;  // Matches "23"
        const apartmentNumberPattern = /(\/\d+|m\.\s?\d+)/i;  // Matches "/45" or "m. 24"
        const postalCodePattern = /\b\d{2}-\d{3}\b/;  // Matches postal codes like "00-001"
        const cityPattern = /([A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+\s?)+$/i;  // Matches city names like "Warszawa"
    
        // Extract street with house number
        const streetMatch = addressString.match(streetPattern);
        if (streetMatch) {
            address.street = streetMatch[0].replace(/ul\.\s/i, '');
            // removw "ul." prefix and number suffix
     

            
            // Extract house number from the street
            const houseNumberMatch = address.street.match(houseNumberPattern);
            if (houseNumberMatch) {
                address.houseNumber = houseNumberMatch[0];
            }
    
            // Extract apartment number if it exists
            const apartmentNumberMatch = addressString.match(apartmentNumberPattern);
            if (apartmentNumberMatch) {
                address.apartmentNumber = apartmentNumberMatch[0].replace(/m\.\s?/i, '').replace(/\//, '');
            }

            address.street = address.street.replace(/\d+/, '');
        }
    
        // Extract postal code
        const postalCodeMatch = addressString.match(postalCodePattern);
        if (postalCodeMatch) {
            address.postalCode = postalCodeMatch[0];
        }
    
        // Extract city
        const cityMatch = addressString.match(cityPattern);
        if (cityMatch) {
            address.city = cityMatch[0].trim();
        }
    
        return address;
    };





    responseMessage = (response) => {
        // Decode the JWT token to extract basic user information
        const userObject = jwtDecode(response.credential);
    
        // Initialize the inputs with the information from the JWT token
        let updatedInputs = this.state.inputs.map(input => {
            if (input.id === 'Email') {
                return { ...input, value: userObject.email };
            }
            if (input.id === 'firstName') {
                return { ...input, value: userObject.given_name };
            }
            if (input.id === 'lastName') {
                return { ...input, value: userObject.family_name };
            }
            return input;
        });
    
        // Make an API request to Google People API to get phone numbers and addresses
        axios.get('https://people.googleapis.com/v1/people/me?personFields=phoneNumbers,addresses', {
            headers: {
                'Authorization': `Bearer ${response.access_token}`  // Use the access token from the response
            }
        })
        .then(res => {
            const phoneNumber = res.data.phoneNumbers ? res.data.phoneNumbers[0].value : '';
            const address = res.data.addresses ? res.data.addresses[0] : {};
    
            // Update the inputs with the additional data
            updatedInputs = updatedInputs.map(input => {
                if (input.id === 'Phone') {
                    return { ...input, value: phoneNumber };
                }
                if (input.id === 'Street') {
                    return { ...input, value: address.streetAddress || '' };
                }
                if (input.id === 'City') {
                    return { ...input, value: address.city || '' };
                }
                if (input.id === 'PostCode') {
                    return { ...input, value: address.postalCode || '' };
                }
                return input;
            });
    
            // Update the state with the filled inputs
            this.setState({ inputs: updatedInputs });
        })
        .catch(error => {
            console.error('Error fetching additional user details:', error);
        });
    
        console.log('Login Success:', userObject);
    };

     errorMessage = (error) => {
        console.log(error);
    };

    state = { ...initialState };

    // validators

    validateForm = () => {
        let inputs = [...this.state.inputs];
        let isValid = true;

        // eslint-disable-next-line array-callback-return
        inputs.map((input, index) => {
            const inputId = input.id;
            if (input.required) {
                if (inputId === "Phone") {
                }

                switch (inputId) {
                    case "firstName":
                    case "lastName":
                        if (!validators.validateNameValue(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "Email":
                        if (!validators.validateEmail(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "Phone":
                        if (!validators.validatePhone(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "Street":
                        if (!validators.validateStreetName(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "StreetNr":
                        if (!validators.validateStreetNo(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "PostCode":
                        if (!validators.validatePostCode(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "City":
                        if (!validators.validateCityName(input.value)) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    case "BirthDate":
                        if (
                            !input.value ||
                            new Date(input.value) > this.maxDate() ||
                            input.value === "Invalid date"
                        ) {
                            isValid = false;
                            inputs[index].error = true;
                        } else {
                            inputs[index].error = false;
                        }
                        break;
                    default:
                        break;
                }
            } else {
                if (input.value) {
                    if (!validators.validateStreetNo(input.value)) {
                        isValid = false;
                        inputs[index].error = true;
                    } else {
                        inputs[index].error = false;
                    }
                } else {
                    inputs[index].error = false;
                }
            }
        });

        this.setState({ inputs: inputs });

        return isValid;
    };

    validateCheckboxes = () => {
        let checkboxes = [...this.state.checkboxes];
        let isValid = true;

        // eslint-disable-next-line array-callback-return
        checkboxes.map((checkbox, index) => {
            if (checkbox.required) {
                checkboxes[index].error = !document.getElementById(checkbox.id)
                    .checked;
                isValid = document.getElementById(checkbox.id).checked;
            } else {
                checkboxes[index].error = false;
            }
        });

        this.setState({ checkboxes: checkboxes });
        return isValid;
    };

    prepareDataToSend = () => {
        const inputs = this.state.inputs;
        const checkboxes = this.state.checkboxes;
        let requestJson = {
            src_r: this.state.src_r,
            recaptchaToken: this.state.recaptchaToken || null,
            frstNm: "frstNm", // First name
            lastNm: "lastNm", // Last name
            emailAddrTxt: "emailAddrTxt", // Email
            mobilePhoneNr: "mobilePhoneNr", // Mobile phone number
            rcrtrId: this.state.rcrtrId, // uplineEmail
            apptSrceCd: this.state.appSrcCode, // Application source code
            utmTerm: this.state.utm_term,
            addresses: [
                {
                    addrLocTyp: "HOME",
                    postCd: "postCd", // Post Code
                    city: "city", // City
                    strAddr1Txt: "strAddr1Txt", // Street
                    strAddr2Txt: "strAddr2Txt", // Apartment Number
                    strAddr3Txt: "", // Apartment Number
                },
            ],
            agrmnts: [
                {
                    agrmntTyp: "agrmntInd",
                    agrmntVerNr: "1",
                    agrmntAcptdInd: false,
                },
                {
                    agrmntTyp: "prsnlDataStorgInd",
                    agrmntVerNr: "1",
                    agrmntAcptdInd: false,
                },
                {
                    agrmntTyp: "prvcyAgrmntInd",
                    agrmntVerNr: "1",
                    agrmntAcptdInd: false,
                },
            ],
            cmnctn: [
                {
                    comnctnTyp: "ALWD",
                    email: false,
                    phone: false,
                    sms: false,
                },
            ],
        };

        if (this.state.refRepId && this.state.refRepId !== this.state.rcrtrId) {
            requestJson.rfrlId = this.state.refRepId;
        }

        // eslint-disable-next-line array-callback-return
        inputs.map((input) => {
            if (input.id === "firstName") {
                requestJson.frstNm = input.value;
            }

            if (input.id === "BirthDate") {
                requestJson.birthDate = input.value;
            }

            if (input.id === "lastName") {
                requestJson.lastNm = input.value;
            }

            if (input.id === "Email") {
                requestJson.emailAddrTxt = input.value;
            }

            if (input.id === "Phone") {
                requestJson.mobilePhoneNr =
                    "48" + input.value.replace(/ /g, "");
            }

            if (input.id === "Street") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].strAddr1Txt = input.value;
                });
            }

            if (input.id === "StreetNr") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].strAddr2Txt = input.value;
                });
            }

            if (input.id === "ApartmentNr") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].strAddr3Txt = input.value;
                });
            }

            if (input.id === "PostCode") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].postCd = input.value;
                });
            }

            if (input.id === "City") {
                requestJson.addresses.map((address, i) => {
                    requestJson.addresses[i].city = input.value;
                });
            }
        });

        // eslint-disable-next-line array-callback-return
        checkboxes.map((checkbox) => {
            if (checkbox.id === "Agreement1") {
                requestJson.agrmnts.map((agreement, i) => {
                    requestJson.agrmnts[i].agrmntAcptdInd = checkbox.isChecked;
                });
            }

            if (checkbox.id === "Agreement2") {
                requestJson.cmnctn.map((agreement, i) => {
                    requestJson.cmnctn[i].email = checkbox.isChecked;
                    requestJson.cmnctn[i].phone = checkbox.isChecked;
                    requestJson.cmnctn[i].sms = checkbox.isChecked;
                });
            }
        });

        return requestJson;
    };

    dateToSendParser = (date) => {
        const y = date.getFullYear();
        const m = date.getMonth() + 1;
        const d = date.getDate();
        const mm = m < 10 ? "0" + m : m;
        const dd = d < 10 ? "0" + d : d;

        return `${y}-${mm}-${dd}`;
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (this.validateForm() ? (this.validateCheckboxes() && this.validateForm()) : (this.validateCheckboxes() && this.validateForm())) {
            this.setState({ isFormSent: true });
            const errorFunc = this.prepareErrorResponse;
            const successFunc = this.prepareSuccessResponse;

            try {
                switch (window.location.pathname) {
                    case "/form3":
                        // eslint-disable-next-line no-undef
                        ga("join.send", {
                            hitType: "event",
                            eventCategory: "Dołącz do Avon - button click",
                            eventAction: "Test A/B - zgoda marketingowa",
                            eventLabel: "Wersja A",
                        });
                        break;
                    case "/form2":
                        // eslint-disable-next-line no-undef
                        ga("join.send", {
                            hitType: "event",
                            eventCategory: "Dołącz do Avon - button click",
                            eventAction: "Test A/B - zgoda marketingowa",
                            eventLabel: "Wersja B",
                        });
                        break;
                    default:
                        // eslint-disable-next-line no-undef
                        ga("join.send", {
                            hitType: "event",
                            eventCategory: "dolacz-do-klubu",
                            eventAction: window.location.pathname,
                            eventLabel: "form sent",
                        });
                }
            } catch (e) {}


            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(process.env.REACT_APP_API_RE_CAPTCHA_KEY, { action: 'form_action' }).then(token => {
                    this.setState({ recaptchaToken: token });
                    axios
                    .post(path, this.prepareDataToSend(), {
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(function (response) {
                        return successFunc(response);
                    })
                    .catch(function (error) {
                        if (!error.response) {
                            return errorFunc();
                        } else {
                            return errorFunc(error.response.data.errCd);
                        }
                    });
                });
              });
  
        } else {
            console.log("form is not valid");
        }
    };

    prepareErrorResponse = (code) => {
        const inputs = this.state.inputs;

        try {
            // eslint-disable-next-line no-undef
            ga("join.send", {
                hitType: "event",
                eventCategory: "dolacz-do-klubu",
                eventAction: window.location.pathname,
                eventLabel: `error code: ${code || "unknown error"}`,
            });
        } catch (e) {}

        switch (code) {
            case "DUPLICITY_REP_EMAIL":
                inputs.map((input, i) => {
                    if (input.id === "Email") {
                        inputs[i].error = true;
                        inputs[i].errorMessage =
                            "Podany adres jest już wykorzystywany";
                    }
                });
                break;
            case "DUPLICITY_REP_MOBILE":
                inputs.map((input, i) => {
                    if (input.id === "Phone") {
                        inputs[i].error = true;
                        inputs[i].errorMessage =
                            "Podany nr telefonu jest już wykorzystywany";
                    }
                });
                break;
            case "MULTIPLE_ACCOUNTS_NOT_REAPPOINTABLE":
            case "DUPLICITY_APPOINTMENT_NOT_POSSIBLE":
            case "DUPLICITY_REINSTATABLE_REPRESENTATIVE":
                this.setState({ duplicityError: true });
                this.setState({ error: true });
                break;
            default:
                this.setState({ error: true });
                break;
        }

        this.setState({ inputs: inputs });
        this.setState({ isFormSent: false });
    };

    prepareSuccessResponse = (response) => {
        try {
            // eslint-disable-next-line no-undef
            ga("join.send", {
                hitType: "event",
                eventCategory: "dolacz-do-klubu",
                eventAction: window.location.pathname,
                eventLabel: `registered apptId: ${
                    response.data.apptId || "no apptId"
                }`,
            });
            window.dataLayer.push({
                event: "typ",
                leadNumber: response.data.id || "no lead number",
                typeForm: window.location.pathname,
                repId: response.data.apptId || "no apptId",
            });
        } catch (e) {}

        this.setState({ responseSuccess: true });
    };

    onFocusHandler = (id) => {
        const inputs = [...this.state.inputs];
        inputs.forEach((elem) => {
            if (elem.id === id) elem.focus = true;
        });

        this.setState({ inputs: inputs });
    };

    onBlurHandler = (id) => {
        const inputs = [...this.state.inputs];
        inputs.forEach((elem) => {
            if (elem.id === id) elem.focus = false;
        });

        this.setState({ inputs: inputs });
    };

    changeInputHandler = (event, id) => {
        const inputIndex = this.state.inputs.findIndex((i) => {
            return i.id === id;
        });

        const input = {
            ...this.state.inputs[inputIndex],
        };

        switch (input.id) {
            case "firstName":
            case "lastName":
                if (!validators.validateNameValue(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;

            case "Email":
                if (!validators.validateEmail(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "Street":
                if (!validators.validateStreetName(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "StreetNr":
                if (!validators.validateStreetNo(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "ApartmentNr":
                const apartmentValid = validators.validateApartmentNo(
                    event.target.value
                );

                if (!apartmentValid) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else if (apartmentValid === -1) {
                    input.error = false;
                    input.class = "";
                    input.correct = null;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "City":
                if (!validators.validateCityName(event.target.value)) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            case "PostCode":
                const notValidPostCode = !validators.validatePostCode(
                    event.target.value
                );
                let eValue = event.target.value;
                if (eValue.length > 2 && eValue.indexOf("-") === -1) {
                    String.prototype.splice = function (idx, rem, str) {
                        return (
                            this.slice(0, idx) +
                            str +
                            this.slice(idx + Math.abs(rem))
                        );
                    };
                    input.value = eValue.splice(2, 0, "-");
                } else if (eValue.length <= 6 && notValidPostCode) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                    input.value = event.target.value;
                } else if (eValue.length > 6) {
                    return;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                    if (!notValidPostCode) {
                        input.value = event.target.value;
                    }
                }
                break;

            case "Phone":
                const notValid = !validators.validatePhone(event.target.value);
                if (notValid && event.target.value.length <= 9) {
                    input.value = event.target.value;
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else {
                    if (!notValid) {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                    input.value = event.target.value;
                    }
                }
                break;
            case "Referral":
                const isRefValid = validators.validateAcc(event.target.value);
                if (!isRefValid) {
                    input.error = true;
                    input.class = "errorBg";
                    input.correct = false;
                } else if (isRefValid === -1) {
                    input.error = false;
                    input.class = "";
                    input.correct = null;
                } else {
                    input.error = false;
                    input.class = "correct";
                    input.correct = true;
                }
                input.value = event.target.value;
                break;
            // const notValidAcc = !validators.validateAcc(event.target.value);
            // if (notValidAcc && event.target.value.length <= 9) {
            //     input.value = event.target.value;
            //     input.error = true;
            //     input.class = "errorBg";
            //     input.correct = false;
            // } else {
            //     input.error = false;
            //     input.class = "correct";
            //     input.correct = true;
            //     if (!notValidAcc) {
            //         input.value = event.target.value;
            //     }
            // }
            // break;
            default:
                input.value = event.target.value;
                break;
        }

        const inputs = [...this.state.inputs];
        inputs[inputIndex] = input;

        this.setState({ inputs: inputs });
    };

    changeCheckboxHandler = (event, id) => {
        const checkboxIndex = this.state.checkboxes.findIndex((i) => {
            return i.id === id;
        });

        const checkbox = {
            ...this.state.checkboxes[checkboxIndex],
        };

        if (checkbox.isChecked) {
            checkbox.isChecked = false;
            if (checkbox.id === "Agreement1") {
                checkbox.error = true;
            }
        } else {
            checkbox.isChecked = true;
            checkbox.error = false;
        }
        const checkboxes = [...this.state.checkboxes];
        checkboxes[checkboxIndex] = checkbox;

        this.setState({ checkboxes: checkboxes });
    };

    dateHandler = (date, id) => {
        const inputIndex = this.state.inputs.findIndex((i) => {
            return i.id === id;
        });

        const input = {
            ...this.state.inputs[inputIndex],
        };

        input.startDate = date;
        input.value = date;

        if (
            (input.value && new Date(input.value) > this.maxDate()) ||
            input.value === "Invalid date"
        ) {
            input.error = true;
            input.class = "errorBg";
            input.correct = false;
        } else if (input.value != null) {
            input.error = false;
            input.class = "correct";
            input.correct = true;
        }

        input.startDate = date;
        input.value = date;

        const inputs = [...this.state.inputs];
        inputs[inputIndex] = input;

        this.setState({ inputs: inputs });
    };

    verifyCallback = (recaptchaToken) => {
        this.setState({ recaptchaToken: recaptchaToken });
    };

    handleFormArrowClick = (e) => {
        scrollToElement(e, 'scrollToSlider');
        // scrollToElement(e, 'scrollToWorkOnlineSlider');
    };

    render() {
        let inputs = this.state.inputs.map(
            ({
                id,
                label,
                type,
                name,
                value,
                error,
                correct,
                errorMessage,
                icon,
                focus,
                width,
            }) => {
                const handler =
                    id !== "BirthDate"
                        ? (event) => this.changeInputHandler(event, id)
                        : (date) => this.dateHandler(date, id);
                if (
                    id !== "Referral" ||
                    (id === "Referral" && this.state.appSrcCode === "45")
                ) {
                    if (id === "Referral" && this.state.refRepId) {
                        value = this.state.refRepId || "";
                        correct = true;
                    }

                    return (
                        <React.Fragment key={name}>
                            <InputBox
                                key={id}
                                label={label}
                                val={value}
                                err={error}
                                errMsg={errorMessage}
                                correct={correct}
                                focus={focus}
                                width={width}
                            >
                                {icon && (
                                    <IconBox>
                                        <i className={icon} />
                                    </IconBox>
                                )}

                                <InputEl
                                    type={type}
                                    name={name}
                                    id={id}
                                    value={value}
                                    onKeyDown={type=="number" ? blockInvalidChar : null}
                                    onChange={handler}
                                    err={error}
                                    focus={focus}
                                    onFocus={() => this.onFocusHandler(id)}
                                    onBlur={() => this.onBlurHandler(id)}
                                    disabled={
                                        id === "Referral" && this.state.refRepId
                                            ? true
                                            : false
                                    }
                                />
{/* 
                                {!focus && error && (
                                    <ErrorIcon>
                                        <i className={"fa fa-exclamation-circle"} />
                                    </ErrorIcon>
                                )}
                                {!focus && correct && (
                                    <CorrectIcon>
                                        <i className={"fa fa-check"} />
                                    </CorrectIcon>
                                )} */}
                            </InputBox>
                            {name === 'email' && (
                                <div className={styles.formHeaderWrapper}>
                                    <h2 className={styles.formHeader}>DANE ADRESOWE</h2>
                                    <Tooltip
                                        placement="bottomLeft"
                                        trigger={['click']}
                                        showArrow={false}
                                        overlay={<span>Podanie Twojego adresu umożliwi nam wysłanie paczki powitalnej i katalogów z kosmetykami Avon.</span>}
                                    >
                                        <a className={styles.formTooltip}>
                                            Dlaczego potrzebujemy?
                                        </a>
                                    </Tooltip>
                                </div>
                            )}
                        </React.Fragment>
                    );
                } else {
                    return null;
                }
            }
        );

        return (
            <FormContainer>
                <ReCaptcha
                    sitekey={process.env.REACT_APP_API_RE_CAPTCHA_KEY}
                    action="form_action"
                    verifyCallback={this.verifyCallback}
                />
                {this.state.responseSuccess ? (
                    <ThankYou />
                ) : (
                    <>
                        {this.state.error ? (
                            this.state.duplicityError ? null : (
                                <div className="error-resp">
                                    Wystąpił błąd podczas przesyłania danych.
                                    <br />
                                    Odczekaj chwilę i spróbuj ponownie.
                                </div>
                            )
                        ) : (
                            <Header>
                                <span style={{fontWeight: '900',marginBottom: "8px",display:"inline-block"}}>WYPEŁNIJ FORMULARZ</span> <br/>
                                {!this.state.isGoogleLoggedIn ? (
                                    <div className="mb-1">
                                                       Możesz skorzystać z autouzupełniania danych z                      
                                                       <GoogleLoginButton 
                                           onLoginSuccess={this.handleLoginSuccess} 
                                           onLoginFailure={this.handleLoginFailure} 
                                       />
                                       </div>) : (
                                        <div>
                                     <div className={`${styles.googleLogout} mb-1`} onClick={this.handleLogout}><strong>Wyloguj się z Google</strong></div>
                                        </div>
                                    )}
                                 W ciągu 5 minut otrzymasz e-mail i SMS z jednorazowym hasłem do logowania. Od tego momentu możesz składać zamówienia poprzez stronę <strong><a href="https://www2.pl.avon.com/">WWW KLUBU AVON</a></strong>.
                            </Header>
                        )}
                        {this.state.duplicityError ? (
                            <DuplicityError />
                        ) : (
                            <FormEl
                                name="consultantForm"
                                onSubmit={this.submitHandler}
                            >
                                <div className={styles.formHeaderWrapper}>
                                    <h2 className={styles.formHeader}>DANE OSOBOWE</h2>
                                    <Tooltip
                                        placement="bottomLeft"
                                        trigger={['click']}
                                        showArrow={false}
                                        overlay={<span>Podanie telefonu umożliwi kontakt ze strony Opiekuna, który wprowadzi Cię do wszystkich korzyści jakie na Ciebie czekają.</span>}
                                    >
                                        <a className={styles.formTooltip}>
                                            Dlaczego potrzebujemy?
                                        </a>
                                    </Tooltip>
                                    <div className={styles.inputsWrapper}>
                                        {inputs}
                                    </div>
                                </div>

                                <Agreement1
                                    input={this.state.checkboxes[0]}
                                    changed={(event) =>
                                        this.changeCheckboxHandler(
                                            event,
                                            this.state.checkboxes[0].id
                                        )
                                    }
                                />
                                <Agreement2
                                    shortAgr={this.props.shortAgr}
                                    input={this.state.checkboxes[1]}
                                    changed={(event) =>
                                        this.changeCheckboxHandler(
                                            event,
                                            this.state.checkboxes[1].id
                                        )
                                    }
                                />
                                <div id="toScroll" className={styles.submitButtonWrapper}>
                                    {this.state.isFormSent ? (
                                        <span className={styles.submitInfo}>Zaczekaj, przetwarzamy Twoje zgłoszenie</span>
                                    ) : (
                                        <BigButton type="submit" fullWidth>
                                            DOŁĄCZ DO KLUBU AVON
                                        </BigButton>
                                    )}
                                    <ArrowButton noTopMargin customClickEvent={this.handleFormArrowClick} />
                                </div>
                            </FormEl>
                        )}
                    </>
                )}
            </FormContainer>
        );
    }
}

export default FormComponent;
