import React from 'react';
import styles from './SmallButtonAndHeader.module.scss';
import rightArrow from '../../../assets/images/aprilForm/arrow-right.svg';

const SmallButtonAndHeader = (props) => {
  const {
    children, withArrow, isButton, customClickEvent,
  } = props;
  return (
    <>
      {isButton ? (
        <button onClick={customClickEvent} className={`${styles.elementContainer} ${styles.elementContainer__button}`} type="button">
          {children}
          {withArrow && (
            <img className={styles.buttonArrow} src={rightArrow} alt="Right arrow" />
          )}
        </button>
      ) : (
        <h2 className={styles.elementContainer}>
          {children}
        </h2>
      )}
    </>
  );
};

export default SmallButtonAndHeader;
