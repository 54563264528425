import styled, { keyframes } from "styled-components";
import bgSmall from "../../assets/images/form3/bg2880.png";
import bgBig from "../../assets/images/form3/bg2880.png";
import bgTab from "../../assets/images/form3/bg_tab.png";
import bgMob from "../../assets/images/form3/bg_mob.png";

const fadeIn = keyframes`
  0% { opacity: 0;}
  100% { opacity: 1;}
`;

export const FormSection = styled.section`
    background-image: url(${bgBig});
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 20px 40px 40px 40px;
    font-family: "Montserrat";

    @media screen and (min-width: 1900px) { 
        align-items: center;
    }

    @media screen and (max-width: 1440px) {
        background-image: url(${bgSmall});
    }

    @media screen and (max-width: 1100px) {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    @media screen and (max-width: 786px) {
        background-image: url(${bgTab});
        background-size: cover;
    }

    @media screen and (max-width: 420px) {
        padding: 0;
    }

    @media screen and (max-width: 390px) {
        background-image: url(${bgMob});
        background-size: cover;
        margin-top: -8px;
    }
`;

export const ContentBox = styled.div`
    width: 850px;
    height: 100%;
    min-height: 750px;
    position: relative;
    opacity: 1;

    animation: ${fadeIn} 1.4s linear;

    @media screen and (min-width: 1920px) {
        max-height: 850px;
    }

    @media screen and (max-width: 1100px) {
        order: 2;
        width: 100%;
        min-height: unset;
    }
`;

export const FormBox = styled.div`
    width: 550px;
    height: 675px;
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 20px 40px #00000024;
    border-radius: 8px;
    opacity: 1;

    animation: ${fadeIn} 1s linear;

    @media screen and (min-width: 1900px) {
        max-height: 700px;
        height: 700px;
        max-width: 600px;
        width: 100%;
    }

    @media screen and (max-width: 1100px) {
        order: 1;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        height: auto;
        min-height: 300px;
    }

    @media screen and (max-width: 420px) {
        width: calc(100% - 28px);
    }
`;

export const Banner = styled.div`
    display: none;
    @media screen and (max-width: 768px) {
        background: rgb(106, 42, 193);
        background: linear-gradient(
            130deg,
            rgba(106, 42, 193, 0.17) 0%,
            rgba(215, 80, 115, 1) 100%
        );
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-size: 20px;
        font-family: Montserrat, sans-serif;
        color: white;
        font-weight: bold;

        width: 100%;
        padding: 20px 10px;

        border-bottom: 10px solid white;
        margin-bottom: 10px;
    }
`;

export const Frame = styled.iframe`
    height: 100%;
    width: 100%;

    @media screen and (max-width: 600px) {
        height: 853px;
    }
`;
