import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import googlePlayLogo from "../../../assets/images/aprilForm/googlePlay-pobierz.svg";
import arrowUp from "../../../assets/images/aprilForm/arrow-up.svg";
import appStoreLogo from "../../../assets/images/aprilForm/appStore-pobierz.svg";
import appGalleryLogo from "../../../assets/images/aprilForm/appGallery-pobierz.svg";
import BigButton from "../BigButton/BigButton";
import ArrowButton from "../ArrowButton/ArrowButton";
import { scrollToElement } from "../../../utils";
import {
    handleScrollToFormClick,
} from "../utils";

import styles from "./WorkOnlineSection.module.scss";
import HorizontalScroll2 from "../HorizontalScroll/HorizontalScroll2";


const HowToBeConsultantSection = () => {

    const containerRef5 = useRef();
    const [isScrollEnabled, setIsScrollEnabled] = useState(false);
    const [switchRef, setSwitchRef] = useState(false);

    const handleShowCatalogClick = () => {
        window.location.href = "https://pl.avon-brochure.com/c04_pl_2023/";
    };
    const handleArrowDownClick = (e) => {
        scrollToElement(e, "joySection");
        // scrollToElement(e, "scrollVideoSection");
    };
    const contentBlocks = [
        {
            topSegment: "AVON ON",
            bottomSegment: "TWÓJ OSOBISTY ASYSTENT",
            textSection: [
                {
                    text: `Wyjątkowa aplikacja, do której otrzymasz
                    dostęp i którą szybko pokochasz.`,
                },
                {
                    text: `Twoje własne centrum zarządzania, w
                    którym masz wszystko zawsze pod ręką -
                    od dostępu do zawsze aktualnego katalogu
                    cyfrowego, po możliwość złożenia
                    zamówienia i opłacenia faktury.`,
                },
            ],
            html: 
                <div className={styles.storeLogos}>
                    <a
                        className={styles.storeLogos__links}
                        href="https://play.google.com/store/apps/details?id=com.avon.avonon&hl=pl&gl=US"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={googlePlayLogo} alt="Google Play Logo" />
                    </a>
                    <a
                        className={styles.storeLogos__links}
                        href="https://apps.apple.com/pl/app/avon-on/id1439105374?l=pl"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={appStoreLogo} alt="App Store Logo" />
                    </a>
                    <a
                        className={styles.storeLogos__links}
                        href="https://appgallery.huawei.com/app/C104382659"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={appGalleryLogo} alt="App Gallery Logo" />
                    </a>
                </div>
            ,
        },
        {
            topSegment: "KATALOG ON-LINE",
            bottomSegment: "Z WIRTUALNYM MAKIJAŻEM",
            textSection: [
                {
                    text: `Dzięki katalogowi online Twoja praca
                    będzie jeszcze łatwiejsza. Szeruj i
                    zarabiaj nie wychodząc z domu!`,
                },
                {
                    text: `Udostępniając klientom spersonalizowany
                    link, dajesz im wygodny dostęp do pełnej
                    oferty i szybkiej wysyłki.`,
                },
            ],
            html: (<a
                className={styles.customLink}
                href="https://www.avon.pl/katalog-online"
            >
                <span>ZOBACZ iKATALOG</span>
                <img src={arrowUp} alt="arrow up" />
            </a>)
        },
        {
            topSegment: "ELASTYCZNE",
            bottomSegment: "PŁATNOŚCI",
            textSection: [
                {
                    text: `Pracując zdalnie, to Ty decydujesz, czy
                    i w jaki sposób kontaktujesz się ze
                    swoimi klientami oraz kiedy pracujesz.`,
                },
                {
                    text: `Możesz zrobić sobie wolne albo pracować
                    wieczorami - to Ty jesteś szefem!`,
                },
            ],
        },
        {
            topSegment: "TWOJA SZANSA",
            bottomSegment: "NA ROZWÓJ",
            textSection: [
                {
                    text: `Zostań konsultantką, zyskaj wiernych
                    klientów, zdobądź wiedzę i
                    doświadczenie, a następnie sięgaj dalej!
                    W Avon mamy wiele szkoleń, dzięki którym
                    poczujesz się pewniej jako Konsultantka
                    i zarobisz jeszcze więcej pieniędzy.`,
                },
                {
                    text: `Pamiętaj, że możesz liczyć na wsparcie
                    na każdym etapie!`,
                },
            ],
        },
    ];

    const handleResize = () => {
        if (window.innerWidth < 768) {
            setSwitchRef(true);
        }
        if (window.innerWidth > 300) {
            setIsScrollEnabled(true);
        }
    };

    useEffect(() => {
        window.addEventListener("DOMContentLoaded", handleResize);

        return () => {
            window.removeEventListener("DOMContentLoaded", handleResize);
        };
    }, []);

    return (
        <>
            <div ref={switchRef ? null : containerRef5} className={styles.section}>
                <div className={styles.online_section}>
                    <div className={styles.online_section__content}>
                        <div className={styles.mainHeader}>
                            <span className={styles.mainHeader__topSegment}>
                                PRACA{" "}
                            </span>
                            <span>ONLINE</span>
                        </div>
                        <div className={styles.textWrapper}>
                            Prosta, przyjemna i efektywna praca jest teraz
                            możliwa dzięki nowoczesnym narzędziom, które
                            otrzymujesz do swojej dyspozycji.
                            <br />
                            <br />
                            Zostań konsultantką Avon, sprzedawaj, zarabiaj,
                            zgarniaj profity <br /> i kupuj produkty dla siebiei
                            swoich bliskich w najlepszych cenach, których nie
                            znajdziesz nigdzie indziej!
                        </div>
                        <div className={styles.contentContainer__buttons}>
                            <BigButton
                                customClickEvent={handleScrollToFormClick}
                                fullWidth
                            >
                                CHCĘ ZOSTAĆ KONSULTANTKĄ
                            </BigButton>
                            <ArrowButton
                                customClickEvent={handleArrowDownClick}
                            />
                        </div>
                    </div>
                </div>
                <div ref={switchRef ? containerRef5 : null} className={styles.scrollWrapper}>
                <HorizontalScroll2
                    ref2={containerRef5}
                    blocks={contentBlocks}
                    enableScroll={isScrollEnabled}
                    xPercentConfig={
                        window.innerWidth > 1200
                            ? -38
                            : window.innerWidth >= 768
                            ? -75
                            : -108
                    }
                />
                </div>
            </div>
        </>
    );
};

export default HowToBeConsultantSection;
