import React from 'react';
import { scrollToElement } from '../../utils';
import styles from './utils.module.scss';

export const handleScrollToFormClick = (e) => {
  scrollToElement(e, 'scrollToFormTop');
};

export const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={`${styles.arrow} ${styles.arrow__next}`}
      onClick={onClick}
    />
  );
};

export const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={`${styles.arrow} ${styles.arrow__prev}`}
      onClick={onClick}
    />
  );
};
