/* eslint-disable import/prefer-default-export */
export const state = {
    recaptchaToken: "",
    error: false,
    duplicityError: false,
    responseSuccess: false,
    uplineEmail: "",
    inputs: [
        {
            name: "firstName",
            value: "",
            id: "firstName",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawne imię",
            correct: null,
            required: true,
            label: "Imię*",
            focus: false,
            // width: 'regular',
            width: "short1",
        },
        {
            name: "lastName",
            value: "",
            id: "lastName",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawne nazwisko",
            correct: null,
            required: true,
            label: "Nazwisko*",
            focus: false,
            // width: "wide",
            width: "wide1",
        },
        {
            name: "phone",
            value: "",
            id: "Phone",
            type: "number",
            error: false,
            errorMessage: "Nieprawidłowy numer telefonu.",
            correct: null,
            required: true,
            label: "Numer kontaktowy*",
            focus: false,
            // width: "regular",
            width: "short1",
        },
        {
            name: "email",
            value: "",
            id: "Email",
            type: "email",
            error: false,
            errorMessage: "Wpisz poprawny adres e-mail.",
            correct: null,
            required: true,
            label: "E-mail*",
            focus: false,
            // width: "wide",
            width: "wide1",
        },
        {
            name: "street",
            value: "",
            id: "Street",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawną nazwę ulicy.",
            correct: null,
            required: true,
            label: "Ulica*",
            focus: false,
            // width: "wide",
            width: "wide-solo",
        },
        {
            name: "streetNr",
            value: "",
            id: "StreetNr",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawny numer domu.",
            correct: null,
            required: true,
            label: "Nr domu*",
            focus: false,
            // width: "short",
            width: "short2",
        },
        {
            name: "apartmentNr",
            value: "",
            id: "ApartmentNr",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawny numer mieszkania.",
            correct: null,
            required: false,
            label: "Nr mieszkania",
            focus: false,
            // width: "short",
            width: "short2",
        },
        {
            name: "postCode",
            value: "",
            id: "PostCode",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawny kod.",
            correct: null,
            required: true,
            label: "Kod pocztowy*",
            focus: false,
            width: "short",
            width: "short2",
        },
        {
            name: "City",
            value: "",
            id: "City",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawną nazwę miejscowości.",
            correct: null,
            required: true,
            label: "Miejscowość*",
            focus: false,
            width: "short",
            width: "short2",
        },
        {
            name: "referral",
            value: "",
            id: "Referral",
            type: "number",
            error: false,
            errorMessage: "Poprawna długość to 8 znaków.",
            correct: null,
            required: false,
            label: "Osoba polecająca",
            focus: false,
            width: "regular",
        },
    ],
    checkboxes: [
        {
            name: "Agreement1",
            id: "Agreement1",
            type: "checkbox",
            error: false,
            errorMessage: "Zgoda jest wymagana",
            required: true,
            isChecked: false,
        },
        {
            name: "Agreement2",
            id: "Agreement2",
            type: "checkbox",
            error: false,
            errorMessage: "Zgoda jest wymagana",
            required: false,
            isChecked: false,
        },
    ],
    isFormSent: false,
};
