import React, { useState } from "react";
import Form3 from "../Form3/Form3";
import BeRepComponent from "../Be-rep-component/Be-rep.component";
import Faq from "../FAQ/faq.component";
import { scrollToElement, iframePostMessage } from "../../utils";

const BeRep = () => {
    const [showFAQ, setShowFAQ] = useState(false);

    const clickHandler = (e) => {
        if (!showFAQ) {
            scrollToElement(e, "faq");
        }
        setShowFAQ(!showFAQ);

        iframePostMessage();
    };

    return (
        <>
            <BeRepComponent />
            <br />
            <br />
            <section id="apply">
                <Form3
                    button={true}
                    clicked={clickHandler}
                    buttonText="WARTO WIEDZIEĆ"
                />
            </section>
            <Faq showButton={false} show={showFAQ} />
        </>
    );
};

export default BeRep;
