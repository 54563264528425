import React from 'react';
import SliderSection from './SliderSection/SliderSection';
import FormComponent from './FormComponent/FormComponent';
import styles from './AprilForm.module.scss';
import TopSection from './TopSection/TopSection';
import HowToBeConsultantSection from './HowToBeConsultantSection/HowToBeConsultantSection';
import WorkOnlineSection from './WorkOnlineSection/WorkOnlineSection';
import AvonConsultantSection from './AvonConsultantSection/AvonConsultantSection';
import VideoSection from './VideoSection/VideoSection';
import Logo from './Logo/Logo';
import HorizontalScroll from './HorizontalScroll/HorizontalScroll';

const AprilForm = () => {

  return (
    <div className={styles.lpContainer} id="scrollToTop">
    <Logo />
      <div className={styles.joinSection}>
        <HowToBeConsultantSection />
        <div id="scrollToFormTop" className={styles.formContainer}>
          <FormComponent />
        </div>
      </div>
      <div id="scrollToSlider" />
      <TopSection />
      {/* <SliderSection /> */}
    
      <div id="scrollToWorkOnlineSlider" />
      <WorkOnlineSection />
      <AvonConsultantSection />
      <div id="scrollVideoSection" />
      <VideoSection />
    </div>
  );
};

export default AprilForm;
