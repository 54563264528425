/* eslint-disable react/button-has-type */
import React from 'react';
import styles from './BigButton.module.scss';
import rightUpArrowWhite from '../../../assets/images/aprilForm/arrow-right-up-white.svg';

const BigButton = (props) => {
  const {
    children, type, customClickEvent, withArrow, fullWidth,
  } = props;
  return (
    <button
      onClick={customClickEvent}
      className={`${styles.buttonContainer} ${fullWidth && styles.buttonContainer__fullWidth}`}
      type={type || 'button'}
    >
      {children}
      {withArrow && (
      <img className={styles.buttonArrow} src={rightUpArrowWhite} alt="Right arrow" />
      )}
    </button>
  );
};

export default BigButton;
