import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import googlePlayLogo from '../../../assets/images/aprilForm/googlePlay-pobierz.svg';
import appStoreLogo from '../../../assets/images/aprilForm/appStore-pobierz.svg';
import appGalleryLogo from '../../../assets/images/aprilForm/appGallery-pobierz.svg';
import iconMakeup from '../../../assets/images/aprilForm/icon-makeup.svg';
import BigButton from '../BigButton/BigButton';
import { SampleNextArrow, SamplePrevArrow, handleScrollToFormClick } from '../utils';

import styles from './WorkOnlineSection.module.scss';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 4000,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const HowToBeConsultantSection = () => {
  const handleShowCatalogClick = () => {
    window.location.href = 'https://pl.avon-brochure.com/c04_pl_2023/';
  };

  return (
    <>
      <div className={`${styles.mainHeader} ${styles.mainHeader__standalone} ${styles.mainHeader__desktop}`}>
        <span className={styles.mainHeader__topSegment}>PRACA </span>
        <span>ONLINE</span>
      </div>
      <div className={styles.section}>
        <Slider className={styles.sliderWrapper} {...settings}>
          <div className={styles.slideElement}>
            <div className={styles.slideElement__content}>
              <div className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}>
                <div className={styles.mainHeader__topSegment}>AVON ON</div>
                <div>TWÓJ OSOBISTY</div>
                <div>ASYSTENT</div>
              </div>
              <div>
                <div className={styles.slideElement__textSection}>
                  <img src={iconMakeup} alt="Icon Makeup" />
                  <div>
                    Wyjątkowa aplikacja, do której otrzymasz dostęp i którą szybko pokochasz.
                  </div>
                </div>
                <div className={styles.slideElement__textSection}>
                  <img src={iconMakeup} alt="Icon Makeup" />
                  <div>
                    Twoje własne centrum zarządzania, w którym masz wszystko zawsze pod ręką -
                    od dostępu do zawsze aktualnego katalogu cyfrowego, po możliwość złożenia
                    zamówienia i opłacenia faktury.
                  </div>
                </div>
              </div>
              <div className={styles.storeLogos}>
                <a className={styles.storeLogos__links} href="https://play.google.com/store/apps/details?id=com.avon.avonon&hl=pl&gl=US" target="_blank" rel="noreferrer">
                  <img src={googlePlayLogo} alt="Google Play Logo" />
                </a>
                <a className={styles.storeLogos__links} href="https://apps.apple.com/pl/app/avon-on/id1439105374?l=pl" target="_blank" rel="noreferrer">
                  <img src={appStoreLogo} alt="App Store Logo" />
                </a>
                <a className={styles.storeLogos__links} href="https://appgallery.huawei.com/app/C104382659" target="_blank" rel="noreferrer">
                  <img src={appGalleryLogo} alt="App Gallery Logo" />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.slideElement}>
            <div className={styles.slideElement__content}>
              <div className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}>
                <div className={styles.mainHeader__topSegment}>SPERSONALIZOWANY</div>
                <div>KATALOG</div>
                <div>ONLINE</div>
              </div>
              <div>
                <div className={styles.slideElement__textSection}>
                  <img src={iconMakeup} alt="Icon Makeup" />
                  <div>
                    Dzięki katalogowi online Twoja praca będzie jeszcze łatwiejsza.
                    Szeruj i zarabiaj nie wychodząc z domu!
                  </div>
                </div>
                <div className={styles.slideElement__textSection}>
                  <img src={iconMakeup} alt="Icon Makeup" />
                  <div>
                    Udostępniając klientom spersonalizowany link,
                    dajesz im wygodny dostęp do pełnej oferty i szybkiej wysyłki.
                  </div>
                </div>
              </div>
              <BigButton customClickEvent={handleShowCatalogClick} withArrow>
                ZOBACZ KATALOG
              </BigButton>
            </div>
          </div>
          <div className={styles.slideElement}>
            <div className={styles.slideElement__content}>
              <div className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}>
                <div className={styles.mainHeader__topSegment}>ELASTYCZNA PRACA</div>
                <div>NIEOGRANICZONE</div>
                <div>MOŻLIWOŚCI</div>
              </div>
              <div>
                <div className={styles.slideElement__textSection}>
                  <img src={iconMakeup} alt="Icon Makeup" />
                  <div>
                    Pracując zdalnie, to Ty decydujesz, czy i w jaki sposób kontaktujesz
                    się ze swoimi klientami oraz kiedy pracujesz.
                  </div>
                </div>
                <div className={styles.slideElement__textSection}>
                  <img src={iconMakeup} alt="Icon Makeup" />
                  <div>
                    Możesz zrobić sobie wolne albo pracować wieczorami – to Ty jesteś szefem!
                  </div>
                </div>
              </div>
              <BigButton customClickEvent={handleScrollToFormClick}>
                DOŁĄCZ DO KLUBU AVON
              </BigButton>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default HowToBeConsultantSection;
