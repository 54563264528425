import React, { useEffect, useRef, useState } from "react";
import Logo from "../Logo/Logo";
import BigButton from "../BigButton/BigButton";
import ArrowButton from "../ArrowButton/ArrowButton";
import { handleScrollToFormClick } from "../utils";
import { scrollToElement } from "../../../utils";

import styles from "./TopSection.module.scss";
import HorizontalScroll from "../HorizontalScroll/HorizontalScroll";

const TopSection = () => {
    const [isScrollEnabled, setIsScrollEnabled] = useState(false);
    const containerRef = useRef();
    const handleFirstSectionArrowClick = (e) => {
        scrollToElement(e, "scrollToSlider");
    };

    const avonBlocks = [
        {
            img: "/static/media/icon-rabat.b2bbcbdd.svg",
            desc: `<span class="bold">Zniżka <span class="avonColor emphasize">15%</span> na start i każde kolejne zamówienie</span> - możliwość zwiększenia rabatu nawet <span class="bold avonColor">do <span class="emphasize">40%</span></span>`,
        },
        {
            img: "/static/media/icon-gift.a86f6e53.svg",
            desc: `Prezenty, bonusy, <span class="bold">wcześniejszy dostęp do nowości i możliwość testowania produktów</span>`,
        },
        // {
        //     img: "/static/media/icon-computer.d5bfe922.svg",
        //     desc: `<span class="bold">Praca na Twoich zasadach</span> - jak długo, kiedy, skąd chcesz. Możez pracować online, nie wychodząc z domu.`,
        // },
        // {
        //     img: "/static/media/icon-shopping.77a819ea.svg",
        //     desc: `<span class="bold">Biznes bez wymagań i ograniczeń</span> Liczba i wartość realizowanych zamówień zależy tylko od Ciebie i potrzeb Twoich klientów`,
        // },
        {
            img: "/static/media/icon-hands.36dd5a03.svg",
            desc: `<span class="bold">Możliwość skorzystania z profesjonalnych szkoleń.</span>`,
        },
    ];

    const handleResize = () => {
        if (window.innerWidth <= 1240) {
            console.log("run");
            setIsScrollEnabled(true);
        }
    };

    useEffect(() => {
        window.addEventListener("DOMContentLoaded", handleResize);

        return () => {
            window.removeEventListener("DOMContentLoaded", handleResize);
        };
    }, []);

    return (
        <>
            <Logo />
            <div ref={containerRef} className={styles.section}>
                <div className={styles.section__content}>
                    <div className={styles.mainHeader}>
                        <div className={styles.mainHeader__topSegment}>
                            KORZYSTAJ
                        </div>
                        <div>NA PASJI</div>
                        <div>DO PIĘKNA</div>
                    </div>
                    <div className={styles.section__buttons}>
                        <BigButton customClickEvent={handleScrollToFormClick}>
                            DOŁĄCZ DO KLUBU AVON
                        </BigButton>
                        <ArrowButton
                            customClickEvent={handleFirstSectionArrowClick}
                        />
                    </div>
                </div>
                <HorizontalScroll
                withImg={true}
                    ref2={containerRef}
                    blocks={avonBlocks}
                    enableScroll={isScrollEnabled}
                    xPercentConfig={
                        window.innerWidth > 1200
                            ? -31
                            : window.innerWidth >= 768
                            ? -60
                            : -110
                    }
                />
            </div>
        </>
    );
};

export default TopSection;
