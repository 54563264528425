import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styles from "./HorizontalScroll.module.scss";
gsap.registerPlugin(ScrollTrigger);

export default function HorizontalScroll({ ref2,withImg = false, blocks = [], enableScroll = false, xPercentConfig = window.innerWidth > 1200
    ? -31
    : window.innerWidth > 768
    ? -90
    : -105 }) {


    const component = useRef(null);
    const slider = useRef();

    useLayoutEffect(() => {
        if(!enableScroll) return;
        let ctx = gsap.context(() => {
            if (ref2.current === undefined) return;
            let panels = gsap.utils.toArray(".panel");
            gsap.to(panels, {
                xPercent: xPercentConfig *
                (panels.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: ref2.current,
                    pin: true,
                    scrub: 1,
                    //   snap: 1 / (panels.length - 1),
                    end: () => "+=" + ref2.current.offsetWidth,
                    //   markers: true
                },
            });
        }, component);
        return () => ctx.revert();
    }, [ref2.current, enableScroll]);
    return (
        <div ref={component} >
            <div ref={slider} className={styles.slideWrapper} >
                {blocks?.map((item, index) => (
                    <div key={index} className={`${styles.slideElement} panel`}>
                        {withImg ? <img className={styles.centerImg} src={item.img} /> : null}
                        <h3
                            dangerouslySetInnerHTML={{ __html: item.desc }}
                        ></h3>
                    </div>
                ))}
            </div>
        </div>
    );
}
