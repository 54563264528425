import React from "react";
import BigButton from "../BigButton/BigButton";
import ArrowButton from "../ArrowButton/ArrowButton";
import { handleScrollToFormClick } from "../utils";
import svgDesign from "../../../assets/images/aprilForm/joy.svg";
import LipsSvg from "../../../assets/images/aprilForm/lips.svg";
import { scrollToElement } from "../../../utils";

import styles from "./joySection.module.scss";

const JoySection = () => {
    const handleArrowDownClick = (e) => {
        // scrollToElement(e, "gridSection1");
        const gridSection = document.querySelector("#gridSection1");
        if (gridSection) {
            setTimeout(()=>gridSection.scrollIntoView({ behavior: 'smooth' }),300)
        }
        // scrollToElement(e, "profitSection");
    };

    return (
        <div className={styles.section} id="joySection">
            <div
                className={`${styles.mainHeader} ${styles.mainHeader__videoSection}`}
            >
                <div className={styles.desktop}>
                    <div className={styles.mainHeader__extraSegment}>
                        <div>BYCIE KONSULTANTKĄ AVON</div>

                    </div>
                    <div className={`${styles.mainHeader__bottomSegment} ${styles.mainHeader__topSegment}`}>
                        <div>NICZEGO OD CIEBIE <br /> NIE WYMAGA</div>
                        <img src={LipsSvg} alt="lips" />
                    </div>
                </div>
                <div className={styles.mobile}>
                BYCIE KONSULTANTKĄ AVON<span className={styles.mainHeader__topSegment}> NICZEGO<br/>OD CIEBIE NIE WYMAGA</span>
                        <img src={LipsSvg} alt="lips" />
                </div>


            </div>
            {/* <div className={styles.contentContainer}>
                <img className={styles.svgDesign} src={svgDesign} alt="joy" />
            </div> */}

            <div className={styles.contentContainer__buttons}>
                <BigButton customClickEvent={handleScrollToFormClick} fullWidth>
                    DOŁĄCZ DO KLUBU AVON
                </BigButton>
                <ArrowButton customClickEvent={handleArrowDownClick} />
            </div>
        </div>
    );
};

export default JoySection;
