import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import Modal from "react-modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pleasure from "../../../assets/images/aprilForm/przyjemnezpozytecznym2.jpg";
import Poznawaj from "../../../assets/images/aprilForm/poznawajnowychludzi.jpg";
import Zostan from "../../../assets/images/aprilForm/zostanliderem.jpg";
import Freedom from "../../../assets/images/aprilForm/wolnoscwkazdymmomencie2.jpg";
import styles from "./GridSection.module.scss";
import playIcon from "../../../assets/images/aprilForm/play-alone.svg";
import { ReactSVG } from "react-svg";
gsap.registerPlugin(ScrollTrigger);


Modal.setAppElement("#root");



const customStyles = {
    content: {
        width: "80vw",
        height: "50vw",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};




const GridSection = ({ ref2 }) => {

    const [refresh,setRefresh] = useState(false);
    const slider = useRef(); // utworzenie referencji dla suwaka


    const component = useRef(null);

    const handleLoad = () => {
        setRefresh(!refresh)
    }
    useEffect(()=>{
        window.addEventListener("DOMContentLoaded",handleLoad)
        return () => {
            window.removeEventListener("DOMContentLoaded",handleLoad)
        }
    },[])



    //   useEffect(() => {
    //     // Usuń klasę, gdy komponent jest odmontowywany
    //     return () => {
    //       document.body.classList.add("body--fixed");
    //     };
    //   }, []);


    const [modalIsOpen, setIsOpen] = useState({
        isOpen: false,
        video: "",
    });

    const openModal = (video) => {
        setIsOpen((prevState) => ({ ...prevState, isOpen: true, video }));
    };

    const closeModal = () => {
        setIsOpen((prevState) => ({ ...prevState, isOpen: false, video: "" }));
    };

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            if (ref2.current === undefined) return;
            let panels = gsap.utils.toArray(".panel");
            gsap.to(panels, {
                xPercent:
                    (window.innerWidth > 1240
                        ? -52
                        : -100) *
                    (panels.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: ref2.current,
                    pin: true,
                    scrub: 1,
                    //   snap: 1 / (panels.length - 1),
                    end: () => "+=" + ref2.current.offsetWidth,
                    //   markers: true
                },
            });
        }, component);
        return () => ctx.revert();
    }, [ref2.current, window.innerWidth,refresh]);
    return (
        <div ref={component} id="gridSection1">
             <div ref={slider} className={styles.slideWrapper}>
             
                    <div className={`${styles.slideElement} panel`}>
                        <div className={styles.slideContent}>
                            <div
                                className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}
                            >
                                <div className={styles.mainHeader__topSegment}>
                                    ŁĄCZ PRZYJEMNE
                                </div>
                                <div>Z POŻYTECZNYM</div>
                            </div>
                            <div className={styles.textWrapper}>
                                To ważne, by praca jako Konsultantka Avon
                                przynosiła radość i satysfakcję. Rób to, co
                                kochasz, na własnych zasadach - jak chcesz, ile
                                chcesz i kiedy chcesz!
                            </div>
                        </div>
                        <img src={Pleasure} alt="przyjemne z pożytecznym" />
                    </div>

                    <div className={`${styles.slideElement} panel`}>
                        <img src={Freedom} alt="przyjemne z pożytecznym" />
                        <div className={styles.slideContent}>
                            <div
                                className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}
                            >
                                <div className={styles.mainHeader__topSegment}>
                                    CIESZ SIĘ WOLNOŚCIĄ
                                </div>
                                <div>W KAŻDYM MOMENCIE</div>
                            </div>
                            <div className={styles.textWrapper}>
                                Praca w Avon to praca na Twoich zasadach. To Ty
                                decydujesz, kiedy pracujesz, jak długo to robisz
                                i gdzie to robisz. Zarządzaj swoim czasem i
                                przeznaczaj go na to, co jest dla Ciebie
                                naprawdę ważne.
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.slideElement} panel`}>
                        <div className={styles.slideContent}>
                            <div
                                className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}
                            >
                                <div className={styles.mainHeader__topSegment}>
                                    POZNAWAJ
                                </div>
                                <div>NOWYCH LUDZI</div>
                            </div>
                            <div className={styles.textWrapper}>
                                Konsekwentnie buduj swoją bazę klientów.
                                Zawieraj nowe znajomości nie wychodząc z domu,
                                które przerodzą się nie tylko w stałe kontakty
                                biznesowe, ale w trwałe przyjaźnie!
                            </div>
                        </div>
                        <img src={Poznawaj} alt="przyjemne z pożytecznym" />
                        {/* <div className={styles.videoWrapper}>
                            <img className={styles.videoBackground} src={Poznawaj} alt="przyjemne z pożytecznym" />
                            <div
                                className={styles.playButtonWrapper}
                                onClick={() => openModal("patrycja")}
                            >
                                <ReactSVG
                                    className={
                                        styles["playButtonWrapper--icon"]
                                    }
                                    src={playIcon}
                                />
                            </div>
                        </div> */}
                    </div>
                    
                    <div className={`${styles.slideElement} panel`}>
                        <img src={Zostan} alt="przyjemne z pożytecznym" />
                        <div className={styles.slideContent}>
                            <div
                                className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}
                            >
                                <div className={styles.mainHeader__topSegment}>
                                    SIĘGNIJ PO WIĘCEJ
                                </div>
                                <div>ZOSTAŃ LIDEREM</div>
                            </div>
                            <div className={styles.textWrapper}>
                                Śmiało zdobywaj wiedzę i doświadczenie, bierz
                                udział w szkoleniach, korzystaj z pomocy
                                praktycznych poradników i… awansuj! Zbuduj swój
                                zespół Konsultantek i ciesz się jeszcze większym
                                zyskiem i korzyściami z pracy w Avon.
                                <br />
                                <br />
                                Zbuduj swój zespół Konsultantek i ciesz się
                                jeszcze większym zyskiem i korzyściami z pracy w
                                Avon.
                            </div>
                        </div>
                    </div>

             

                 
             
            </div>
            <Modal
                isOpen={modalIsOpen.isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {modalIsOpen.video === "patrycja" && (
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/QcbQILzu9NA"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    />
                )}
            </Modal>
        </div>
    );
};

export default GridSection;
