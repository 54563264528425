import React, { Component } from "react";
import "./css/bootstrap-customized/bootstrap-grid.css";
import "./css/main.css";
import a from "./img/A.png";
import grid_1__05 from "./img/grid_1__05.jpg";
import grid_1__02 from "./img/grid_1__02.jpg";
import grid_1__03 from "./img/grid_1__03.jpg";
import grid_1__07 from "./img/grid_1__07.jpg";

class WMN extends Component {
    render() {
        return (
            <>
                <div id="pcontent" className="content">
                    <section>
                        <div className="container">
                            <div className="container__inner py-3 pt-md-6 pb-md-4">
                                <div className="pl-md-4 pr-md-8">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-12 col-md-8 order-md-1 fromLeft">
                                            <h2 className="h2 mb-2 mb-md-0">
                                                <img src={a} alt="" />
                                                <span>Wspieramy kobiety</span>
                                            </h2>
                                        </div>
                                        <div className="col-12 pt-md-4 pb-3 pb-md-6 order-md-3 fromBottom">
                                            <p>
                                                Od 135 lat zmieniamy świat na
                                                lepsze, dając kobietom szansę na
                                                finansową niezależność,dzięki
                                                której mogą tworzyć lepszą
                                                przyszłość dla
                                                <span className="txt_more d-md-none">
                                                    ...
                                                    <strong>
                                                        czytaj dalej
                                                    </strong>
                                                </span>
                                                <span className="d-none d-md-inline">
                                                    dla siebie i swoich
                                                    najbliższych. Zabieramy głos
                                                    w kwestiach, które mają
                                                    znaczenie dla naszych
                                                    społeczności i wspieramy
                                                    organizacje, które w centrum
                                                    swojej działalności stawiają
                                                    dobro kobiet. Siłę, która
                                                    tkwi w pięknie,
                                                    wykorzystujemy do tego, by
                                                    zmieniać życie kobiet na
                                                    lepsze.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="boxes row no-gutters border">
                                    <div className="col-12 col-md-3 h100">
                                        <div className="row no-gutters h100">
                                            <div className="col-6 col-md-12">
                                                <div className="wmn-box">
                                                    <p>
                                                        <span>
                                                            1 miliard
                                                            <br /> dolarów
                                                        </span>
                                                        <br />
                                                        na walkę z rakiem piersi
                                                        na świecie
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-12">
                                                <img
                                                    src={grid_1__05}
                                                    className="d-block img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 h100 d-none d-md-block">
                                        <img
                                            src={grid_1__02}
                                            className="d-block img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-12 col-md-3 h100">
                                        <div className="row no-gutters">
                                            <div className="col-6 col-md-12">
                                                <img
                                                    src={grid_1__03}
                                                    className="d-block img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-6 col-md-12">
                                                <div className="wmn-box">
                                                    <p>
                                                        <span>
                                                            linia pomocy
                                                            avonalert
                                                        </span>
                                                        <br />7 dni w tygodniu
                                                        24 godziny na dobę
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 h100">
                                        <div className="row no-gutters">
                                            <div className="col-6 col-md-12">
                                                <div className="wmn-box">
                                                    <p>
                                                        <span>
                                                            globalna społeczność
                                                        </span>
                                                        <br />
                                                        przedsiębiorczych kobiet
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-12">
                                                <img
                                                    src={grid_1__07}
                                                    className="d-block img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default WMN;
