import styled from "styled-components";

export const FaqComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const Button = styled.button`
    border: 2px solid grey;
    border-radius: 4px;
    opacity: 1;
    width: 212px;
    height: 54px;
    color: grey;
    background-color: transparent;

    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;

    cursor: pointer;
    transition: all 0.5s;

    :hover {
        transform: scale(1.1);
    }
`;

export const ContentBox = styled.div`
    width: 75%;
    padding: 10px 3px;
    & li {
        font-weight: bold;
        list-style: decimal-leading-zero;
    }
    & li,
    p {
        font-size: 20px;
        margin: 12px 0;
        & span {
            padding-left: 20px;
        }
        & a {
            text-decoration: underline;
            color: dodgerblue;
        }
    }
    img {
        max-width: 632px;
        max-height: 236px;
        display: block;
        margin: 15px auto;
        width: 100%;
    }
`;

export const ButtonBox = styled(FaqComponent)`
    margin: 20px auto;
`;
