import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Modal from "react-modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrowUp from "../../../assets/images/aprilForm/arrow-up.svg";
import AvonSwiat from "../../../assets/images/aprilForm/wavontworzymy-lepszyswiat.jpg";
import AvonKontraRak from "../../../assets/images/aprilForm/dbamyozdrowie.jpg";
import AvonKontraPrzemoc from "../../../assets/images/aprilForm/avonkontraprzemoc.jpg";
import Forest from "../../../assets/images/aprilForm/forest.jpg";
import AvonAnimals from "../../../assets/images/aprilForm/avon_animals.jpg";

import styles from "./GridSection.module.scss";
import BigButton from "../BigButton/BigButton";
import {
    handleScrollToFormClick,
} from "../utils";

import ArrowButton from "../ArrowButton/ArrowButton";
import { scrollToElement } from "../../../utils";
gsap.registerPlugin(ScrollTrigger);


Modal.setAppElement("#root");




const GridSection2 = ({ ref2 }) => {
    const [refresh,setRefresh] = useState(false);
    const handleArrowUpClick = (e) => {
        scrollToElement(e, "consequencesSection");
        // scrollToElement(e, "scrollToTop");
    };

    const slider = useRef(); // utworzenie referencji dla suwaka


    const component = useRef(null);

    const handleLoad = () => {
        setRefresh(!refresh)
    }
    useEffect(()=>{
        window.addEventListener("DOMContentLoaded",handleLoad)
        return () => {
            window.removeEventListener("DOMContentLoaded",handleLoad)
        }
    },[])


    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            if (ref2.current === undefined) return;
            let panels = gsap.utils.toArray(".panel");
            gsap.to(panels, {
                xPercent:
                    (window.innerWidth > 1240
                        ? -65
                        : -100) *
                    (panels.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: ref2.current,
                    pin: true,
                    scrub: 1,
                    //   snap: 1 / (panels.length - 1),
                    end: () => "+=" + ref2.current.offsetWidth,
                    //   markers: true
                },
            });
        }, component);
        return () => ctx.revert();
    }, [ref2.current,refresh]);


    return (
        <>
        <div className={styles.section} >
         
        <div ref={component}>
             <div ref={slider} className={styles.slideWrapper}>
             <div className={`${styles.slideElement} panel`}>
                    <div className={styles.slideContent}>
                        <div
                            className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}
                        >
                            <div className={styles.mainHeader__topSegment}>
                                W AVON TWORZYMY
                            </div>
                            <div>LEPSZY ŚWIAT</div>
                        </div>
                        <div className={styles.textWrapper}>
                            Piękno ma wiele twarzy, a każdy dzień to
                            nieskończone możliwości, żeby zrobić coś pięknego
                            dla siebie, dla innych, dla świata.
                            <br />
                            <br />
                            Całkowity zysk ze sprzedaży produktów z Różową
                            Wstążką i Symbolem Nieskończoności przeznaczony jest
                            na kampanie Avon Kontra Rak Piersi i Avon Kontra
                            Przemoc.
                        </div>
                    </div>
                    <img src={AvonSwiat} alt="przyjemne z pożytecznym" />
                </div>
                <div className={`${styles.slideElement} panel`}>
                    <img src={AvonKontraRak} alt="przyjemne z pożytecznym" />
                    <div className={styles.slideContent}>
                        <div
                            className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}
                        >
                            <div className={styles.mainHeader__topSegment}>
                                DBAMY O
                            </div>
                            <div>ZDROWIE KOBIET</div>
                        </div>
                        <div className={styles.textWrapper}>
                            Prowadzimy Kampanie Społeczne na rzecz PROFILAKTYKI
                            RAKA PIERSI. Od 2020 r. przekazaliśmy 498 000 zł na
                            dofinansowanie badań USG w Polsce.
                            <br /> <br />
                            Jako 1. firma w Polsce, Avon daje pracownikom Dzień
                            na "U", czyli dodatkowy dzień wolny na badania
                            profilaktyczne.
                        </div>
                        <div className={styles.buttonWrapper}>
                            <a
                                className={styles.customLink}
                                href="https://avonkontrarakpiersi.pl/"
                            >
                                <span>DOWIEDZ SIĘ WIĘCEJ</span>
                                <img src={arrowUp} alt="arrow up" />
                            </a>

                            <a
                                className={styles.customLink}
                                href="https://www.avon.pl/539-606/wspieraj-z-avon/avon-kontra-rak-piersi/"
                            >
                                <span>KUP PRODUKTY Z MISJĄ</span>
                                <img src={arrowUp} alt="arrow up" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className={`${styles.slideElement} panel`}>
                    <div className={styles.slideContent}>
                        <div
                            className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}
                        >
                            <div className={styles.mainHeader__topSegment}>
                                DBAMY O
                            </div>
                            <div>BEZPIECZEŃSTWO</div>
                        </div>
                        <div className={styles.textWrapper}>
                            Prowadzimy Kampanie Społeczne PRZECIWKO PRZEMOCY.
                            <br />
                            <br />
                            Blisko 3,5 ml zł przekazaliśmy na walkę z przemocą i
                            pomogliśmy 40 000 kobiet na "Niebieskiej Linii" w
                            czasie naszej 11-letniej współpracy.
                        </div>
                        <div className={styles.buttonWrapper}>
                            <a
                                className={styles.customLink}
                                href="https://avonkontraprzemoc.pl/"
                            >
                                <span>DOWIEDZ SIĘ WIĘCEJ</span>
                                <img src={arrowUp} alt="arrow up" />
                            </a>

                            <a
                                className={styles.customLink}
                                href="https://www.avon.pl/539-605/wspieraj-z-avon/avon-kontra-przemoc"
                            >
                                <span>KUP PRODUKTY Z MISJĄ</span>
                                <img src={arrowUp} alt="arrow up" />
                            </a>
                        </div>
                    </div>
                    <img src={AvonKontraPrzemoc} alt="przyjemne z pożytecznym" />
                </div>

                <div className={`${styles.slideElement} panel`}>
                    <img src={Forest} alt="przyjemne z pożytecznym" />
                    <div className={styles.slideContent}>
                        <div
                            className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}
                        >
                            <div className={styles.mainHeader__topSegment}>
                                DBAMY O
                            </div>
                            <div>ŚRODOWISKO</div>
                        </div>
                        <div className={styles.textWrapper}>
                            Wierzymy, że świat może być piękniejszym i zdrowszym
                            miejscem, dlatego aktywnie promujemy działania na
                            rzecz środowiska.
                            <br />
                            <br />
                            Nasze kartony są w 100% recyklingowe i używamy tylko
                            faktur elektronicznych.
                        </div>
                    </div>
                </div>

                <div className={`${styles.slideElement} panel`}>
                    <div className={styles.slideContent}>
                        <div
                            className={`${styles.mainHeader} ${styles.mainHeader__inSlider}`}
                        >
                            <div className={styles.mainHeader__topSegment}>
                                DBAMY O
                            </div>
                            <div>KOMFORT ZWIERZĄT</div>
                        </div>
                        <div className={styles.textWrapper}>
                            W Avon nie testujemy ŻADNYCH produktów na naszych
                            futrzanych przyjacielach. 
                            <br />
                            <br />
                            Produkty są przebadane
                            dermatologicznie, bez konieczności sprawdzania ich
                            na zwierzętach.
                        </div>
                    </div>
                    <img src={AvonAnimals} alt="przyjemne z pożytecznym" />
                </div>

                </div>
            </div>
          

            <div className={styles.buttonWrapperBig}>
                <BigButton customClickEvent={handleScrollToFormClick} fullWidth>
                CHCĘ RAZEM Z AVON ZMIENIAĆ ŚWIAT
                </BigButton>
                <ArrowButton customClickEvent={handleArrowUpClick} />
            </div>
            </div>

        </>
    );
};

export default GridSection2;
