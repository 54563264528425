import React, { Component } from "react";
import "./thankYou.css";
import img from "../../assets/images/dolacz-do-klubu/success-icon.png";

class ThankYou extends Component {
    componentDidMount() {}

    render() {
        return (
            <div className="main">
                <div className="container-content">
                    <div className="text-box">
                        <h1>DZIĘKUJEMY ZA REJESTRACJĘ</h1>
                        <h3>
                            Wkrótce otrzymasz od nas SMS z&nbsp;hasłem <br />
                            Na Twój adres email wysłaliśmy potwierdzenie
                            założenia konta oraz informacje na start.
                        </h3>
                    </div>
                    <div className="img-box">
                        <img src={img} alt="nagrody" />
                    </div>
                    <div className="bottom-text-box">
                        <h3>
                            Już teraz złóż swoje pierwsze zamówienie
                            <br />
                            i&nbsp;odbierz nagrodę!
                        </h3>
                        <a
                            target="_parent"
                            href="https://www2.pl.avon.com/pl-home"
                        >
                            <button className="btn-cstm">
                                PRZEJDŹ DO STRONY LOGOWANIA
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default ThankYou;
