import styled from "styled-components";

const buttonPadding = "15px";

export const Button = styled.button`
    width: ${(props) => (props.small ? "auto" : "25%")};
    min-width: 187px;
    height: 54px;
    padding-left: ${buttonPadding};
    padding-right: ${buttonPadding};
    background: transparent
        linear-gradient(
            158deg,
            #7f28c4 0%,
            #ac21a2 37%,
            #da1b81 66%,
            #e42027 100%
        )
        0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: 700;

    transition: all 0.5s;
    font-size: ${(props) => (props.small ? "1.4rem" : "1.6rem")};

    @media only screen and (min-width: 768px) {
        font-size: ${(props) => (props.small ? "2rem" : "3rem")};
    }

    :hover {
        transform: scale(1.1);
    }
`;

export const ButtonOutline = styled.button`
    border: 2px solid #ffffff;
    border-radius: 4px;
    opacity: 1;
    min-width: 212px;
    width: ${(props) => (props.small ? "auto" : "25%")};
    height: 54px;
    color: white;
    background-color: transparent;

    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;

    cursor: pointer;
    transition: all 0.5s;

    font-size: ${(props) => (props.small ? "1.4rem" : "1.6rem")};

    @media only screen and (min-width: 768px) {
        font-size: ${(props) => (props.small ? "2rem" : "3rem")};
    }

    :hover {
        transform: scale(1.1);
    }
`;

export const ButtonOutlineBlack = styled(ButtonOutline)`
    color: black;
    border-color: black;
`;
