const mobileData = [
    {
      "Zakres (number)": "45(7-8)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2022-03-24 12:15"
    },
    {
      "Zakres (number)": "450(3,5-9)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2022-03-02 12:15"
    },
    {
      "Zakres (number)": 4500,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2016-08-25 12:15"
    },
    {
      "Zakres (number)": 4501,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2021-01-31 12:15"
    },
    {
      "Zakres (number)": "4502(0,2-9)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2021-10-19 12:15"
    },
    {
      "Zakres (number)": 45021,
      "Operator (ID)": 13050,
      "Operator (nazwa)": "4RE Sp. z o.o.",
      "Data modyfikacji": "2021-10-19 12:15"
    },
    {
      "Zakres (number)": "4504(0,3,6-9)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2024-04-12 12:45"
    },
    {
      "Zakres (number)": "4504(1,2)",
      "Operator (ID)": 12041,
      "Operator (nazwa)": "Mobiledata Sp. z o.o.",
      "Data modyfikacji": "2024-02-28 12:45"
    },
    {
      "Zakres (number)": 45044,
      "Operator (ID)": 4078,
      "Operator (nazwa)": "Telestrada S.A.",
      "Data modyfikacji": "2024-04-12 12:45"
    },
    {
      "Zakres (number)": 45045,
      "Operator (ID)": 66,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Data modyfikacji": "2022-03-02 12:15"
    },
    {
      "Zakres (number)": "451(0,1)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2020-10-01 12:15"
    },
    {
      "Zakres (number)": "451(3,7-9)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2021-12-21 12:15"
    },
    {
      "Zakres (number)": "451(5,6)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2021-12-21 12:15"
    },
    {
      "Zakres (number)": 4512,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Zakres (number)": "4514(2-3,6-9)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2024-04-10 12:45"
    },
    {
      "Zakres (number)": 45140,
      "Operator (ID)": 4408,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Data modyfikacji": "2020-04-25 12:15"
    },
    {
      "Zakres (number)": 45141,
      "Operator (ID)": 1900,
      "Operator (nazwa)": "Politechnika ��dzka Uczelniane Centrum Informatyczne",
      "Data modyfikacji": "2020-02-08 12:15"
    },
    {
      "Zakres (number)": 45144,
      "Operator (ID)": 12566,
      "Operator (nazwa)": "BSG ESTONIA OU",
      "Data modyfikacji": "2024-04-10 12:45"
    },
    {
      "Zakres (number)": 45145,
      "Operator (ID)": 12857,
      "Operator (nazwa)": "VCOM Polska Sp. z o.o.",
      "Data modyfikacji": "2021-03-19 12:15"
    },
    {
      "Zakres (number)": 452,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2021-12-21 12:15"
    },
    {
      "Zakres (number)": 453,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2022-03-04 12:15"
    },
    {
      "Zakres (number)": "454(0-4)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2022-03-24 12:15"
    },
    {
      "Zakres (number)": "454(5-9)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2022-10-08 12:20"
    },
    {
      "Zakres (number)": "455(0-3,6-9)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Zakres (number)": "4554(1-3,6-9)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2021-12-09 12:15"
    },
    {
      "Zakres (number)": "4554(4,0)",
      "Operator (ID)": 4078,
      "Operator (nazwa)": "Telestrada S.A.",
      "Data modyfikacji": "2021-12-09 12:15"
    },
    {
      "Zakres (number)": 45545,
      "Operator (ID)": 13433,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Zakres (number)": "4555(1-4,6-9)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2023-05-27 12:45"
    },
    {
      "Zakres (number)": 45550,
      "Operator (ID)": 4078,
      "Operator (nazwa)": "Telestrada S.A.",
      "Data modyfikacji": "2023-05-27 12:45"
    },
    {
      "Zakres (number)": 45555,
      "Operator (ID)": 12839,
      "Operator (nazwa)": "Pomagacz Sp. Z o.o.",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Zakres (number)": "456(0-3,5-6,8-9)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2022-11-04 12:35"
    },
    {
      "Zakres (number)": "4564(1-4,6-9)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2023-09-06 12:45"
    },
    {
      "Zakres (number)": 45640,
      "Operator (ID)": 4078,
      "Operator (nazwa)": "Telestrada S.A.",
      "Data modyfikacji": "2023-09-06 12:45"
    },
    {
      "Zakres (number)": 45645,
      "Operator (ID)": 13433,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Zakres (number)": "4567(1-6)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2024-05-15 12:45"
    },
    {
      "Zakres (number)": 45670,
      "Operator (ID)": 13422,
      "Operator (nazwa)": "MVNE.PL Sp. z o.o.",
      "Data modyfikacji": "2024-05-15 12:45"
    },
    {
      "Zakres (number)": 45677,
      "Operator (ID)": 13422,
      "Operator (nazwa)": "MVNE.PL Sp. z o.o.",
      "Data modyfikacji": "2024-04-12 12:45"
    },
    {
      "Zakres (number)": 45678,
      "Operator (ID)": 6297,
      "Operator (nazwa)": "CANAL+ POLSKA S.A.",
      "Data modyfikacji": "2023-09-27 12:45"
    },
    {
      "Zakres (number)": 45679,
      "Operator (ID)": 6297,
      "Operator (nazwa)": "CANAL+ POLSKA S.A.",
      "Data modyfikacji": "2023-09-27 12:45"
    },
    {
      "Zakres (number)": "4590(0-4)",
      "Operator (ID)": 6297,
      "Operator (nazwa)": "CANAL+ POLSKA S.A.",
      "Data modyfikacji": "2017-11-10 12:15"
    },
    {
      "Zakres (number)": "4590(6,7)",
      "Operator (ID)": 12041,
      "Operator (nazwa)": "Mobiledata Sp. z o.o.",
      "Data modyfikacji": "2020-05-19 12:15"
    },
    {
      "Zakres (number)": 45905,
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2019-01-12 12:15"
    },
    {
      "Zakres (number)": 45908,
      "Operator (ID)": 12041,
      "Operator (nazwa)": "Mobiledata Sp. z o.o.",
      "Data modyfikacji": "2020-09-03 12:15"
    },
    {
      "Zakres (number)": 45909,
      "Operator (ID)": 11749,
      "Operator (nazwa)": "ENREACH Sp. z o.o.",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Zakres (number)": "4591(6-8)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2023-10-21 12:45"
    },
    {
      "Zakres (number)": 45910,
      "Operator (ID)": 13389,
      "Operator (nazwa)": "Adeo Telecom LP",
      "Data modyfikacji": "2023-10-21 12:45"
    },
    {
      "Zakres (number)": 45911,
      "Operator (ID)": 12044,
      "Operator (nazwa)": "OTVARTA Sp. z o.o.",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Zakres (number)": 45912,
      "Operator (ID)": 5568,
      "Operator (nazwa)": "Vectra S.A.",
      "Data modyfikacji": "2024-04-06 12:45"
    },
    {
      "Zakres (number)": 45913,
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2024-04-06 12:45"
    },
    {
      "Zakres (number)": 45914,
      "Operator (ID)": 13405,
      "Operator (nazwa)": "YATECO O�",
      "Data modyfikacji": "2023-12-13 12:45"
    },
    {
      "Zakres (number)": 45915,
      "Operator (ID)": 12056,
      "Operator (nazwa)": "NAU Profit Sp. z o.o.",
      "Data modyfikacji": "2022-05-25 12:15"
    },
    {
      "Zakres (number)": 45919,
      "Operator (ID)": 12041,
      "Operator (nazwa)": "Mobiledata Sp. z o.o.",
      "Data modyfikacji": "2023-10-21 12:45"
    },
    {
      "Zakres (number)": 45920,
      "Operator (ID)": 12014,
      "Operator (nazwa)": "SIA Ntel Solutions",
      "Data modyfikacji": "2017-05-14 12:15"
    },
    {
      "Zakres (number)": 45921,
      "Operator (ID)": 13166,
      "Operator (nazwa)": "M TARGET MOBILE",
      "Data modyfikacji": "2022-05-24 12:15"
    },
    {
      "Zakres (number)": 45922,
      "Operator (ID)": 127,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Data modyfikacji": "2022-06-03 12:15"
    },
    {
      "Zakres (number)": 45923,
      "Operator (ID)": 12041,
      "Operator (nazwa)": "Mobiledata Sp. z o.o.",
      "Data modyfikacji": "2023-07-01 12:45"
    },
    {
      "Zakres (number)": 45924,
      "Operator (ID)": 13197,
      "Operator (nazwa)": "WDM Mobile Sp. z o.o.",
      "Data modyfikacji": "2022-08-23 12:15"
    },
    {
      "Zakres (number)": 45925,
      "Operator (ID)": 13028,
      "Operator (nazwa)": "Fonia Telecom Sp. z o.o.",
      "Data modyfikacji": "2022-10-20 12:20"
    },
    {
      "Zakres (number)": 45926,
      "Operator (ID)": 12041,
      "Operator (nazwa)": "Mobiledata Sp. z o.o.",
      "Data modyfikacji": "2022-11-04 12:00"
    },
    {
      "Zakres (number)": 45927,
      "Operator (ID)": 13305,
      "Operator (nazwa)": "Binotel Polska Sp. z o.o.",
      "Data modyfikacji": "2023-07-15 12:45"
    },
    {
      "Zakres (number)": 45928,
      "Operator (ID)": 13197,
      "Operator (nazwa)": "WDM Mobile Sp. z o.o.",
      "Data modyfikacji": "2023-08-02 12:45"
    },
    {
      "Zakres (number)": 45929,
      "Operator (ID)": 13388,
      "Operator (nazwa)": "TELCO TRIBE",
      "Data modyfikacji": "2023-10-20 12:45"
    },
    {
      "Zakres (number)": 4593,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2022-06-03 02:49"
    },
    {
      "Zakres (number)": "4594(6-8)",
      "Operator (ID)": 10196,
      "Operator (nazwa)": "Klucz Telekomunikacja Sp. z o. o.",
      "Data modyfikacji": "2022-03-04 12:15"
    },
    {
      "Zakres (number)": 45940,
      "Operator (ID)": 9750,
      "Operator (nazwa)": "FIBERWAY Sp. z o.o.",
      "Data modyfikacji": "2022-03-19 12:15"
    },
    {
      "Zakres (number)": 45941,
      "Operator (ID)": 12044,
      "Operator (nazwa)": "OTVARTA Sp. z o.o.",
      "Data modyfikacji": "2021-04-28 12:15"
    },
    {
      "Zakres (number)": 45942,
      "Operator (ID)": 11976,
      "Operator (nazwa)": "UAB B2B Network (d. UAB NordConnect)",
      "Data modyfikacji": "2021-05-01 12:15"
    },
    {
      "Zakres (number)": 45943,
      "Operator (ID)": 12041,
      "Operator (nazwa)": "Mobiledata Sp. z o.o.",
      "Data modyfikacji": "2021-08-05 12:15"
    },
    {
      "Zakres (number)": 45944,
      "Operator (ID)": 13040,
      "Operator (nazwa)": "Vonage B.V.",
      "Data modyfikacji": "2021-09-01 12:15"
    },
    {
      "Zakres (number)": 45945,
      "Operator (ID)": 12056,
      "Operator (nazwa)": "NAU Profit Sp. z o.o.",
      "Data modyfikacji": "2017-08-29 12:15"
    },
    {
      "Zakres (number)": 45949,
      "Operator (ID)": 18,
      "Operator (nazwa)": "EXATEL S.A.",
      "Data modyfikacji": "2022-05-21 12:15"
    },
    {
      "Zakres (number)": "4595(1-5)",
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Zakres (number)": "4595(8,9)",
      "Operator (ID)": 4078,
      "Operator (nazwa)": "Telestrada S.A.",
      "Data modyfikacji": "2018-04-27 12:15"
    },
    {
      "Zakres (number)": 45950,
      "Operator (ID)": 12014,
      "Operator (nazwa)": "SIA Ntel Solutions",
      "Data modyfikacji": "2017-05-14 12:15"
    },
    {
      "Zakres (number)": 45956,
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2018-01-31 12:15"
    },
    {
      "Zakres (number)": 45957,
      "Operator (ID)": 12566,
      "Operator (nazwa)": "BSG ESTONIA OU",
      "Data modyfikacji": "2019-03-08 12:15"
    },
    {
      "Zakres (number)": 4596,
      "Operator (ID)": 13055,
      "Operator (nazwa)": "CrossMobile Sp. z o.o.",
      "Data modyfikacji": "2023-03-02 12:45"
    },
    {
      "Zakres (number)": 4597,
      "Operator (ID)": 13197,
      "Operator (nazwa)": "WDM Mobile Sp. z o.o.",
      "Data modyfikacji": "2023-12-05 12:45"
    },
    {
      "Zakres (number)": 4598,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Zakres (number)": 4599,
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2023-12-05 12:45"
    },
    {
      "Zakres (number)": 500,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:36"
    },
    {
      "Zakres (number)": 501,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:36"
    },
    {
      "Zakres (number)": 502,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:36"
    },
    {
      "Zakres (number)": 503,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 504,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 505,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 506,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 507,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 508,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 509,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 510,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 511,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 512,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 513,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 514,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 515,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 516,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 517,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 518,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 519,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:37"
    },
    {
      "Zakres (number)": 530,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": 531,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": 532,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": 533,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": 534,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": 535,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": "536(0,1-5,7-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": 5366,
      "Operator (ID)": 8834,
      "Operator (nazwa)": "Polskie Sieci Cyfrowe Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": 537,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": 538,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": 539,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": 570,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": "571(0,5,6)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2019-07-01 12:15"
    },
    {
      "Zakres (number)": "571(1-4)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": "571(7-9)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2019-09-20 12:15"
    },
    {
      "Zakres (number)": 572,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2016-11-01 12:15"
    },
    {
      "Zakres (number)": "573(0-4,9)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2016-11-01 12:15"
    },
    {
      "Zakres (number)": "573(6-8)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2020-10-01 12:15"
    },
    {
      "Zakres (number)": "5735(1-5)",
      "Operator (ID)": 111,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Zakres (number)": "5735(6,8)",
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2020-06-11 12:15"
    },
    {
      "Zakres (number)": 57350,
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2020-01-17 12:15"
    },
    {
      "Zakres (number)": 57357,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2018-10-25 12:15"
    },
    {
      "Zakres (number)": 57359,
      "Operator (ID)": 11225,
      "Operator (nazwa)": "Fundacja Nasza Wizja",
      "Data modyfikacji": "2021-03-10 12:15"
    },
    {
      "Zakres (number)": 574,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-12-30 12:15"
    },
    {
      "Zakres (number)": 575,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:38"
    },
    {
      "Zakres (number)": 576,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-09-05 12:15"
    },
    {
      "Zakres (number)": 577,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 578,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2016-01-29 12:15"
    },
    {
      "Zakres (number)": "579(0,8,9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2022-06-03 02:49"
    },
    {
      "Zakres (number)": "579(1-3)",
      "Operator (ID)": 9362,
      "Operator (nazwa)": "Lycamobile Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": "5794(3-5)",
      "Operator (ID)": 6297,
      "Operator (nazwa)": "CANAL+ POLSKA S.A.",
      "Data modyfikacji": "2017-02-01 12:15"
    },
    {
      "Zakres (number)": 57940,
      "Operator (ID)": 6297,
      "Operator (nazwa)": "CANAL+ POLSKA S.A.",
      "Data modyfikacji": "2016-11-17 12:15"
    },
    {
      "Zakres (number)": 57941,
      "Operator (ID)": 11757,
      "Operator (nazwa)": "Messagebird B.V.",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Zakres (number)": 57942,
      "Operator (ID)": 11234,
      "Operator (nazwa)": "SIA NetBalt",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 57946,
      "Operator (ID)": 11225,
      "Operator (nazwa)": "Fundacja Nasza Wizja",
      "Data modyfikacji": "2020-01-16 12:15"
    },
    {
      "Zakres (number)": 57947,
      "Operator (ID)": 9576,
      "Operator (nazwa)": "VOICE NET TV Sp. z o.o.",
      "Data modyfikacji": "2017-04-04 12:15"
    },
    {
      "Zakres (number)": 57948,
      "Operator (ID)": 12014,
      "Operator (nazwa)": "SIA Ntel Solutions",
      "Data modyfikacji": "2017-05-16 12:15"
    },
    {
      "Zakres (number)": 57949,
      "Operator (ID)": 7443,
      "Operator (nazwa)": "SGT S.A.",
      "Data modyfikacji": "2017-05-14 12:15"
    },
    {
      "Zakres (number)": "5795(1,2,4-6)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2022-06-03 02:49"
    },
    {
      "Zakres (number)": 57950,
      "Operator (ID)": 10196,
      "Operator (nazwa)": "Klucz Telekomunikacja Sp. z o. o.",
      "Data modyfikacji": "2016-09-23 12:16"
    },
    {
      "Zakres (number)": 57953,
      "Operator (ID)": 11234,
      "Operator (nazwa)": "SIA NetBalt",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 57957,
      "Operator (ID)": 4408,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Data modyfikacji": "2016-06-08 12:15"
    },
    {
      "Zakres (number)": 57958,
      "Operator (ID)": 11823,
      "Operator (nazwa)": "NIMBUSFIVE GmbH",
      "Data modyfikacji": "2016-11-08 12:15"
    },
    {
      "Zakres (number)": 57959,
      "Operator (ID)": 6297,
      "Operator (nazwa)": "CANAL+ POLSKA S.A.",
      "Data modyfikacji": "2016-11-17 12:15"
    },
    {
      "Zakres (number)": 5796,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2022-06-03 02:48"
    },
    {
      "Zakres (number)": "5797(1-5)",
      "Operator (ID)": 111,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Data modyfikacji": "2019-06-12 12:15"
    },
    {
      "Zakres (number)": "5797(8-9)",
      "Operator (ID)": 11296,
      "Operator (nazwa)": "EZ PHONE MOBILE Sp. z o.o.",
      "Data modyfikacji": "2018-11-29 12:15"
    },
    {
      "Zakres (number)": 57970,
      "Operator (ID)": 37,
      "Operator (nazwa)": "PRZEDSI�BIORSTWO TELEKOMUNIKACYJNE \"TELGAM\" SPӣKA AKCYJNA",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Zakres (number)": 57976,
      "Operator (ID)": 10856,
      "Operator (nazwa)": "TELENABLER Sp. z o.o.",
      "Data modyfikacji": "2020-01-25 12:15"
    },
    {
      "Zakres (number)": 57977,
      "Operator (ID)": 12662,
      "Operator (nazwa)": "I.M. Consulting Izabela Mi�osz",
      "Data modyfikacji": "2019-08-09 12:15"
    },
    {
      "Zakres (number)": 600,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 601,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 602,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 603,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 604,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 605,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 606,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 607,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 608,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 609,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 660,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 661,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:39"
    },
    {
      "Zakres (number)": 662,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 663,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 664,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 665,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": "666(0,1-5,7-9)",
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 6666,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 667,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 668,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 669,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": "690(0,8-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": "690(1-6)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": "6907(0,4-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2021-02-26 12:15"
    },
    {
      "Zakres (number)": "6907(2-3)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Zakres (number)": 69071,
      "Operator (ID)": 13040,
      "Operator (nazwa)": "Vonage B.V.",
      "Data modyfikacji": "2021-09-01 12:15"
    },
    {
      "Zakres (number)": 691,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 692,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 693,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 694,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 695,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 696,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 697,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:40"
    },
    {
      "Zakres (number)": 698,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69900,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69901,
      "Operator (ID)": 12925,
      "Operator (nazwa)": "AMD Telecom SA (2)",
      "Data modyfikacji": "2020-12-31 12:15"
    },
    {
      "Zakres (number)": 69902,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69903,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69904,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69905,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69906,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69907,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69908,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69909,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69910,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69911,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69912,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69913,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69914,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69915,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69916,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69917,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69918,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69919,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69920,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69921,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69922,
      "Operator (ID)": 11973,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Data modyfikacji": "2022-03-17 12:15"
    },
    {
      "Zakres (number)": 69923,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69924,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69925,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69926,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69927,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69928,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69929,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 6993,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 6994,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": "6995(1-5)",
      "Operator (ID)": 12041,
      "Operator (nazwa)": "Mobiledata Sp. z o.o.",
      "Data modyfikacji": "2017-06-21 12:15"
    },
    {
      "Zakres (number)": 69950,
      "Operator (ID)": 12925,
      "Operator (nazwa)": "AMD Telecom SA (2)",
      "Data modyfikacji": "2020-12-31 12:15"
    },
    {
      "Zakres (number)": 69956,
      "Operator (ID)": 12633,
      "Operator (nazwa)": "Twilio Ireland Limited",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Zakres (number)": 69957,
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2024-06-21 12:45"
    },
    {
      "Zakres (number)": 69958,
      "Operator (ID)": 12662,
      "Operator (nazwa)": "I.M. Consulting Izabela Mi�osz",
      "Data modyfikacji": "2019-08-09 12:15"
    },
    {
      "Zakres (number)": 69959,
      "Operator (ID)": 11764,
      "Operator (nazwa)": "Move Telecom S.A.",
      "Data modyfikacji": "2017-08-11 12:15"
    },
    {
      "Zakres (number)": 69960,
      "Operator (ID)": 4408,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Data modyfikacji": "2016-07-31 12:15"
    },
    {
      "Zakres (number)": 69961,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69962,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69963,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69964,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69965,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69966,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69967,
      "Operator (ID)": 9576,
      "Operator (nazwa)": "VOICE NET TV Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69968,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69969,
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": "6997(2,3,5-7)",
      "Operator (ID)": 9324,
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69970,
      "Operator (ID)": 6297,
      "Operator (nazwa)": "CANAL+ POLSKA S.A.",
      "Data modyfikacji": "2015-11-20 12:16"
    },
    {
      "Zakres (number)": 69971,
      "Operator (ID)": 8267,
      "Operator (nazwa)": "Sat Film Sp. z o.o. i Wsp�lnicy Sp.k.",
      "Data modyfikacji": "2017-06-10 12:15"
    },
    {
      "Zakres (number)": 69974,
      "Operator (ID)": 9511,
      "Operator (nazwa)": "COMPATEL LIMITED",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69978,
      "Operator (ID)": 7575,
      "Operator (nazwa)": "VOXBONE SA",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 69979,
      "Operator (ID)": 6297,
      "Operator (nazwa)": "CANAL+ POLSKA S.A.",
      "Data modyfikacji": "2015-11-20 12:16"
    },
    {
      "Zakres (number)": 6998,
      "Operator (ID)": 6,
      "Operator (nazwa)": "Netia S.A.",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Zakres (number)": 6999,
      "Operator (ID)": 6,
      "Operator (nazwa)": "Netia S.A.",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Zakres (number)": "720(0,1-7,9)",
      "Operator (ID)": 8223,
      "Operator (nazwa)": "Polkomtel Sp. z o. o. (d. AERO 2 Sp. z o. o.)",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 7208,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 721,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 722,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 723,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 724,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 725,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 726,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": "727(0,4-6,8,9)",
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-08-07 12:15"
    },
    {
      "Zakres (number)": "727(2,3)",
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": "7271(1,2)",
      "Operator (ID)": 12195,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Globitell Wholesale Sp. z o.o. Sp. k.)",
      "Data modyfikacji": "2024-07-10 12:45"
    },
    {
      "Zakres (number)": "7271(3-5)",
      "Operator (ID)": 99990,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Data modyfikacji": "2024-07-10 12:45"
    },
    {
      "Zakres (number)": "7271(6,8,9)",
      "Operator (ID)": 11366,
      "Operator (nazwa)": "PREMIUM MOBILE Sp. z o.o.",
      "Data modyfikacji": "2024-07-03 12:45"
    },
    {
      "Zakres (number)": 72710,
      "Operator (ID)": 12839,
      "Operator (nazwa)": "Pomagacz Sp. Z o.o.",
      "Data modyfikacji": "2021-05-15 12:15"
    },
    {
      "Zakres (number)": 72717,
      "Operator (ID)": 11366,
      "Operator (nazwa)": "PREMIUM MOBILE Sp. z o.o.",
      "Data modyfikacji": "2024-07-03 12:45"
    },
    {
      "Zakres (number)": "7277(0-2,7,9)",
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-08-07 12:15"
    },
    {
      "Zakres (number)": "7277(3-6,8)",
      "Operator (ID)": 180,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Data modyfikacji": "2015-08-07 12:15"
    },
    {
      "Zakres (number)": "728(1-9)",
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 7280,
      "Operator (ID)": 8223,
      "Operator (nazwa)": "Polkomtel Sp. z o. o. (d. AERO 2 Sp. z o. o.)",
      "Data modyfikacji": "2015-12-07 12:15"
    },
    {
      "Zakres (number)": "729(2-6)",
      "Operator (ID)": 9362,
      "Operator (nazwa)": "Lycamobile Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": "7290(0-6,8)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2021-02-26 12:15"
    },
    {
      "Zakres (number)": 72907,
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2021-11-17 12:15"
    },
    {
      "Zakres (number)": 72909,
      "Operator (ID)": 12975,
      "Operator (nazwa)": "SMSWIZARD POLSKA Sp. z o.o.",
      "Data modyfikacji": "2022-01-25 12:15"
    },
    {
      "Zakres (number)": 7291,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": "7297(3,4)",
      "Operator (ID)": 5568,
      "Operator (nazwa)": "Vectra S.A.",
      "Data modyfikacji": "2019-02-14 12:15"
    },
    {
      "Zakres (number)": "7297(8-9)",
      "Operator (ID)": 5568,
      "Operator (nazwa)": "Vectra S.A.",
      "Data modyfikacji": "2019-05-21 12:15"
    },
    {
      "Zakres (number)": 72970,
      "Operator (ID)": 12925,
      "Operator (nazwa)": "AMD Telecom SA (2)",
      "Data modyfikacji": "2020-12-31 12:15"
    },
    {
      "Zakres (number)": 72971,
      "Operator (ID)": 8267,
      "Operator (nazwa)": "Sat Film Sp. z o.o. i Wsp�lnicy Sp.k.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 72972,
      "Operator (ID)": 9511,
      "Operator (nazwa)": "COMPATEL LIMITED",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 72975,
      "Operator (ID)": 12041,
      "Operator (nazwa)": "Mobiledata Sp. z o.o.",
      "Data modyfikacji": "2020-09-03 12:15"
    },
    {
      "Zakres (number)": 72976,
      "Operator (ID)": 8267,
      "Operator (nazwa)": "Sat Film Sp. z o.o. i Wsp�lnicy Sp.k.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 72977,
      "Operator (ID)": 6,
      "Operator (nazwa)": "Netia S.A.",
      "Data modyfikacji": "2020-06-10 12:15"
    },
    {
      "Zakres (number)": "7298(1,2)",
      "Operator (ID)": 5568,
      "Operator (nazwa)": "Vectra S.A.",
      "Data modyfikacji": "2018-09-22 12:15"
    },
    {
      "Zakres (number)": "7298(3-5)",
      "Operator (ID)": 11366,
      "Operator (nazwa)": "PREMIUM MOBILE Sp. z o.o.",
      "Data modyfikacji": "2020-08-13 12:15"
    },
    {
      "Zakres (number)": "7298(7-9)",
      "Operator (ID)": 11366,
      "Operator (nazwa)": "PREMIUM MOBILE Sp. z o.o.",
      "Data modyfikacji": "2018-07-14 12:15"
    },
    {
      "Zakres (number)": 72980,
      "Operator (ID)": 6,
      "Operator (nazwa)": "Netia S.A.",
      "Data modyfikacji": "2019-06-08 12:15"
    },
    {
      "Zakres (number)": 72986,
      "Operator (ID)": 6,
      "Operator (nazwa)": "Netia S.A.",
      "Data modyfikacji": "2019-09-19 12:15"
    },
    {
      "Zakres (number)": "7299(1,2)",
      "Operator (ID)": 11366,
      "Operator (nazwa)": "PREMIUM MOBILE Sp. z o.o.",
      "Data modyfikacji": "2019-09-11 12:15"
    },
    {
      "Zakres (number)": "7299(4,5)",
      "Operator (ID)": 11366,
      "Operator (nazwa)": "PREMIUM MOBILE Sp. z o.o.",
      "Data modyfikacji": "2017-07-07 12:15"
    },
    {
      "Zakres (number)": "7299(6,7)",
      "Operator (ID)": 11366,
      "Operator (nazwa)": "PREMIUM MOBILE Sp. z o.o.",
      "Data modyfikacji": "2017-02-17 12:15"
    },
    {
      "Zakres (number)": "7299(8,9)",
      "Operator (ID)": 11366,
      "Operator (nazwa)": "PREMIUM MOBILE Sp. z o.o.",
      "Data modyfikacji": "2016-12-10 12:15"
    },
    {
      "Zakres (number)": 72990,
      "Operator (ID)": 12221,
      "Operator (nazwa)": "TELCO LEADERS LTD",
      "Data modyfikacji": "2018-03-21 12:15"
    },
    {
      "Zakres (number)": 72993,
      "Operator (ID)": 13040,
      "Operator (nazwa)": "Vonage B.V.",
      "Data modyfikacji": "2021-10-01 12:15"
    },
    {
      "Zakres (number)": 730,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 731,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": "732(3,5-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2021-02-26 12:15"
    },
    {
      "Zakres (number)": "7320(0-5,7-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2021-02-26 12:15"
    },
    {
      "Zakres (number)": 73206,
      "Operator (ID)": 10691,
      "Operator (nazwa)": "VikingCo Poland Sp. z o. o.",
      "Data modyfikacji": "2021-09-21 12:15"
    },
    {
      "Zakres (number)": "7321(0-2,4-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2021-02-26 12:15"
    },
    {
      "Zakres (number)": 73213,
      "Operator (ID)": 10691,
      "Operator (nazwa)": "VikingCo Poland Sp. z o. o.",
      "Data modyfikacji": "2021-09-21 12:15"
    },
    {
      "Zakres (number)": "7322(3-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2021-02-26 12:15"
    },
    {
      "Zakres (number)": 73220,
      "Operator (ID)": 4408,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Data modyfikacji": "2022-09-07 12:15"
    },
    {
      "Zakres (number)": 73221,
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2021-11-17 12:15"
    },
    {
      "Zakres (number)": 73222,
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2021-05-20 12:15"
    },
    {
      "Zakres (number)": "7324(0-6)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2021-02-26 12:15"
    },
    {
      "Zakres (number)": 73247,
      "Operator (ID)": 6,
      "Operator (nazwa)": "Netia S.A.",
      "Data modyfikacji": "2020-06-10 12:15"
    },
    {
      "Zakres (number)": 73248,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Zakres (number)": 73249,
      "Operator (ID)": 10691,
      "Operator (nazwa)": "VikingCo Poland Sp. z o. o.",
      "Data modyfikacji": "2023-04-28 12:45"
    },
    {
      "Zakres (number)": "733(0-6,8-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2021-02-26 12:15"
    },
    {
      "Zakres (number)": "7337(0-1,3-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2021-02-26 12:15"
    },
    {
      "Zakres (number)": 73372,
      "Operator (ID)": 10691,
      "Operator (nazwa)": "VikingCo Poland Sp. z o. o.",
      "Data modyfikacji": "2021-09-21 12:15"
    },
    {
      "Zakres (number)": 734,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:41"
    },
    {
      "Zakres (number)": 735,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": "736(0,7-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2022-06-02 12:15"
    },
    {
      "Zakres (number)": "736(1-6)",
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": "737(3-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2022-06-03 02:48"
    },
    {
      "Zakres (number)": 7370,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 7371,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2017-05-01 12:15"
    },
    {
      "Zakres (number)": "7372(0-5)",
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2017-12-01 12:15"
    },
    {
      "Zakres (number)": 73726,
      "Operator (ID)": 11225,
      "Operator (nazwa)": "Fundacja Nasza Wizja",
      "Data modyfikacji": "2017-12-01 12:15"
    },
    {
      "Zakres (number)": 73727,
      "Operator (ID)": 11225,
      "Operator (nazwa)": "Fundacja Nasza Wizja",
      "Data modyfikacji": "2017-05-01 12:15"
    },
    {
      "Zakres (number)": 73728,
      "Operator (ID)": 11225,
      "Operator (nazwa)": "Fundacja Nasza Wizja",
      "Data modyfikacji": "2017-09-01 12:15"
    },
    {
      "Zakres (number)": 73729,
      "Operator (ID)": 11225,
      "Operator (nazwa)": "Fundacja Nasza Wizja",
      "Data modyfikacji": "2017-09-01 12:15"
    },
    {
      "Zakres (number)": 738,
      "Operator (ID)": 8805,
      "Operator (nazwa)": "PKP Polskie Linie Kolejowe S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": "739(0,2)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": "739(4-6)",
      "Operator (ID)": 9362,
      "Operator (nazwa)": "Lycamobile Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": "739(7,8)",
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": "7391(6,7)",
      "Operator (ID)": 5568,
      "Operator (nazwa)": "Vectra S.A.",
      "Data modyfikacji": "2018-07-04 12:15"
    },
    {
      "Zakres (number)": 73910,
      "Operator (ID)": 12662,
      "Operator (nazwa)": "I.M. Consulting Izabela Mi�osz",
      "Data modyfikacji": "2019-08-09 12:15"
    },
    {
      "Zakres (number)": 73911,
      "Operator (ID)": 5568,
      "Operator (nazwa)": "Vectra S.A.",
      "Data modyfikacji": "2017-09-20 12:15"
    },
    {
      "Zakres (number)": 73912,
      "Operator (ID)": 5568,
      "Operator (nazwa)": "Vectra S.A.",
      "Data modyfikacji": "2017-11-09 12:15"
    },
    {
      "Zakres (number)": 73913,
      "Operator (ID)": 5568,
      "Operator (nazwa)": "Vectra S.A.",
      "Data modyfikacji": "2017-12-14 12:15"
    },
    {
      "Zakres (number)": 73914,
      "Operator (ID)": 5568,
      "Operator (nazwa)": "Vectra S.A.",
      "Data modyfikacji": "2018-01-12 12:15"
    },
    {
      "Zakres (number)": 73915,
      "Operator (ID)": 5568,
      "Operator (nazwa)": "Vectra S.A.",
      "Data modyfikacji": "2018-03-28 12:15"
    },
    {
      "Zakres (number)": 73918,
      "Operator (ID)": 12318,
      "Operator (nazwa)": "Polvoice Sp. z o.o.",
      "Data modyfikacji": "2018-09-13 12:15"
    },
    {
      "Zakres (number)": 73919,
      "Operator (ID)": 6,
      "Operator (nazwa)": "Netia S.A.",
      "Data modyfikacji": "2018-06-15 12:15"
    },
    {
      "Zakres (number)": "7393(1-9)",
      "Operator (ID)": 85,
      "Operator (nazwa)": "Cyfrowy POLSAT S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 73930,
      "Operator (ID)": 5568,
      "Operator (nazwa)": "Vectra S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": "7399(0,7,8)",
      "Operator (ID)": 10691,
      "Operator (nazwa)": "VikingCo Poland Sp. z o. o.",
      "Data modyfikacji": "2016-06-20 12:15"
    },
    {
      "Zakres (number)": "7399(5,6)",
      "Operator (ID)": 180,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Zakres (number)": 73991,
      "Operator (ID)": 11645,
      "Operator (nazwa)": "AGILE TELECOM S.P.A.",
      "Data modyfikacji": "2016-03-15 12:15"
    },
    {
      "Zakres (number)": 73992,
      "Operator (ID)": 11366,
      "Operator (nazwa)": "PREMIUM MOBILE Sp. z o.o.",
      "Data modyfikacji": "2023-05-31 12:45"
    },
    {
      "Zakres (number)": 73993,
      "Operator (ID)": 11234,
      "Operator (nazwa)": "SIA NetBalt",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 73994,
      "Operator (ID)": 180,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Zakres (number)": 73999,
      "Operator (ID)": 66,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Zakres (number)": "780(0,1,5,6)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": "7802(1-4,7)",
      "Operator (ID)": 13009,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Zakres (number)": 78020,
      "Operator (ID)": 127,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Data modyfikacji": "2019-09-12 12:15"
    },
    {
      "Zakres (number)": 78025,
      "Operator (ID)": 13055,
      "Operator (nazwa)": "CrossMobile Sp. z o.o.",
      "Data modyfikacji": "2021-09-02 12:15"
    },
    {
      "Zakres (number)": 78026,
      "Operator (ID)": 11234,
      "Operator (nazwa)": "SIA NetBalt",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 78028,
      "Operator (ID)": 8748,
      "Operator (nazwa)": "METROPORT Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 78029,
      "Operator (ID)": 11560,
      "Operator (nazwa)": "SMSHIGHWAY LIMITED",
      "Data modyfikacji": "2016-02-06 12:15"
    },
    {
      "Zakres (number)": 7803,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Zakres (number)": "7804(1-5)",
      "Operator (ID)": 111,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Zakres (number)": "7804(6-9)",
      "Operator (ID)": 111,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Data modyfikacji": "2021-02-13 12:15"
    },
    {
      "Zakres (number)": 78040,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2018-11-24 12:15"
    },
    {
      "Zakres (number)": "7807(1-9)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Zakres (number)": 78070,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2021-06-30 12:15"
    },
    {
      "Zakres (number)": 7808,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Zakres (number)": "7809(1-9)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Zakres (number)": 78090,
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2020-03-28 12:15"
    },
    {
      "Zakres (number)": 781,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 782,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 783,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 784,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 785,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": "786(1,2)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": "786(3,4)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2019-09-20 12:15"
    },
    {
      "Zakres (number)": "786(5,6)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2019-09-20 12:15"
    },
    {
      "Zakres (number)": "786(8-9)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Zakres (number)": "7860(1-6)",
      "Operator (ID)": 180,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Data modyfikacji": "2016-06-08 12:15"
    },
    {
      "Zakres (number)": 78600,
      "Operator (ID)": 3487,
      "Operator (nazwa)": "JMDI J. Maleszko",
      "Data modyfikacji": "2019-11-09 12:15"
    },
    {
      "Zakres (number)": 78607,
      "Operator (ID)": 5568,
      "Operator (nazwa)": "Vectra S.A.",
      "Data modyfikacji": "2016-06-21 12:15"
    },
    {
      "Zakres (number)": 78608,
      "Operator (ID)": 127,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 78609,
      "Operator (ID)": 12917,
      "Operator (nazwa)": "TISMI B.V.",
      "Data modyfikacji": "2020-12-15 12:15"
    },
    {
      "Zakres (number)": "7867(1-3)",
      "Operator (ID)": 180,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Data modyfikacji": "2022-06-07 12:15"
    },
    {
      "Zakres (number)": "7867(4-5)",
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2023-09-12 12:45"
    },
    {
      "Zakres (number)": 78670,
      "Operator (ID)": 12994,
      "Operator (nazwa)": "Lancelot B.V.",
      "Data modyfikacji": "2021-04-23 12:15"
    },
    {
      "Zakres (number)": 78676,
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2023-05-27 12:45"
    },
    {
      "Zakres (number)": 78677,
      "Operator (ID)": 13288,
      "Operator (nazwa)": "2WAY INC.",
      "Data modyfikacji": "2023-05-25 12:45"
    },
    {
      "Zakres (number)": 78678,
      "Operator (ID)": 10056,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Data modyfikacji": "2018-12-16 12:15"
    },
    {
      "Zakres (number)": 78679,
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2023-01-21 12:45"
    },
    {
      "Zakres (number)": 787,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 788,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": "789(7-9)",
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2021-04-15 12:15"
    },
    {
      "Zakres (number)": 7890,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 7891,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 7892,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 7893,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 7894,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": "7895(0-5)",
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2021-08-11 12:15"
    },
    {
      "Zakres (number)": "7895(6-8)",
      "Operator (ID)": 11366,
      "Operator (nazwa)": "PREMIUM MOBILE Sp. z o.o.",
      "Data modyfikacji": "2021-12-17 12:15"
    },
    {
      "Zakres (number)": 78959,
      "Operator (ID)": 10691,
      "Operator (nazwa)": "VikingCo Poland Sp. z o. o.",
      "Data modyfikacji": "2023-04-28 12:45"
    },
    {
      "Zakres (number)": 7896,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Zakres (number)": 790,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:42"
    },
    {
      "Zakres (number)": 791,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": 792,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": "793(0-7,9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2018-06-30 12:15"
    },
    {
      "Zakres (number)": 7938,
      "Operator (ID)": 6,
      "Operator (nazwa)": "Netia S.A.",
      "Data modyfikacji": "2018-06-30 12:15"
    },
    {
      "Zakres (number)": 794,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": "795(0,6-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": "795(1-5)",
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": 796,
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": 797,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": 798,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": "799(1-4)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2021-02-26 12:15"
    },
    {
      "Zakres (number)": "799(7-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2022-06-03 02:48"
    },
    {
      "Zakres (number)": 7990,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": "7995(0-3,5-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2021-02-26 12:15"
    },
    {
      "Zakres (number)": 79954,
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2021-11-17 12:15"
    },
    {
      "Zakres (number)": 7996,
      "Operator (ID)": 1,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": 880,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": "881(0,8-9)",
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": "881(2-7)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": 8811,
      "Operator (ID)": 8223,
      "Operator (nazwa)": "Polkomtel Sp. z o. o. (d. AERO 2 Sp. z o. o.)",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": 882,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": "883(0,1,2,4-7,9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": "883(3,8)",
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": "884(0,3,6-9)",
      "Operator (ID)": 92,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": "884(1,2)",
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": "8844(1-3)",
      "Operator (ID)": 11366,
      "Operator (nazwa)": "PREMIUM MOBILE Sp. z o.o.",
      "Data modyfikacji": "2023-05-31 12:45"
    },
    {
      "Zakres (number)": "8844(6,7)",
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2023-06-27 12:45"
    },
    {
      "Zakres (number)": 88440,
      "Operator (ID)": 13288,
      "Operator (nazwa)": "2WAY INC.",
      "Data modyfikacji": "2023-05-25 12:45"
    },
    {
      "Zakres (number)": 88444,
      "Operator (ID)": 12190,
      "Operator (nazwa)": "CARITAS ��CZY Sp. z o.o.",
      "Data modyfikacji": "2020-01-18 12:15"
    },
    {
      "Zakres (number)": 88445,
      "Operator (ID)": 1339,
      "Operator (nazwa)": "ORION B�a�ej Hess",
      "Data modyfikacji": "2022-11-19 12:45"
    },
    {
      "Zakres (number)": 88448,
      "Operator (ID)": 7541,
      "Operator (nazwa)": "INTERKONEKT Pawe� Barczyk Tomasz Furman Sp�ka Jawna",
      "Data modyfikacji": "2021-12-08 12:15"
    },
    {
      "Zakres (number)": 88449,
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2023-09-12 12:45"
    },
    {
      "Zakres (number)": "8845(0,9)",
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2023-09-12 12:45"
    },
    {
      "Zakres (number)": "8845(2,3)",
      "Operator (ID)": 12207,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Next Mobile Sp. z o.o.)",
      "Data modyfikacji": "2024-02-27 12:45"
    },
    {
      "Zakres (number)": 88451,
      "Operator (ID)": 10691,
      "Operator (nazwa)": "VikingCo Poland Sp. z o. o.",
      "Data modyfikacji": "2023-04-28 12:45"
    },
    {
      "Zakres (number)": 88454,
      "Operator (ID)": 11366,
      "Operator (nazwa)": "PREMIUM MOBILE Sp. z o.o.",
      "Data modyfikacji": "2024-07-03 12:45"
    },
    {
      "Zakres (number)": 88455,
      "Operator (ID)": 13282,
      "Operator (nazwa)": "PRIVEE Sp. z o.o.",
      "Data modyfikacji": "2023-09-07 12:45"
    },
    {
      "Zakres (number)": 88456,
      "Operator (ID)": 13148,
      "Operator (nazwa)": "Oskar Rybczy�ski",
      "Data modyfikacji": "2023-10-01 12:45"
    },
    {
      "Zakres (number)": 88457,
      "Operator (ID)": 13433,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Data modyfikacji": "2024-03-26 12:45"
    },
    {
      "Zakres (number)": 88458,
      "Operator (ID)": 13405,
      "Operator (nazwa)": "YATECO O�",
      "Data modyfikacji": "2023-12-13 12:45"
    },
    {
      "Zakres (number)": 885,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": 886,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:43"
    },
    {
      "Zakres (number)": 887,
      "Operator (ID)": 3,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Data modyfikacji": "2015-07-20 12:44"
    },
    {
      "Zakres (number)": 888,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:44"
    },
    {
      "Zakres (number)": 889,
      "Operator (ID)": 4,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Data modyfikacji": "2015-07-20 12:44"
    }
  ]

  export { mobileData };