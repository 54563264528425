import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import "./App.css";
import "../assets/fonts/zona-font.css";
import "../assets/fonts/mont-font.css";
import DDK from "../components/Dolacz-do-klubu/DDK";
// import Selfappointment from "../components/Selfappointment/Selfappointment";
import BeRep from "../components/Be-rep/Be-rep";
// import Form3 from "../components/Form3/Form3";
// import BeRepRev from "../components/Be-rep-rev/Be-rep-rev";
// import BeRepRevNewForm from "../components/Be-rep-rev/Be-rep-rev-new-form";
// import BeRepRevMasterclass from "../components/Be-rep-rev/Be-rep-rev-masterclass";
import AprilFormLong from "../components/AprilFormLong/AprilForm";
import AprilForm from "../components/AprilForm/AprilForm";
import Cleo from "../components/Cleo/AprilForm";
// import Sanah from "../components/Sanah/AprilForm";

function App() {
    const [iframeMessage, setIframeMessage] = useState(false);

    useEffect(() => {
        if (!iframeMessage) {
            setIframeMessage(true);
            // iframePostMessage();
        }
    }, [iframeMessage]);

    return (
        <div className={"app"}>

            {/* <Route exact path="/form1" component={Form3} /> */}
            {/* <Route exact path="/form2" component={BeRepRevNewForm} /> */}
            <Route exact path="/form3" component={AprilFormLong} />
            {/* <Route exact path="/selfappointment" component={Selfappointment} /> */}
            <Route exact path="/dolacz-do-klubu" component={AprilFormLong} />           
            {/* <Route exact path="/masterclass" component={BeRepRevMasterclass} /> */}
            {/* <Route exact path="/" component={BeRepRevNewForm} /> */}
            {/* <Route exact path="/mab" component={AprilForm} />   */}
            <Route exact path="/dolacz-do-avon" 
            render={() => (
                <>
                <Helmet>
            {/*- Optanon Consent Notice start */}
<           script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="7f468908-df3a-4e7a-87ae-4dd92e04e6c5" ></script>
            <script type="text/javascript">
            {'function OptanonWrapper() { }'}
            </script>
            {/* Optanon Consent Notice end */}
                </Helmet>
        
                <AprilForm/>
              </>
            )}
          />
        <Route exact path="/chcesz-cos-z-avonu" 
            render={() => (
                <>
                <Helmet>
            {/*- Optanon Consent Notice start */}
<           script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="7f468908-df3a-4e7a-87ae-4dd92e04e6c5" ></script>
            <script type="text/javascript">
            {'function OptanonWrapper() { }'}
            </script>
            {/* Optanon Consent Notice end */}
                </Helmet>
        
                <Cleo/>
              </>
            )}
          />     
            {/* <Route exact path="/sanah" component={Sanah} /> */}
            {/* <Route exact path="/dolacz-teraz" component={AprilForm} />
            <Route exact path="/dolacz-do-avon" component={AprilForm} /> */}
            {/* <Route exact path="/testlp" component={AprilFormLong} /> */}
            {/* <Route
                exact
                path="/dolacz-do-klubu"
                render={() =>
                    (window.location = "https://www.avon.pl/dolacz-do-klubu")
                }
            />
            <Route
                exact
                path="/"
                render={() => {
                    window.location = "https://www.avon.pl/dolacz-do-klubu";
                }}
            /> */}
        </div>
    );
}

export default App;
