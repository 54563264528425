import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./containers/App";
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <GoogleOAuthProvider clientId="337641650610-17a11abq98hcb9o4scmuk47u062hfb4t.apps.googleusercontent.com">
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    </GoogleOAuthProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
